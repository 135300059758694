import React, { useState, useEffect } from "react";
import Modal from "@/app/core/layouts/main/components/Modal";
import DocsComponent from "../DocsComponent";
import { Tabs, Tab, Panel } from "@bumaga/tabs";
import TimelineComponent from "../TimelineComponent";
import { SelectedServiceUpdate } from "@/app/core/base/controllers/ServicesController";
import ProcessController from "@/app/core/base/controllers/ProcessController";
import TranslateDeliveryType, {
  DeliveryType,
  DocumentType,
  DocumentTypeList,
} from "@/app/core/util/constants/DeliveryType";
import OrganListController from "@/app/core/base/controllers/OrganListController";
import { OrganStatus } from "@/app/core/util/helpers/OrganListHelper";
import { toast } from "react-toastify";
import ProcessCard from "../../main/elements/ProcessCard";
import CopyToClipboard from "react-copy-to-clipboard";
import GetBaseUrl from "@/app/core/base/BaseAddress";
import { NotifyUpdate } from "@/app/core/base/controllers/TimelineController";
import { Dropdown } from "react-bootstrap";
import { DateTimeNow } from "@/app/core/util/DateTranslater";
import CustomContact from "../contact/CustomContact";
import JobsController from "@/app/core/base/controllers/JobsController";
import { TimelineChannelSistema } from "@/app/core/util/helpers/TimelineHelper";
import { ComunicationController } from "@/app/core/base/controllers/ComunicationController";
import ProcessHelper from "@/app/core/util/helpers/ProcessHelpers";
import ExigenciesComponent from "@/app/core/layouts/main-components/ExigenciesComponent";
import AutomationsController from "@/app/core/base/controllers/AutomationsController";

export default function ProcessDeferredModal({
  organList,
  show,
  onHide,
  submit,
  exigency,
}) {
  const [selectedService, setSelectedService] = useState({});
  const [process, setProcess] = useState({});
  const [organ, setOrgan] = useState({});
  const [organs, setOrgans] = useState({});
  const [type, setType] = useState(DocumentType.Physical);
  const [loading, setLoading] = useState(false);
  const processCtrl = ProcessController();
  const organCtrl = OrganListController();
  const jobsDone = JobsController();

  useEffect(() => {
    if (organList.id !== undefined) {
      setOrgan(organList);
      loadEverything();
    } else {
      setOrgan({});
    }
  }, [organList]);

  async function loadEverything() {
    setLoading(true);
    await processCtrl.TheProcess(organList.id, null, (res) => {
      setProcess(res);
      for (let s in res.selectedServices) {
        const service = res.selectedServices[s];

        const organ = service.organList.filter(
          (x) => x.id == parseInt(organList.id),
        );
        if (organ.length > 0) {
          setSelectedService(service);
          setOrgans(organ);
        }
      }
      setLoading(false);
    });
  }

  function save() {}

  function showDoc(item) {
    let url =
      "https://drive.google.com/viewerng/viewer?url=" +
      item +
      "?sv=2015-04-05&sr=b&sig=x8VhYOUGSNp9AJfV%2BT%2BybcBkPQknqNXdolq4Xo0ZAXU%3D&se=2016-06-29T20%3A58%3A52Z&sp=r&embedded=true";

    window.open(url, "_blank");
  }

  async function processDeferredEmail() {
    await organCtrl.SendDeferredEmail(
      organ.id,
      true,
      async (res) => {
        let response = DeliveryType.Questioned;
        if (type === DocumentType.Digital) {
          response = DeliveryType.Digital;
        }

        await reportHistory(type);

        await organCtrl.Update(
          { ...organList, deliveryType: response },
          (res) => {
            toast.success("Email enviado com sucesso");
            onHide();
          },
        );
      },
      (error) => {
        toast.error(
          "Parece que o responsável pelo processo foi removido, você pode adicionar alguém na aba 'Processo'",
        );
      },
    );
  }

  async function processDeferredWhatsApp() {
    await ComunicationController.DeferredWhats(organ.id, async (res) => {
      let response = DeliveryType.Questioned;
      if (type === DocumentType.Digital) {
        response = DeliveryType.Digital;
      }

      await reportHistory(type);

      await organCtrl.Update(
        { ...organList, deliveryType: response },
        (res) => {
          onHide();
        },
      );
    });
  }

  async function customProcessDeferredEmail(mail) {
    await organCtrl.SendDeferredCustomEmail(
      organ.id,
      true,
      mail,
      async (res) => {
        let response = DeliveryType.Questioned;
        if (type === DocumentType.Digital) {
          response = DeliveryType.Digital;
        }

        await reportHistory(type);

        await organCtrl.Update(
          { ...organList, deliveryType: response },
          (res) => {
            onHide();
          },
        );
      },
    );
  }

  function notifyEnd(name) {
    let message = "";

    if (organList.notFullfil == true) {
      message = " Devolvido por não cumprimento de exigências";
    } else {
      message = `${`Documentos entregues para ${name}`}`;
    }

    NotifyUpdate(selectedService.processId, message, false);
    AutomationsController().EndProcess(
      (res) => {},
      (error) => {},
    );
    // NotifyUpdate(selectedService.processId, "Processo concluído", true);
  }

  async function endDevolution() {
    if (
      organ.deliveryType === DeliveryType.NotDefined ||
      organ.deliveryType === DeliveryType.Questioned
    ) {
      toast.error(
        "Não foi definido a forma de entrega ainda, defina o tipo de documento e envie o email para o cliente",
      );
      return;
    }
    if (window.confirm("Tem certeza de que deseja encerrar a devolução?")) {
      let toWho = "";

      if (organ.deliveryType !== DeliveryType.Digital) {
        toWho = window.prompt("Para quem foi entregue os documentos?");
        if (toWho.length === 0) {
          toast.error("Preencha o nome de quem recebeu os documentos");
          return;
        }
        notifyEnd(toWho);
      }

      setLoading(true);

      await jobsDone.Insert(organ.processId, organ.id, (res) => {});
      await organCtrl.Delivered(organ.id, (res) => {
        toast.success("Salvo com sucesso");
        setLoading(false);
        AutomationsController().EndProcess(res => {})
        onHide();
      });
    }
  }

  async function endNotFulfillDevolution() {
    if (window.confirm("Tem certeza de que deseja encerrar a devolução?")) {
      notifyEnd("");

      setLoading(true);

      await jobsDone.Insert(organ.processId, organ.id, (res) => {});
      await organCtrl.ReturnProcess(organ.id, (res) => {
        toast.success("Salvo com sucesso");
        setLoading(false);
        onHide();
      });
    }
  }

  async function updateService() {
    let response = DeliveryType.Questioned;
    if (type === DocumentType.Digital) {
      response = DeliveryType.Digital;
    }
    await organCtrl.SendDeferredEmail(organ.id, false, async (res) => {
      await organCtrl.Update(
        { ...organList, deliveryType: response },
        async (res) => {
          reportHistory(type);
          toast.info("Copiado para a área de transferência");
        },
      );
    });
  }

  async function reportHistory(response) {
    let message = "";
    if (response == DocumentType.Digital) {
      message = `Aguardando confirmação de recebimento (Processo Digital)`;
      await NotifyUpdate(
        organ.processId,
        message,
        false,
        TimelineChannelSistema,
        organ.id,
      );
    } else if (
      response == DocumentType.Physical ||
      response == DeliveryType.Questioned
    ) {
      message = `Tudo pronto para entrega! Qual sua preferencia?`;
    }
  }

  function send() {
    if (
      window.confirm(
        "Tem certeza de que deseja enviar a mensagem de finalização?",
      )
    ) {
      if (process.contact == ProcessHelper().ContactPreference.WhatsApp) {
        processDeferredWhatsApp();
      } else if (process.contact == ProcessHelper().ContactPreference.Email) {
        processDeferredEmail();
      } else if (process.contact == ProcessHelper().ContactPreference.Both) {
        processDeferredEmail();
        processDeferredWhatsApp();
      }
    }
  }

  return (
    <Modal
      title={
        organList && organList.exigency == true
          ? "Devolução por não cumprimento"
          : "Processo deferido"
      }
      show={show}
      isLoading={loading}
      onHide={onHide}
      onSave={submit}
      onSubmit={() => save()}
    >
      <Tabs>
        <Tab>
          <button type="button" className="btn btn-sm btn-outline-info">
            <i className="far fa-file-alt"></i> Documentos
          </button>
        </Tab>
        <Tab>
          <button type="button" className="btn btn-sm btn-outline-info">
            <i className="fa fa-tasks"></i> Timeline
          </button>
        </Tab>
        <Tab>
          <button type="button" className="btn btn-sm btn-outline-info">
            <i className="fa fa-folder"></i> Processo
          </button>
        </Tab>
        {organList && organList.exigency == true ? (
          <Tab>
            <button type="button" className="btn btn-sm btn-outline-info">
              <i className="fa fa-tasks"></i> Exigências
            </button>
          </Tab>
        ) : (
          ""
        )}
        <Panel>
          {organ &&
          organ.deliveryType !== DeliveryType.Digital &&
          organ.deliveryType !== DeliveryType.NotDefined &&
          organ.deliveryType !== DeliveryType.Questioned ? (
            <div
              className="alert alert-danger alert-dismissable mt-3"
              role="alert"
            >
              <h3 className="alert-heading font-size-h4 my-2">Resposta</h3>
              <p className="mb-0">
                O cliente informou que a entrega será :{" "}
                {TranslateDeliveryType(organ.deliveryType)}
              </p>
            </div>
          ) : (
            ""
          )}
          {organList && organList.notFullfil == undefined ? (
            <div className="text-center">
              <Dropdown>
                <Dropdown.Toggle variant="light">
                  Tipo de entrega
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <div className="form-group">
                    <span>Selecione o tipo de documento</span>
                    <select
                      className="form-control"
                      onChange={(evt) => setType(parseInt(evt.target.value))}
                    >
                      <option value={null}>Selecione</option>
                      {DocumentTypeList.map((item) => (
                        <option value={item.value}>{item.name}</option>
                      ))}
                    </select>
                  </div>
                  <button
                    className="btn btn-square btn-info btn-block"
                    onClick={() => send()}
                  >
                    <i className="far fa-envelope" /> Enviar
                  </button>
                  <Dropdown>
                    <Dropdown.Toggle variant="light">
                      <i className="si si-envelope-letter" /> Enviar por
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <button
                        className="btn btn-square btn-dual"
                        onClick={() => processDeferredEmail()}
                      >
                        <i className="far fa-envelope" /> Enviar email de
                        devolução
                      </button>
                      <button
                        className="btn btn-square btn-dual"
                        onClick={() => processDeferredWhatsApp()}
                      >
                        <i className="fab fa-whatsapp" /> Enviar WhatsApp de
                        devolução
                      </button>
                      <CopyToClipboard
                        text={`${GetBaseUrl()}#/deferred/${organ.id}`}
                        onCopy={() =>
                          toast.info("Copiado para a área de transferência")
                        }
                      >
                        <button
                          type="button"
                          className="btn btn-square btn-dual"
                          title="copiar"
                          onClick={() => updateService()}
                        >
                          <i className="far fa-copy" /> Copiar link
                        </button>
                      </CopyToClipboard>
                      <CustomContact
                        send={(mail) => customProcessDeferredEmail()}
                      />
                    </Dropdown.Menu>
                  </Dropdown>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          ) : (
            ""
          )}
          <DocsComponent
            processId={process && process.id}
            setView={(item) => showDoc(item)}
            preLoad={process}
            refresh={() => loadEverything()}
            organListId={
              organList && organList.exigency == true
                ? undefined
                : organ && organ.id
            }
          />

          <div className="text-center">
            {organList && organList.notFullfil == undefined ? (
              <button
                className="btn btn-square btn-primary"
                onClick={() => endDevolution()}
              >
                Documentos entregues
              </button>
            ) : (
              <button
                className="btn btn-square btn-warning"
                onClick={() => endNotFulfillDevolution()}
              >
                Registrar devolução
              </button>
            )}
          </div>
        </Panel>
        <Panel>
          <TimelineComponent
            processId={process && process.id}
            organListId={organ && organ.id}
            view={(item) => showDoc(item)}
            refresh={() => loadEverything()}
            preLoad={process}
          />
        </Panel>
        <Panel>
          <ProcessCard
            processId={process && process.id}
            refresh={() => loadEverything()}
            organListId={organ && organ.id}
            preLoad={process}
          />
        </Panel>
        <Panel>
          <ExigenciesComponent
            processId={process && process.id}
            organListId={organ && organ.id}
          />
        </Panel>
      </Tabs>
    </Modal>
  );
}
