import React, { useEffect, useState, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import Storage from "@/app/core/util/Storage";
import CardItem from "@/app/core/layouts/main-components/CounterCardItem";
import FinancialEntries from "@/app/core/layouts/main-components/financial/FinancialEntriesCard";
import FinancialHelper from "@/app/core/util/helpers/FinancialHelper";
import CashFlowController from "@/app/core/base/controllers/CashFlowController";
import { MoneyMask } from "@/app/core/util/helpers/MoneyHelper";
import { SelectedServiceByDate } from "@/app/core/base/controllers/ServicesController";
import { CustomerList, CustomerSelect } from "@/app/core/base/controllers/CustomerController";
import { DateTranslate, DateTranslateSimple, OnlyDateTranslate } from "@/app/core/util/DateTranslater";
import { DownloadTableExcel } from 'react-export-table-to-excel';

export default function MainCustomer(props) {
    const tableRef = useRef(null);
    const date = new Date();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(getFirstDayOfMonth(date.getFullYear(), 0)
      .toISOString()
      .substring(0, 10));
  const [endDate, setEndDate] = useState(getLastDayOfMonth(date.getFullYear(), 11)
    .toISOString()
    .substring(0, 10));
    
  function getFirstDayOfMonth(year, month) {
    return new Date(year, month, 1);
  }

  function getLastDayOfMonth(year, month) {
    return new Date(year, month, month === 1 ? 28 : 30);
  }


  useEffect(() => {
    getCustomerData();
  }, []);

  async function getCustomerData() {
    setLoading(true)
    CustomerList(customers => {
      SelectedServiceByDate(`${startDate}T00:00`, `${endDate}T23:59`, res => {
        
        for (let s of customers) {
          s.services = res.filter(x => x.process.customerId === s.id);
          
          s.revenue = s.services.reduce(function (a, b) {
            return a + b.price;
          }, 0);
        }
        console.log("Resultados", customers, customers.filter(x => x.revenue > 0), res);
        setData(customers.filter(x => x.revenue > 0))
        setLoading(false)
    })
    })
  }

  return (
    <>
      <div className={Storage.getTheme() + " content"}>
        <div className="block-header">

          <div className="block-title">
            <h2 className="content-heading">
              <i className="fa fa-angle-right text-muted mr-1"></i> Cálculo LTV
            </h2>
          </div>
          <div className="block-tools d-flex">
            <div className="form-group">
              <span>Data de início</span>
              <input
                type={"date"}
                className="form-control"
                value={startDate}
                onChange={(evt) => setStartDate(evt.target.value)}
              />
            </div>
            <div className="form-group">
              <span>Data fim</span>
              <input
                type={"date"}
                className="form-control"
                value={endDate}
                onChange={(evt) => setEndDate(evt.target.value)}
              />
            </div>
            <span>
            <button className="btn btn-info mt-4" onClick={() => getCustomerData()}>Consultar</button>
              </span>
              <DownloadTableExcel
                        filename={startDate && `Cálculo LTV - ${DateTranslate(startDate)} até ${DateTranslate(endDate)}`}
                        sheet={startDate && `Cálculo LTV - ${DateTranslate(startDate)} até ${DateTranslate(endDate)}`}
                        currentTableRef={tableRef.current}
                    >
                        <button className="btn btn-success mt-4"><i className="fa fa-download" /> Exportar Excel </button>
                    </DownloadTableExcel>
          </div>
        </div>

        <div className="block-content">
        {loading === true ? (
          <div className="block-content text-center">
            <i className={"fa fa-circle-notch fa-spin"}></i>
          </div>
        ) :""}
          <table className="table table-hover table-bordered" ref={tableRef}>
            {data && data.map(item => (
              <tbody>
                <tr className="border-info">
                  <td className="border-info font-w700">Código de cliente</td>
                  <td className="border-info font-w700">Nome</td>
                  <td className="border-info font-w700">Faturamento</td>
                  <td className="border-info font-w700">Email</td>
                </tr>
                <tr className="border-info">
                  <td className="border-info">{item.customerCode}</td>
                  <td className="border-info">{item.name}</td>
                  <td className="border-info">{MoneyMask(item.revenue)}</td>
                  <td className="border-info">{item.email}</td>
                </tr>
                <tr className="border-info">
                  <td className="border-info font-w700">Id</td>
                  <td className="border-info font-w700">Nome do serviço</td>
                  <td className="border-info font-w700">Valor</td>
                  <td className="border-info font-w700">Data</td>
                </tr>
                {item.services.map(service => (
                  <tr className="border-info">
                    <td className="border-info">{item.customerCode}</td>
                    <td className="border-info">{service.serviceName}</td>
                    <td className="border-info">{service && MoneyMask(service.price)}</td>
                    <td className="border-info">{OnlyDateTranslate(service.acceptedDate)}</td>
                  </tr>
                ))}
              </tbody>
            ))}
          </table>
        </div>
      </div>
    </>
  );
}
