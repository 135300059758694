import { toast } from "react-toastify";
import BaseService from "@/app/core/base/BaseService";
import { HandleErrors } from "../../util/helpers/ErrorHelper";

export default function ReportController() {
  async function GeneralCounters(startDate, endDate, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `report/general-counters/?startDate=${startDate}&endDate=${endDate}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function AnalysisCounters(startDate, endDate, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `report/analysis-counters/?startDate=${startDate}&endDate=${endDate}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function LtvCounter(response, error) {
    await BaseService(
      {
        method: "GET",
        url: `report/ltv`,
      },
      (res) => {
        return res;
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function ResumeOperational(type, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `report/operational-resume?type=${type}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function AnalysisResume(startDate, endDate, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `report/analysis-resume/?startDate=${startDate}&endDate=${endDate}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function BudgetCounters(startDate, endDate, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `report/budget-counters/?startDate=${startDate}&endDate=${endDate}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function BudgetResume(startDate, endDate, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `report/budget-resume/?startDate=${startDate}&endDate=${endDate}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function PreparationCounters(startDate, endDate, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `report/preparation-counters/?startDate=${startDate}&endDate=${endDate}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function PreparationResume(startDate, endDate, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `report/preparation-resume/?startDate=${startDate}&endDate=${endDate}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function DevolutionCounters(startDate, endDate, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `report/devolution-counters/?startDate=${startDate}&endDate=${endDate}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function DevolutionResume(startDate, endDate, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `report/devolution-resume/?startDate=${startDate}&endDate=${endDate}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function ExigencyCounters(type, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `report/exigency-counters/?type=${type}`,
        // url: `report/exigency-counters/?startDate=${startDate}&endDate=${endDate}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function DeferredCounters(startDate, endDate, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `report/deferred-counters/?startDate=${startDate}&endDate=${endDate}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function Customers(startDate, endDate, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `report/customers/?startDate=${startDate}&endDate=${endDate}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function Income(startDate, endDate, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `report/income?startDate=${startDate}&endDate=${endDate}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function InitialBuy(response, error) {
    await BaseService(
      {
        method: "GET",
        url: `report/initial-buy`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function Discount(startDate, endDate, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `report/discount?startDate=${startDate}&endDate=${endDate}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function DiscountCustomerMonth(customerId, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `report/discount-customer-month?customerId=${customerId}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function Retention(response, error) {
    await BaseService(
      {
        method: "GET",
        url: `report/retention`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function CustomRetention(month, year, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `report/retention/custom?date=${year}-${month<10 ? `0${month}`: month}-01T00:00`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function Reactivated(response, error) {
    await BaseService(
      {
        method: "GET",
        url: `report/reactived`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function Organs(startDate, endDate, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `report/organs/?startDate=${startDate}&endDate=${endDate}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function Resume(response, error) {
    await BaseService(
      {
        method: "GET",
        url: `report/general-resume`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function GeneralCounter(response, error) {
    await BaseService(
      {
        method: "GET",
        url: `report/general-counter`,
      },
      (res) => {
        return res;
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function ActiveAccounts(startDate, endDate, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `report/active-accounts?startDate=${startDate}&endDate=${endDate}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function Ticket(startDate, endDate, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `report/ticket?startDate=${startDate}&endDate=${endDate}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function SaleVolume(startDate, endDate, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `report/sale-volume?startDate=${startDate}&endDate=${endDate}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function Reavaliation(startDate, endDate, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `report/reavaliation?startDate=${startDate}&endDate=${endDate}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function Invoicing(startDate, endDate, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `report/invoicing?startDate=${startDate}&endDate=${endDate}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function BudgetReport(userId, startDate, endDate, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `report/comercial-report?startDate=${startDate}&endDate=${endDate}&userId=${userId}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function BudgetOperatorReport(
    userId,
    startDate,
    endDate,
    response,
    error,
  ) {
    await BaseService(
      {
        method: "GET",
        url: `report/user-comercial-report?startDate=${startDate}&endDate=${endDate}&userId=${userId}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function NpsByOrgan(startDate, endDate, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `report/nps-by-organ?startDate=${startDate}&endDate=${endDate}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function DeferredWithoutReview(startDate, endDate, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `report/deferred-without-review?startDate=${startDate}&endDate=${endDate}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  return {
    GeneralCounters,
    GeneralCounter,
    Customers,
    Organs,
    Resume,
    AnalysisCounters,
    PreparationCounters,
    PreparationResume,
    DevolutionCounters,
    DevolutionResume,
    ExigencyCounters,
    BudgetCounters,
    DeferredCounters,
    BudgetResume,
    AnalysisResume,
    LtvCounter,
    ResumeOperational,
    ActiveAccounts,
    Ticket,
    SaleVolume,
    Invoicing,
    Income,
    Retention,
    InitialBuy,
    Discount,
    BudgetReport,
    BudgetOperatorReport,
    NpsByOrgan,
    DeferredWithoutReview,
    Reactivated,
    DiscountCustomerMonth,
    CustomRetention,
    Reavaliation,
  };
}
