import FollowController from "@/app/core/base/controllers/FollowController";
import PlanButton from "@/app/core/layouts/main-components/plan/PlanButton";
import { TelphoneMask } from "@/app/core/util/helpers/ContactHelper";
import React, { useEffect, useState } from "react";

export default function ManagerCard({ customerId, available, reload }) {
  const [managers, setManagers] = useState([]);

  useEffect(() => {
    loadManagers();
  }, [customerId]);

  function loadManagers() {
    FollowController().FollowingCustomer(
      customerId,
      (res) => setManagers(res),
      (err) => {
      },
    );
  }

  return (
    <div>
      {managers && managers.length == 0 && !available ? 
        <a
        class="block block-rounded p-2 block-link-shadow text-center border border-warning border-3x block-roundedMax"
        href="javascript:void(0)">
          <PlanButton customerId={customerId} name="Gerente de contas" reload={()=> reload()} subtitle={"Tenha um especialista em legalização dedicado a acompanhar seus processos em tempo real"}/>
        </a>
      :
        <div>

          {managers && managers.length > 0 ? (
            <div>
              {managers.map((item) => (
                <a
                  class="block block-rounded block-link-shadow text-center border border-warning border-3x block-roundedMax"
                  href="javascript:void(0)"
                >
                  <div class="block-content block-content-full">
                    <img
                      class="img-avatar"
                      src={
                        item.user &&
                        item.user.avatarImages &&
                        item.user.avatarImages.length > 0
                          ? item.user.avatarImages[0].url
                          : "assets/media/avatars/avatar11.jpg"
                      }
                      alt=""
                    />
                  </div>
                  <div class="block-content block-content-full">
                    <p class="font-size-sm font-italic text-muted mb-0">
                      Gerente de relacionamento
                    </p>
                    <p class="font-w600 mb-0">
                      {item.user && item.user.firstName}{" "}
                      {item.user && item.user.lastName}
                    </p>
                  </div>
                  <div class="block-content block-content-full">
                    <a
                      className="btn btn-warning btn-block text-dark mb-2 btn-square"
                      href={`tel:${item.user && item.user.phone}`}
                    >
                      <i className="fa fa-phone mr-2" />{" "}
                      {item.user &&
                        item.user.phone &&
                        TelphoneMask(item.user.phone)}
                    </a>
                    <a
                      className="btn btn-warning btn-block text-dark btn-square"
                      href={`mailto:${item.user && item.user.email}`}
                    >
                      <i className="si si-envelope-letter mr-2" />{" "}
                      {item.user && item.user.email}
                    </a>
                  </div>
                </a>
              ))}
            </div>
          ) : (
            ""
          )}
        </div>
      }
    </div>
  );
}
