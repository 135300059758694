import React, { useEffect, useState } from "react";
import CashFlowController from "@/app/core/base/controllers/CashFlowController";
import DebtorController from "@/app/core/base/controllers/DebtorController";
import { toast } from "react-toastify";
import { Message } from "@/app/core/util/messages/GeralMessages";
import { OnDataChange } from "@/app/core/util/services/DataService";
import { FieldTypeObj } from "@/app/core/util/helpers/FieldTypeHelper";
import Storage from "@/app/core/util/Storage";
import { Dropdown } from "react-bootstrap";
import { DebtadorHelper } from "@/app/core/util/helpers/DebtorHelper";
import { DateTranslate } from "@/app/core/util/DateTranslater";
import Field from "@/app/core/util/helpers/Field";

export default function DebtorLine({ infos, reload }) {
    const [data, setData] = useState({
        userId: Storage.getUserData().id
    });
    const [edit, setEdit] = useState(false);
    const cashFlow = CashFlowController();

    const date = new Date();
    const [change, setChange] = useState(date.getTime());

    useEffect(() => {
        if (infos) {
            setData(infos)
        } else {
            setEdit(true)
        }
    }, [infos]);

    function notifyChange() {
        setChange(date.getTime());
    }

    function handleChange(evt, type) {
        setData(OnDataChange(data, evt, type));
        notifyChange()
    }

    function save() {
        if (
            !data.customerCode ||
            !data.name ||
            !data.description ||
            !data.value
        ) {
            toast.error(Message.PendingData);
        } else {
            let newData = data;
            if (!newData.userId) {
                newData.userId = Storage.getUserData().id;
            }
            if (data.id) {
                DebtorController().Update(newData, res => {
                    toast.success(Message.Saved);
                    reload();
                    setEdit(false)
                })
            } else {
                DebtorController().Insert(newData, res => {
                    toast.success(Message.Saved);
                    setData({
                        customerCode: "",
                        name: "",
                        description: "",
                        value: 0
                    });
                    reload();
                    notifyChange();
                })
            }
        }
    }

    async function updateStatus(sts) {
        if (window.confirm(Message.Confirm)) {
            let newData = data;
            newData.status = sts;
            DebtorController().Update(newData, res => {
                toast.success(Message.Saved);
                reload();
                setEdit(false)
            })
        }
    }

    function gotEdit() {
        setEdit(!edit);
    }

    function deleteItem() {
        if (window.confirm(Message.Confirm)) {
            DebtorController().Delete(data.id, res => {
                toast.success(Message.DeleteSuccess);
                reload()
            })
        }
    }

    return (
        <tr>
            <td>{data && data.createdDate && DateTranslate(data.createdDate)}</td>
            <td>
                {edit === true ?
                    <Field name={"customerCode"} type={FieldTypeObj.Text} value={change && data && data.customerCode} onChange={evt => handleChange(evt)} title={"Código de cliente"} />
                    :
                    <span>{data && data.customerCode}</span>
                }
            </td>
            <td>
                {edit === true ?
                    <Field name={"name"} type={FieldTypeObj.Text} value={change && data && data.name} onChange={evt => handleChange(evt)} title={"Código sistema"} />
                    :
                    <span>{data && data.name}</span>
                }
            </td>
            <td>
                {edit === true ?
                    <Field name={"description"} type={FieldTypeObj.Text} value={change && data && data.description} onChange={evt => handleChange(evt)} title={"Nome"} />
                    :
                    <span>{data && data.description}</span>
                }
            </td>

            <td>
                {edit === true ?
                    <Field name={"value"} type={FieldTypeObj.Number} value={change && data && data.value} onChange={evt => handleChange(evt, FieldTypeObj.Decimal)} title={"Valor"} />
                    :
                    <span>{data && data.value}</span>
                }
            </td>
            <td className="d-flex">
                <span>
                    {data && data.id &&
                        <Dropdown>
                            {data && DebtadorHelper.Status.Pending === data.status ?
                                <Dropdown.Toggle variant="light">
                                    <button className="btn btn-sm btn-info">
                                        Pendente
                                    </button>
                                </Dropdown.Toggle>
                                : data && DebtadorHelper.Status.Approved === data.status ?
                                    <Dropdown.Toggle variant="light">
                                        <button className="btn btn-sm btn-success">
                                            Aprovado
                                        </button>
                                    </Dropdown.Toggle>
                                    :
                                    <Dropdown.Toggle variant="light">
                                        <button className="btn btn-sm btn-danger">
                                            Reprovado
                                        </button>
                                    </Dropdown.Toggle>
                            }
                            <Dropdown.Menu>
                                <button className="btn btn-sm btn-block btn-info" onClick={() => updateStatus(DebtadorHelper.Status.Pending)}><i className="si si-clock" /> Pendente</button>
                                <button className="btn btn-sm btn-block btn-success" onClick={() => updateStatus(DebtadorHelper.Status.Approved)}><i className="si si-check" /> Aprovado</button>
                                <button className="btn btn-sm btn-block btn-danger" onClick={() => updateStatus(DebtadorHelper.Status.Reproved)}><i className="si si-check" /> Reprovado</button>
                            </Dropdown.Menu>
                        </Dropdown>
                    }
                </span>

                <span>
                    {edit === true ?
                        <button className="btn btn-sm btn-info mt-4" onClick={() => save()}><i className="si si-check" /> Salvar</button> :
                        <button className="btn btn-sm btn-success" onClick={() => gotEdit()}><i className="far fa-edit" /> Editar</button>
                    }
                </span>
                <span>

                    {data && data.id &&
                        <Dropdown>
                            <Dropdown.Toggle variant="light">
                                <i className="si si-info" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu style={{ width: 280 }}>
                                <ul className="nav-items">
                                    {data && data.user ?
                                        <li>
                                            <a class="media py-2" href="be_pages_generic_profile.html">
                                                <div class="mx-3 overlay-container">
                                                    <img class="img-avatar img-avatar48" src="assets/media/avatars/avatar3.jpg" alt="" />
                                                    <span class="overlay-item item item-tiny item-circle border border-2x border-white bg-success"></span>
                                                </div>
                                                <div class="media-body">
                                                    <div class="font-w600">{data && data.user && `${data.user.firstName} ${data.user.lastName}`}</div>
                                                    <div class="font-w400 font-size-sm text-muted">Criador</div>
                                                </div>
                                            </a>
                                        </li>
                                        : ""}
                                    {data && data.approver ?
                                        <li>
                                            <a class="media py-2" href="be_pages_generic_profile.html">
                                                <div class="mx-3 overlay-container">
                                                    <img class="img-avatar img-avatar48" src="assets/media/avatars/avatar3.jpg" alt="" />
                                                    <span class="overlay-item item item-tiny item-circle border border-2x border-white bg-success"></span>
                                                </div>
                                                <div class="media-body">
                                                    <div class="font-w600">{data && data.user && `${data.approver.firstName} ${data.approver.lastName}`}</div>
                                                    <div class="font-w400 font-size-sm text-muted">Aprovou</div>
                                                </div>
                                            </a>
                                        </li>
                                        : ""}
                                </ul>
                            </Dropdown.Menu>
                        </Dropdown>
                    }
                </span>
            </td>
        </tr>
    );
}
