import { ReavaliationController } from "@/app/core/base/controllers/ReavaliationController"
import React, { useEffect, useState } from "react"

export default function ReavaliationRequesterCard({startDate, endDate, refresh}) {
    const [data, setData] = useState([]);
    const [other, setOther] = useState([]);
    const [open, setOpen] = useState(false);
    
    useEffect(()=>{
        loadResponsibles()
    },[refresh])
    
    function loadResponsibles(){
        ReavaliationController.Requester(startDate, endDate, res => {
            const base = res.sort(function (a, b) {
                return b.points - a.points;
              });
            setData(base.slice(0, 5))
            setOther(base.slice(5, 1000))
        })
    }

    return (
        <div class="block block-rounded">
            <div class="block-content block-content-full bg-gd-fruit text-center">
                {/* <a class="item item-circle mx-auto bg-black-25" href="javascript:void(0)">
                    <i class="fab fa-2x fa-steam-symbol text-white"></i>
                </a> */}
                <p class="text-white font-size-h3 font-w300 mt-2 mb-2">
                    Quem mais solicitou reavaliações
                </p>
                {/* <p class="text-white-75 mb-0">
                    Best Games
                </p> */}
            </div>
            <div class="block-content block-content-full">
                <table class="table table-borderless table-striped table-hover">
                    <tbody>
                        {data && data.map((item, index) => (
                            <tr>
                                <td class="text-center" style={{width: 40}}>{index + 1}</td>
                                <td>
                                    <strong>{item.name}</strong>
                                </td>
                                <td class="text-center" style={{width: 40}}>
                                    <strong class="text-success">{item.points}</strong>
                                </td>
                            </tr>
                        ))}
                        {open && other.map((item, index) => (
                            <tr>
                                <td class="text-center" style={{width: 40}}>{index + 5}</td>
                                <td>
                                    <strong>{item.name}</strong>
                                </td>
                                <td class="text-center" style={{width: 40}}>
                                    <strong class="text-success">{item.points}</strong>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div class="text-center">
                    <a class="btn btn-hero-sm btn-hero-primary" href="javascript:void(0)" onClick={()=> setOpen(!open)}>
                        <i class="fa fa-eye mr-1"></i> {open === false ? "Mostrar tudo" : "Recolher resultados"}
                    </a>
                </div>
            </div>
        </div>
    )
}