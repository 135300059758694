import SearchInput from "./SearchInput";
import UserOptions from "./UserOptions";
import { Dropdown } from "react-bootstrap";
import Toggler from "@/app/core/util/Toggler";
import Storage from "@/app/core/util/Storage";
import React, { useEffect, useState } from "react";
import HelpModal from "@/app/core/layouts/main-components/feedback/ReportIssueModal";
import OperationNewBudget from "../../main-components/OperationNewBudget";
import NotificationComponent from "../../main-components/notifications/NotificationComponent";
import CustomerNewBudget from "../../main-components/budget/CustomerNewBudget";
import CheckMobile from "@/app/core/util/Screen";
import AutomationsController from "@/app/core/base/controllers/AutomationsController";

export default function Header(props) {
  const userData = Storage.getUserData();
  const [showOperationModal, setShowOperationModal] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [showBudgetModal, setShowBudgetModal] = useState(false);
  let screenWidth = CheckMobile();
  const enviroment = process.env.REACT_APP_ENV;

  useEffect(() => {
    loadAutomation()
  }, [userData]);
  
  function loadAutomation(){
    AutomationsController().Reminders(res => {});
    AutomationsController().ContactsSync(res => {});
  }

  function loadDepartment() {
    if (userData !== null) {
      switch (userData.level) {
        case 1:
          return `Orçamento ${
            userData.teamId !== undefined ? "- Equipe " + userData.teamId : ""
          }`;
        case 2:
          return `Análise ${
            userData.teamId !== undefined ? "- Equipe " + userData.teamId : ""
          }`;
        case 3:
          return `Preparação ${
            userData.teamId !== undefined ? "- Equipe " + userData.teamId : ""
          }`;
        case 4:
          return `Devolução Órgão ${
            userData.teamId !== undefined ? "- Equipe " + userData.teamId : ""
          }`;
        case 5:
          return "Área do Cliente";
        case 6:
          return `Operação ${
            userData.teamId !== undefined ? "- Equipe " + userData.teamId : ""
          }`;
        case 7:
          return "Financeiro";
        default:
          return "Administrativo";
      }
    }
  }

  function loadTopButtons() {
    if (
      userData !== null &&
      (userData.level === 6 || userData.level === 1 || userData.level === 4)
    ) {
      return (
        <div
          className="btn-group"
          role="group"
          aria-label="Horizontal Secondary"
        >
          <button
            type="button"
            className="btn btn-dual mr-1"
            onClick={() => props.toggleNav()}
          >
            <i className="fa fa-fw fa-bars" />
          </button>
          <button
            type="button"
            className="btn  btn-outline-secondary"
            onClick={() => props.showProspectModal()}
          >
            <i className="fa fa-fw fa-plus-square"></i>N.O. Sem cadastro
          </button>
          {userData.level === 6 || userData.level === 4 ? (
            <button
              type="button"
              className="btn  btn-outline-secondary"
              onClick={() => setShowOperationModal(true)}
            >
              <i className="fa fa-fw fa-plus"></i> N.O.
            </button>
          ) : (
            ""
          )}
        </div>
      );
    } else if (userData !== null && userData.level === 5) {
      return (
        <div
          className="btn-group"
          role="group"
          aria-label="Horizontal Secondary"
        >
          <button
            type="button"
            className="btn btn-dual mr-1"
            onClick={() => props.toggleNav()}
          >
            <i className="fa fa-fw fa-bars" />
          </button>
          <button
            type="button"
            className="btn  btn-outline-success"
            onClick={() => setShowBudgetModal(true)}
          >
            <i className="si si-plus"></i> Novo processo
          </button>
          <button
            type="button"
            className="btn  btn-outline-secondary ml-2"
            onClick={() => setShowHelpModal(true)}
          >
            <i className="si si-question"></i> Fale conosco
          </button>
        </div>
      );
    } else {
      return (
        <button
          type="button"
          className="btn btn-dual mr-1"
          onClick={() => props.toggleNav()}
        >
          <i className="fa fa-fw fa-bars" />
        </button>
      );
    }
  }

  function leave() {
    if (window.confirm("Tem certeza que deseja sair?")) {
      localStorage.removeItem("exigencyTemplates");
      localStorage.removeItem("services");
      localStorage.removeItem("user_data");
      localStorage.removeItem("following");
      localStorage.removeItem("services");
      localStorage.removeItem("motivational");
      localStorage.removeItem("preparation_counter");
      localStorage.removeItem("budget_counter");
      localStorage.removeItem("analysis_counter");
      localStorage.removeItem("organs");
      window.location = "/#";
    }
  }

  function loadAdminOptionals() {
    if (userData && userData.admin === true) {
      return (
        <div className="dropdown d-inline-block">
          <Dropdown>
            <Dropdown.Toggle variant={Storage.getTheme()} id="dropdown-basic">
              Supervisor
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <UserOptions />
            </Dropdown.Menu>
          </Dropdown>
        </div>
      );
    }
  }

  return (
    <header id="page-header">
      {screenWidth == false ? (
        <div className="content-header">
          <div>{loadTopButtons()}</div>
          <div>
            <b>{loadDepartment()}</b>
          </div>
          <div>
            {userData && userData.contractType !== 1 ? (
              <SearchInput search={props.search} />
            ) : (
              ""
            )}
            {loadAdminOptionals()}

            <div className="dropdown d-inline-block">
              <NotificationComponent />
            </div>
            {/* {enviroment == "staging" || enviroment == "local" ?
              <button
                type="button"
                className="btn btn-dual mr-1"
                onClick={() => Toggler.toggleSideOverlay()}
              >
                <i className="far fa-fw fa-list-alt" />
              </button>
              : ""} */}
            <button
              type="button"
              className="btn  btn-outline-danger"
              alt="Sair"
              onClick={() => leave()}
            >
              <i className="si si-logout"></i>
            </button>
          </div>
        </div>
      ) : (
        <div className="row p-2">
          <div className="col-2 text-center title py-2"></div>
          <div className="col-8 text-center title py-2">
            <b>{loadDepartment()}</b>
          </div>
          <div className="col-2 text-center title py-2">
            {userData && userData.contractType !== 1 ? (
              <SearchInput search={props.search} />
            ) : (
              ""
            )}
          </div>
          <div className="col-8">{loadTopButtons()}</div>
          <div className="col-4 text-right">
            {loadAdminOptionals()}

            {/* <div className="dropdown d-inline-block">
                <NotificationComponent />
              </div> */}
            {/* {(enviroment == "staging" || enviroment == "local") && userData && userData.level != 5 ?
              <button
                type="button"
                className="btn btn-dual mr-1"
                onClick={() => Toggler.toggleSideOverlay()}
              >
                <i className="far fa-fw fa-list-alt" />
              </button>
              : ""} */}
            <button
              type="button"
              className="btn  btn-outline-danger"
              alt="Sair"
              onClick={() => leave()}
            >
              <i className="si si-logout"></i>
            </button>
          </div>
        </div>
      )}

      <div id="page-header-loader" className="overlay-header bg-primary-darker">
        <div className="content-header">
          <div className="w-100 text-center">
            <i className="fa fa-fw fa-2x fa-sun fa-spin text-white" />
          </div>
        </div>
      </div>

      {/* <Modal
        data={selectedData}
        info={selectedData}
        show={showModal}
        submit={(updateBudget) => notificationList(updateBudget)}
        onHide={() => setShowModal(false)}
      /> */}

      <OperationNewBudget
        show={showOperationModal}
        onHide={() => setShowOperationModal(false)}
      />
      <CustomerNewBudget
        show={showBudgetModal}
        onHide={() => setShowBudgetModal(false)}
      />
      <HelpModal show={showHelpModal} onHide={() => setShowHelpModal(false)} />
    </header>
  );
}
