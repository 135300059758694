import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import CardItem from "@/app/core/layouts/main-components/CounterCardItem";
import MemberComponent from "./MemberComponent";
import Storage from "@/app/core/util/Storage";
import {
  DevolutionSupervisorResume,
  SupervisorTeamSelect,
} from "@/app/core/base/controllers/TeamsController";
import DepartmentTasks from "./DepartmentTasks";
import { OrganStatus } from "@/app/core/util/helpers/OrganListHelper";
import ReavaliationResponsibleCard from "../../dashboard/components/panels/administratorElements/ReavaliationResponsibleCard";
import ReavaliationRequesterCard from "../../dashboard/components/panels/administratorElements/ReavaliationRequesterCard";
import ReavaliationReport from "../../dashboard/components/panels/administratorElements/ReavaliationReport";

export default function SupervisorComponent(props) {
  const [reports, setReports] = useState({});
  const [data, setData] = useState([]);
  const [raw, setRaw] = useState([]);
  const [members, setMembers] = useState([]);
  const [userData, setUserData] = useState(Storage.getUserData());
  const date = new Date();
  const [change, setChange] = useState(date.getTime());
  const [startDate, setStartDate] = useState(
    getFirstDayOfMonth(date.getFullYear(), date.getMonth())
      .toISOString()
      .substring(0, 10),
  );
  const [endDate, setEndDate] = useState(
    getLastDayOfMonth(date.getFullYear(), date.getMonth())
      .toISOString()
      .substring(0, 10),
  );

  function getFirstDayOfMonth(year, month) {
    return new Date(year, month, 1);
  }

  function getLastDayOfMonth(year, month) {
    return new Date(year, month, month === 1 ? 28 : 30);
  }
  useEffect(() => {
    if (Storage.getUserData() && Storage.getUserData().level !== 10) {
      loadTeam();
    } else {
      getUrlParameters();
    }
  }, []);

  useEffect(() => {
    loadTeam();
  }, [userData]);

  function notyChange() {
    setChange(date.getTime());
  }

  function getUrlParameters() {
    let param = props.location.search;
    let teamId = 0;
    let level = 0;
    if (param !== undefined) {
      let base = param.split("?")[1];
      let item = base.split("&");
      if (item.length > 0) {
        if (item[0].includes("teamId")) {
          let res = item[0].split("teamId=");
          teamId = res[1];
        }
        if (item[1].includes("level")) {
          let tryed = item[1].split("level=");
          level = tryed[1];
        }
      }
    }
    let oldUser = userData;
    oldUser.teamId = parseInt(teamId);
    oldUser.level = parseInt(level);
    setUserData(oldUser);
    notyChange();
  }

  async function loadTeam() {
    if (userData && userData.teamId) {
      await SupervisorTeamSelect(userData.teamId, async (res) => {
        setRaw(res);
        let team = [];
        let report = {
          ongoing: 0,
          waiting: 0,
          done: 0,
          filed: 0,
          devolution: 0,
          deferred: 0,
          exigencies: 0,
          done: 0,
          review: 0,
          ...reports,
        };

        for (let s in res) {
          let item = res[s];
          team.push({
            ...item.user,
            assignments:
              item.organAssignments !== undefined ? item.organAssignments : [],
          });

          let ongoing = 0;
          if (item.ongoingSelectedServices !== undefined) {
            report.ongoing += item.ongoingSelectedServices.length;
            ongoing += item.ongoingSelectedServices.length;
          }

          if (item.ongoingProcess !== undefined) {
            report.ongoing += item.ongoingProcess.length;
            ongoing += item.ongoingProcess.length;
          }
          if (item.ongoingOrganList !== undefined) {
            report.ongoing += item.ongoingOrganList.length;
            ongoing += item.ongoingOrganList.length;
          }
          if (item.reviewOrganList !== undefined) {
            report.review += item.reviewOrganList.length;
          }

          let waiting = 0;

          if (item.openSelectedServices !== undefined) {
            report.waiting += item.openSelectedServices.length;
            waiting += item.openSelectedServices.length;
          }

          if (item.openProcess !== undefined) {
            report.waiting += item.openProcess.length;
            waiting += item.openProcess.length;
          }
          if (item.openOrganList !== undefined) {
            report.waiting += item.openOrganList.length;
            waiting += item.openOrganList.length;
          }

          let done = 0;

          if (item.doneSelectedServices !== undefined) {
            report.done += item.doneSelectedServices.length;
            done += item.doneSelectedServices.length;
          }
          if (item.doneProcess !== undefined) {
            report.done += item.doneProcess.length;
            done += item.doneProcess.length;
          }
          if (item.doneOrganList !== undefined) {
            report.done += item.doneOrganList.length;
            done += item.doneOrganList.length;
          }

          // report.ongoing = ongoing;
          // report.waiting = waiting;
          // report.done = done;

          res[s].ongoing = ongoing;
          res[s].waiting = waiting;
          res[s].done = done;
        }
        if (userData.level === 4) {
          await DevolutionSupervisorResume(userData.teamId, (res) => {
            setReports(res);
          });
        } else {
          setReports(report);
        }

        setMembers(team);
        setData(res);
        notyChange();
      });
    }
  }

  function refreshData() {
    loadTeam();
  }

  return (
    <>
      <div className="ml-1 mr-1">
        {change && userData && userData.level === 4 ? (
          <div className="row">
            <div className="col-md-4">
              <CardItem
                name={"A protocolar"}
                value={reports && reports.notStarted}
                text={"text-white-75"}
                icon={"far fa-2x fa-list-alt text-white"}
                aparence={
                  "block block-rounded block-link-shadow bg-xinspire-darker"
                }
              />
            </div>
            <div className="col-md-4">
              <CardItem
                name={"Protocolado"}
                value={reports && reports.filed}
                text={"text-white-75"}
                icon={"fa fa-2x fa-tasks text-white"}
                aparence={
                  "block block-rounded block-link-shadow bg-xsmooth-darker"
                }
              />
            </div>
            <div class="col-md-4">
              <CardItem
                name={"Aguardando devolução"}
                value={reports && reports.devolution}
                text={"text-white-75"}
                icon={"far fa-2x fa-clock text-white"}
                aparence={
                  "block block-rounded block-link-shadow bg-xmodern-darker"
                }
              />
            </div>
            <div class="col-md-4">
              <CardItem
                name={"Deferidos"}
                value={reports && reports.deferred}
                text={"text-white-75"}
                icon={"far fa-2x fa-check-circle text-white"}
                aparence={
                  "block block-rounded block-link-shadow bg-xwork-darker"
                }
              />
            </div>
            <div class="col-md-4">
              <CardItem
                name={"Exigência"}
                value={reports && reports.exigency}
                text={"text-white-75"}
                icon={"si fa-2x si-close text-white"}
                aparence={
                  "block block-rounded block-link-shadow bg-default-darker"
                }
              />
            </div>
            <div class="col-md-4">
              <CardItem
                name={"Concluídos"}
                value={reports && reports.done}
                text={"text-white-75"}
                icon={"si fa-2x si-bag text-white"}
                aparence={"block block-rounded block-link-shadow bg-gd-sea"}
              />
            </div>
          </div>
        ) : (
          <div className="row">
            <div
              className={`col-md-${userData && userData.level !== 3 ? "4" : "3"}`}
            >
              <CardItem
                name={"Não iniciados"}
                value={reports && reports.waiting}
                text={"text-white-75"}
                icon={"far fa-2x fa-list-alt text-white"}
                aparence={"block block-rounded block-link-shadow bg-warning"}
              />
            </div>
            <div
              className={`col-md-${userData && userData.level !== 3 ? "4" : "3"}`}
            >
              <CardItem
                name={"Em andamento"}
                value={reports && reports.ongoing}
                text={"text-white-75"}
                icon={"far fa-2x fa-check-circle text-primary-lighter"}
                aparence={"block block-rounded block-link-shadow bg-primary"}
              />
            </div>
            {userData && userData.level === 3 ? (
              <div
                className={`col-md-${userData && userData.level !== 3 ? "4" : "3"}`}
              >
                <CardItem
                  name={"Em conferência"}
                  value={reports && reports.review}
                  text={"text-white-75"}
                  icon={"far fa-2x fa-check-square text-primary-lighter"}
                  aparence={"block block-rounded block-link-shadow bg-info"}
                />
              </div>
            ) : (
              ""
            )}
            <div
              class={`col-md-${userData && userData.level !== 3 ? "4" : "3"}`}
            >
              <CardItem
                name={"Concluídos"}
                value={reports && reports.done}
                text={"text-white-75"}
                icon={"far fa-2x fa-folder-open text-success-light"}
                aparence={"block block-rounded block-link-shadow bg-success"}
              />
            </div>
          </div>
        )}
        {/* {userData.level !== 10 && <DepartmentTasks members={members} raw={raw && raw} />} */}

        <div className="title">
          <h2 className="content-heading">
            <i className="fa fa-angle-right text-muted mr-1"></i>{" "}
            {members && members.length} Membros
          </h2>
        </div>
        <div className="row">
          {userData &&
            data &&
            data.map((item) => (
              <div className="col-md-12">
                <MemberComponent
                  user={userData}
                  data={item}
                  members={members}
                  reload={() => refreshData()}
                />
              </div>
            ))}
        </div>

        <div className="row">
          <div className="col-6">
            <ReavaliationResponsibleCard startDate={startDate} endDate={endDate} refresh={change} />
          </div>
          <div className="col-6">
            <ReavaliationRequesterCard startDate={startDate} endDate={endDate} refresh={change} />
          </div>

          <div className="col-12">
            <ReavaliationReport />
          </div>
        </div>

      </div>
      <ToastContainer className="toastr" />
    </>
  );
}
