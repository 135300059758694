import React, { useEffect, useState } from "react";
import Nps from "../../dashboard/components/panels/administratorElements/Nps";
import Table from "@/app/core/components/table/";
import OrganListHelper from "@/app/core/util/helpers/OrganListHelper";
import Functions from "@/app/core/util/Functions";
import ReportController from "@/app/core/base/controllers/ReportController";
import { DateTranslate } from "@/app/core/util/DateTranslater";
import OperationProcessModal from "@/app/core/layouts/main-components/OperationProcessModal";
import Loading from "@/app/core/layouts/main/components/Loading";

export default function MainComponent(props) {
  const date = new Date();
  const [nps, setNps] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showNps, setShowNps] = useState(true);
  const [showProcess, setShowProcess] = useState(true);
  const [showItemModal, setShowItemModal] = useState(false);
  const [deferred, setDeferred] = useState([]);
  const [selected, setSelected] = useState({});
  const [startDate, setStartDate] = useState(
    getFirstDayOfMonth(date.getFullYear(), date.getMonth())
      .toISOString()
      .substring(0, 10),
  );
  const [endDate, setEndDate] = useState(
    getFirstDayOfMonth(date.getFullYear(), date.getMonth() + 1)
      .toISOString()
      .substring(0, 10),
  );

  function getFirstDayOfMonth(year, month) {
    return new Date(year, month, 1);
  }

  useEffect(() => {
    loadNps();
  }, []);

  async function loadNps() {
    setLoading(true);
    await ReportController().NpsByOrgan(startDate, endDate, (res) => {
      for (let s of res) {
        s.value = s.value.toFixed(2);
      }
      setNps(res);
    });
    await ReportController().DeferredWithoutReview(startDate, endDate, (res) => {
      for (let s of res) {
        s.processName = s.process.processName;
        s.organName = s.organ.name;
        s.endDevolutionDate = DateTranslate(s.endDevolutionDate);
      }
      setDeferred(res);
    });
    setLoading(false);
  }

  function openProcess(item) {
    let process = { id: item.data.processId, organListId: item.data.id };
    setSelected(process);
    setShowItemModal(true);
  }

  return (
    <div className="content">
      <Loading loading={loading} />
      <div className="row">
        <div className="col-md-6">
          <div className="content-heading">
            <h2>NPS</h2>
          </div>
        </div>
        <div className="form-group col-md-2">
          <span>Data de início</span>
          <input
            type="date"
            className="form-control form-control-alt form-control-sm"
            value={startDate}
            onChange={(evt) => setStartDate(evt.target.value)}
          />
        </div>
        <div className="form-group col-md-2">
          <span>Data fim</span>
          <input
            type="date"
            className="form-control form-control-alt form-control-sm"
            value={endDate}
            onChange={(evt) => setEndDate(evt.target.value)}
          />
        </div>
        <div className="col-md-2">
          <button className="btn btn-info mt-4" onClick={() => loadNps()}><i className="far fa-search"/> Consultar</button>
        </div>
        <div className="col-md-12">
          <Nps startDate={startDate} endDate={endDate} refresh={startDate}/>
        </div>
        <div className="col-md-12">
          <div className="block block-bordered">
            <div className="block-header">
              <div className="block-title">NPS por órgão</div>
              <div className="block-tools">
                <button
                  className="btn btn-dual"
                  onClick={() => setShowNps(!showNps)}
                >
                  <i
                    className={`si si-arrow-${showNps === true ? "up" : "down"}`}
                  ></i>
                </button>
              </div>
            </div>
            {showNps ? (
              <div className="block-content">
                <Table
                  title={"NPS por órgão"}
                  tableId="done"
                  data={nps}
                  hideHeader={true}
                  columns={OrganListHelper().OrganNpsCols}
                  hide={false}
                  refreshData={() => null}
                  setFilter={(filter) => Functions.ProcessFilter(filter, nps)}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="col-md-12">
          <div className="block block-bordered">
            <div className="block-header">
              <div className="block-title">Processos não avaliados</div>
              <div className="block-tools">
                <button
                  className="btn btn-dual"
                  onClick={() => setShowProcess(!showProcess)}
                >
                  <i
                    className={`si si-arrow-${showProcess === true ? "up" : "down"}`}
                  ></i>
                </button>
              </div>
            </div>
            {showProcess ? (
              <div className="block-content">
                <Table
                  title={"Processos não avaliados"}
                  tableId="notReview"
                  data={deferred}
                  hideHeader={true}
                  columns={OrganListHelper().ProcessWithoutReviewCols}
                  hide={false}
                  refreshData={() => null}
                  viewAction={(data) => openProcess(data)}
                  setFilter={(filter) =>
                    Functions.ProcessFilter(filter, deferred)
                  }
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <OperationProcessModal
        show={showItemModal}
        data={selected}
        onHide={() => setShowItemModal(false) | setSelected({})}
      />
    </div>
  );
}
