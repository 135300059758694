import React, { useEffect, useRef, useState } from "react";
import Logo from "@/assets/img/logo.jpg";
import Axios from "axios";
import Storage from "@/app/core/util/Storage";
import {
    CPFValidator,
    CivilState,
    CivilStateObj,
    CivilStateTranslate,
    Communion,
    CommunionObj,
    CommunionTranslate,
} from "@/app/core/util/helpers/UserHelper";

import {
    ContractInsert,
    UploadContract,
} from "@/app/core/base/controllers/ContractController";
import { toast } from "react-toastify";
import {
    CepMask,
    CnpjMask,
    CpfMask,
} from "@/app/core/util/helpers/ContactHelper";
import AutomationsController from "@/app/core/base/controllers/AutomationsController";
import CustomerHelper from "@/app/core/util/helpers/CustomerHelper";
import { MoneyMask } from "@/app/core/util/helpers/MoneyHelper";
import ReportHelper from "@/app/core/util/helpers/ReportHelper";
import { OnlyDateTranslate } from "@/app/core/util/DateTranslater";
import { ElaboratorHelper } from "@/app/core/util/helpers/ElaboratorHelper";

export default function GenerateContract({ customer, data, partners }) {
    const [myDoc, setMyDoc] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [hasCertificate, setHasCertificate] = useState(false);
    const [customerType, setCustomerType] = useState(0);
    const date = new Date();
    const inputFile = useRef(null);
    const userData = Storage.getUserData();
    const automations = AutomationsController();
    const customerHelper = CustomerHelper();
    const baseFont = { fontFamily: "Times New Roman" };
    function onUploadClick() {
        inputFile.current.click();
    }

    const monthNames = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
    ];
    let day = date.getDate(),
        month = date.getMonth() + 1,
        year = date.getFullYear();
    // Font.register({
    //   family: "Oswald",
    //   src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
    // });

    // const styles = StyleSheet.create({
    //     div: {
    //         padding: 30,
    //         fontSize: 12,
    //         lineHeight: 1.5,
    //         backgroundColor: '#ffffff',
    //     },
    //     section: {
    //         marginBottom: 10,
    //     },
    //     row: {
    //         display: "inline",
    //         whiteSpace: "nowrap",
    //     },
    //     title: {
    //         fontSize: 16,
    //         marginBottom: 10,
    //         h6Align: 'center',
    //         fontWeight: 'bold',
    //     },
    //     h6Right: {
    //         fontSize: 16,
    //         marginBottom: 10,
    //         h6Align: 'right',
    //         fontWeight: 'bold',
    //     },
    //     clauseTitle: {
    //         fontSize: 14,
    //         marginTop: 15,
    //         marginBottom: 5,
    //         fontWeight: 'bold',
    //         h6Align: 'center',
    //     },
    //     h6: {
    //         fontSize: 12,
    //         h6Align: 'justify',
    //         margin: 12
    //     },
    //     h6Center: {
    //         fontSize: 12,
    //         h6Align: 'center',
    //         margin: 12
    //     },
    //     h6SignCenter: {
    //         fontSize: 12,
    //         h6Align: 'center',
    //         margin: 3
    //     },
    //     lineCenter: {
    //         fontSize: 12,
    //         h6Align: 'center',
    //         margin: 2
    //     },
    //     celula: {
    //         fontSize: 12,
    //         h6Align: 'justify',
    //         border: 1,
    //         width: "33%",
    //         padding: 5,
    //         borderColor: "#000"
    //     },
    //     table: {
    //         display: "table",
    //         width: "auto",
    //         borderStyle: "solid",
    //         borderWidth: 1,
    //         borderRightWidth: 0,
    //         borderBottomWidth: 0
    //     },
    //     tableRow: {
    //         margin: "auto",
    //         flexDirection: "row"
    //     },
    //     tableCol: {
    //         width: "33%",
    //         borderStyle: "solid",
    //         borderWidth: 1,
    //         borderLeftWidth: 0,
    //         borderTopWidth: 0
    //     },
    //     tableCell: {
    //         margin: "auto",
    //         marginTop: 5,
    //         fontSize: 10
    //     }
    // });

    useEffect(() => {
        createContract();
    }, [customer]);

    function processAdministration() {
        let message = "administração da sociedade será ";
        const partner = partners.filter(x => x.adm === true);
        const notPartner = partners.filter(x => x.onlyAdm === true);

        if (data.adminitrationType == CustomerHelper().AdministationType.ApenasEmConjunto) {
            message += `representada ${partner.filter(x => !x.onlyAdm).length == partners.length ? "por todos sócios" : ``}`;
            for(let s of partner){
                    if(s.onlyAdm != true){
                        if(message.includes("pelo sócio")){
                            message += ` e ${s.name}`
                        }else{
                            message += ` pelo sócio ${s.name}`
                        }
                    }
                }
                if (notPartner.length > 0) {
                    if(message.includes("bem como")){
                        message += ` e o administrador não sócio ${notPartner[0].name}, ${notPartner[0].nationality}, ${CivilStateTranslate(notPartner[0].civilState)} ${notPartner[0].civilState != CivilStateObj.Single ? `sob ${CommunionTranslate(notPartner[0].communion)}` : ""}, nascido em ${OnlyDateTranslate(notPartner[0].birthDate)}, ${notPartner[0].jobTitle}, RG nº. ${notPartner[0].doc} SSP/SP e CPF (MF) nº. ${CpfMask(notPartner[0].stateId)}, residente e domiciliado a ${notPartner[0].street}, nº ${notPartner[0].number}, Bairro ${notPartner[0].neighborhood}, ${notPartner[0].city} – ${notPartner[0].state} - CEP ${CepMask(notPartner[0].postalCode)}`;
                    }else{
                        message += ` bem como o administrador não sócio ${notPartner[0].name}, ${notPartner[0].nationality}, ${CivilStateTranslate(notPartner[0].civilState)} ${notPartner[0].civilState != CivilStateObj.Single ? `sob ${CommunionTranslate(notPartner[0].communion)}` : ""}, nascido em ${OnlyDateTranslate(notPartner[0].birthDate)}, ${notPartner[0].jobTitle}, RG nº. ${notPartner[0].doc} SSP/SP e CPF (MF) nº. ${CpfMask(notPartner[0].stateId)}, residente e domiciliado a ${notPartner[0].street}, nº ${notPartner[0].number}, Bairro ${notPartner[0].neighborhood}, ${notPartner[0].city} – ${notPartner[0].state} - CEP ${CepMask(notPartner[0].postalCode)}`;
                    }
                }
            message += " apenas em conjunto";
        }

        if (data.adminitrationType == CustomerHelper().AdministationType.IsoladamenteEEmConjunto) {
            message += `representada ${partner.filter(x => !x.onlyAdm).length == partners.length ? "por todos sócios" : ``}`;
            for(let s of partner){
                    if(s.onlyAdm != true){
                        if(message.includes("pelo sócio")){
                            message += ` e ${s.name}`
                        }else{
                            message += ` pelo sócio ${s.name}`
                        }
                    }
                }
                if (notPartner.length > 0) {
                    if(message.includes("bem como")){
                        message += ` e o administrador não sócio ${notPartner[0].name}, ${notPartner[0].nationality}, ${CivilStateTranslate(notPartner[0].civilState)} ${notPartner[0].civilState != CivilStateObj.Single ? `sob ${CommunionTranslate(notPartner[0].communion)}` : ""}, nascido em ${OnlyDateTranslate(notPartner[0].birthDate)}, ${notPartner[0].jobTitle}, RG nº. ${notPartner[0].doc} SSP/SP e CPF (MF) nº. ${CpfMask(notPartner[0].stateId)}, residente e domiciliado a ${notPartner[0].street}, nº ${notPartner[0].number}, Bairro ${notPartner[0].neighborhood}, ${notPartner[0].city} – ${notPartner[0].state} - CEP ${CepMask(notPartner[0].postalCode)}`;
                    }else{
                        message += ` bem como o administrador não sócio ${notPartner[0].name}, ${notPartner[0].nationality}, ${CivilStateTranslate(notPartner[0].civilState)} ${notPartner[0].civilState != CivilStateObj.Single ? `sob ${CommunionTranslate(notPartner[0].communion)}` : ""}, nascido em ${OnlyDateTranslate(notPartner[0].birthDate)}, ${notPartner[0].jobTitle}, RG nº. ${notPartner[0].doc} SSP/SP e CPF (MF) nº. ${CpfMask(notPartner[0].stateId)}, residente e domiciliado a ${notPartner[0].street}, nº ${notPartner[0].number}, Bairro ${notPartner[0].neighborhood}, ${notPartner[0].city} – ${notPartner[0].state} - CEP ${CepMask(notPartner[0].postalCode)}`;
                    }
                }

            message += " em conjunto e isoladamente";
        }

        if (data.adminitrationType == CustomerHelper().AdministationType.ApenasIsoladamente && partner.length > 0) {
            message += ` representada isoladamente`
            if (partner.length > 0) {
                for(let s of partner){
                    if(s.onlyAdm != true){
                        if(message.includes("pelo sócio")){
                            message += ` e ${s.name}`
                        }else{
                            message += ` pelo sócio ${s.name}`
                        }
                    }
                }
            }

            if (notPartner.length > 0) {
                if(message.includes("bem como")){
                    message += ` e o administrador não sócio ${notPartner[0].name}, ${notPartner[0].nationality}, ${CivilStateTranslate(notPartner[0].civilState)} ${notPartner[0].civilState != CivilStateObj.Single ? `sob ${CommunionTranslate(notPartner[0].communion)}` : ""}, nascido em ${OnlyDateTranslate(notPartner[0].birthDate)}, ${notPartner[0].jobTitle}, RG nº. ${notPartner[0].doc} SSP/SP e CPF (MF) nº. ${CpfMask(notPartner[0].stateId)}, residente e domiciliado a ${notPartner[0].street}, nº ${notPartner[0].number}, Bairro ${notPartner[0].neighborhood}, ${notPartner[0].city} – ${notPartner[0].state} - CEP ${CepMask(notPartner[0].postalCode)}`;
                }else{
                    message += ` bem como o administrador não sócio ${notPartner[0].name}, ${notPartner[0].nationality}, ${CivilStateTranslate(notPartner[0].civilState)} ${notPartner[0].civilState != CivilStateObj.Single ? `sob ${CommunionTranslate(notPartner[0].communion)}` : ""}, nascido em ${OnlyDateTranslate(notPartner[0].birthDate)}, ${notPartner[0].jobTitle}, RG nº. ${notPartner[0].doc} SSP/SP e CPF (MF) nº. ${CpfMask(notPartner[0].stateId)}, residente e domiciliado a ${notPartner[0].street}, nº ${notPartner[0].number}, Bairro ${notPartner[0].neighborhood}, ${notPartner[0].city} – ${notPartner[0].state} - CEP ${CepMask(notPartner[0].postalCode)}`;
                }
            }
        }

        return message
    }

    function processProlabora() {
        let message = "";
        const partner = partners.filter(x => x.prolabore === true && x.onlyAdm == false);
        const excludePartner = partners.filter(x => x.onlyAdm == false);

        if (partner.length == excludePartner.length) {
            message += "Todos os sócios terão direito a uma retirada mensal a título de pró-labore";
        } else if (partner.length > 1) {
            for (let s of partner) {
                message += `o sócio ${s.name},`
            }

            message += `terão direito a uma retirada mensal a título de pró-labore`
        } else if (partner.length == 1) {
            message += `O sócio ${partner[0].name} terá direito a uma retirada mensal a título de pró-labore`
        }

        return message
    }

    function createContract() {
    }

    function uploadFile(files) {
        var formData = new FormData();
        formData.append("file", files[0]);
        UploadContract(userData.customerId, formData, 1, (res) => {
            toast.success("Salvo com sucesso, bem vindo!");
            setTimeout(() => {
                window.location = `#/customer-profile/${userData.customerId}`;
            }, 500);
        });
    }

    function signature(blob, url, loading, error) {
        if (loading === true && blob === null) {
            toast.error(
                "Ocorreu um erro ao gerar o contrato. Por favor entre em contato com o suporte da Hasa",
            );
        } else if (blob !== null) {
            var reader = new FileReader();
            if (hasCertificate === true) {
                if (customerType === customerHelper.Type.Undefined) {
                    toast.error("Selecione o tipo de certificado para prosseguir");
                    return;
                } else {
                    toast.info("Preparando contrato...");
                }
            }
            reader.readAsDataURL(blob);
            reader.onloadend = function () {
                var base64data = reader.result;
                uploadSigned(base64data);
            };
        } else {
            toast.info("Gerando o contrato");
        }
    }

    async function uploadSigned(base) {
        const body = {
            document: {
                path: `/Contrato-de-prestacao.pdf`,
                content_base64: base,
                customerType: customerType,
            },
        };

        ContractInsert(body.document, (res) => {
            toast.info("Siga o processo de assinatura do contrato");
            // window.open(res.signatureUrl, "_blank");
            window.location = res.signatureUrl;
        });
    }

    return (
        <div style={{ textJustify: "distribute"}}>
           
            <div style={baseFont}>
                <div>
                    <div>
                        <h4 className="font-w700 text-center" style={baseFont}>CONTRATO DE CONSTITUIÇÃO DE SOCIEDADE EMPRESÁRIA LIMITADA</h4>
                        <h6 className="underline text-center font-w700" style={baseFont}>{data.name} LTDA</h6>
                    </div>


                    {/* Sócios inicio */}
                    {partners && partners.map(item => {
                        if (!item.onlyAdm) {
                            return (
                                <div>
                                    <p className="text-justify">
                                        <span className="font-w700 underline">{item.name}</span>, {item.nationality}, {CivilStateTranslate(item.civilState)} {item.civilState != CivilStateObj.Single ? `sob ${CommunionTranslate(item.communion)}` : ""}, nascido em {OnlyDateTranslate(item.birthDate)}, {item.jobTitle}, RG nº. {item.doc} SSP/SP e CPF (MF) nº. {CpfMask(item.stateId)}, residente e domiciliado a {item.street}, nº {item.number}, Bairro {item.neighborhood}, {item.city} – {item.state} - CEP {CepMask(item.postalCode)}.
                                    </p>
                                </div>

                            )
                        }
                    })}
                    {data && data.societyType === CustomerHelper().SocietyType.SociedadeLimitada ? (
                    <div>
                        <p className="text-justify">
                        Tem entre si, justos e contratados, a constituição de uma Sociedade Empresária Limitada, que regerá pelas cláusulas e condições seguintes e nas omissões, pela legislação específica que disciplina essa forma societária.
                        </p>
                    </div>
                    ):data && data.societyType === CustomerHelper().SocietyType.SociedadeUnipessoal ? (
                    <div>
                        <p className="text-justify">
                        Por este instrumento constitui uma Sociedade Limitada Unipessoal, que regerá pelas cláusulas e condições seguintes, nos termos do parágrafo primeiro do artigo 1.052 do código civil de 2002 e nas omissões, pela legislação específica que disciplina essa forma societária.
                        </p>
                    </div>

                    ):""}


                    {/* Sócios Fim */}

                    <div>
                        <h6 className="text-center" style={baseFont}>CLÁUSULA PRIMEIRA</h6>
                        <p className="text-justify">
                            A sociedade girará sob a denominação social de <span className="font-w700 underline">{data && data.name} LTDA</span>, {data && data.hasFantasyName == true ? <span>sob o nome fantasia  <span className="font-w700 underline">{data && data.fantasyName}</span>,</span> : ""} e seu uso será obrigatório em todas as operações sociais da sociedade.
                        </p>
                    </div>

                    {data && data.taxCode !== CustomerHelper().TaxCode.Normal ?
                        <div>
                            <h6 className="text-center" style={baseFont}>
                                CLÁUSULA SEGUNDA
                            </h6>
                            <p className="text-justify">
                                O sócio declara que a sociedade limitada unipessoal se enquadra como {data.taxCode === CustomerHelper().TaxCode.ME ? "Microempresa" : "Empresa de Pequeno Porte"}, nos termos da Lei Complementar nº 123, de 14 de dezembro de 2006, e que não se enquadra em qualquer das hipóteses de exclusão relacionadas no § 4º do art. 3º da mencionada lei. (art. 3º, I, LC nº 123, de 2006).
                            </p>
                        </div>
                        : ""}




                    <div>
                        <h6 className="text-center" style={baseFont}>CLÁUSULA
                            {data && data.taxCode !== CustomerHelper().TaxCode.Normal ? " TERCEIRA" : " SEGUNDA"}
                        </h6>
                        <p className="text-justify">
                            A sociedade terá sua sede à {data && data.street}, nº {data && data.number}, Bairro {data && data.neighborhood}, {data && data.city} – {data && data.state} - CEP {data && CepMask(data.postalCode)}, podendo abrir filiais ou sucursais em qualquer ponto do território nacional, obedecendo às disposições vigentes.
                        </p>
                    </div>

                    <div>
                        <h6 className="text-center" style={baseFont}>CLÁUSULA
                            {data && data.taxCode !== CustomerHelper().TaxCode.Normal ? " QUARTA" : " TERCEIRA"}
                        </h6>
                        <p className="text-justify">
                            O objetivo da sociedade será de {data && data.description}
                        </p>
                    </div>

                    <h6 className="text-center" style={baseFont}>CLÁUSULA
                        {data && data.taxCode !== CustomerHelper().TaxCode.Normal ? " QUINTA" : " QUARTA"}
                    </h6>
                    <p className="text-justify">
                        O capital social é de {data && data.capital && MoneyMask(data.capital, 2)} ({data && data.capitalExtended}) reais dividido em {data && data.capital} ({data && data.capitalExtended}) quotas no valor nominal de R$ 1,00 (um real) cada uma, totalmente integralizado, neste ato em moeda corrente do país {data.societyType === CustomerHelper().SocietyType.SociedadeUnipessoal ? <span>, ficando o sócio <span className="font-w700 underline">{partners && partners[0] && partners[0].name}</span> detentor de sua totalidade.</span> : "e assim distribuído da seguinte forma entre os sócios:"}
                    </p>
                    {data && data.societyType !== CustomerHelper().SocietyType.SociedadeUnipessoal ?
                        <table className="table table-bordered">
                            {partners && partners.map(item => {
                                if(!item.onlyAdm){
                                    return (<tr><td>{item.name}</td>
                                        <td>{item.value} Quotas</td>
                                        <td> {MoneyMask(item.value, 2)}</td>
                                    </tr>)
                                }
                            })}

                            <tr>
                                <td>Total</td>
                                <td>{data && data.capital} Quotas</td>
                                <td> {data && MoneyMask(data.capital, 2)}</td>
                            </tr>
                        </table>
                        : ""}

                    {data && data.societyType !== CustomerHelper().SocietyType.SociedadeUnipessoal ?
                        <div>
                            <p className="text-justify">
                                <span className="font-w700">Parágrafo Único</span>: A responsabilidade de cada sócio é restrita ao valor de suas quotas, mas todos respondem solidariamente pela integralização do Capital Social. (art. 1052, CC/2002).
                            </p>
                        </div>
                        :
                        <div>
                            <p className="text-justify">
                                <span className="font-w700">Parágrafo Único</span>: A responsabilidade do sócio é restrita ao valor de suas quotas, respondendo solidariamente pela integralização do Capital Social. (art. 1052, CC/2002).
                            </p>
                        </div>
                    }



                    <div>
                        <h6 className="text-center" style={baseFont}>CLÁUSULA
                            {data && data.taxCode !== CustomerHelper().TaxCode.Normal ? " SEXTA" : " QUINTA"}
                        </h6>
                        <p className="text-justify">
                            O	prazo de duração da sociedade será por tempo indeterminado.
                        </p>
                    </div>

                    <div>
                        <h6 className="text-center" style={baseFont}>CLÁUSULA
                            {data && data.taxCode !== CustomerHelper().TaxCode.Normal ? " SÉTIMA" : " SEXTA"}
                        </h6>
                        <p className="text-justify">
                            A {data && processAdministration()}, ativa e passivamente, judicial e extrajudicial, ficando, entretanto, vedado o uso da firma em negócios alheios aos fins sociais.
                        </p>
                        <p className="text-justify">
                            <span className="font-w700">Parágrafo Único</span>:- Na ausência ou impedimento dos sócios administradores, todas as funções inerentes ao seu cargo, serão acumuladas por outra pessoa, sendo que em caso algum a interferência de terceiros, serão aceitas salvo autorização reciprocamente consentida pela parte.
                        </p>
                    </div>


                    <div>
                        <h6 className="text-center" style={baseFont}>CLÁUSULA
                            {data && data.taxCode !== CustomerHelper().TaxCode.Normal ? " OITAVA" : " SÉTIMA"}
                        </h6>
                        {data.societyType === CustomerHelper().SocietyType.SociedadeUnipessoal ?
                            <p className="text-justify">
                                O	uso da firma será feito pelo sócio <span className="font-w700 underline">{partners && partners[0] && partners[0].name}</span>, sempre nos negócios que digam respeito aos interesses sociais, sendo proibido seu uso para fins estranhos como endosso de favores, cartas de fiança e outros documentos não análogos que acarretarem responsabilidade para a sociedade limitada unipessoal.
                            </p>
                            :
                            <p className="text-justify">
                                O uso da firma será feito por todos os sócios, sempre nos negócios que digam respeito aos interesses sociais, sendo proibido seu uso para fins estranhos como endosso de favores, cartas de fiança e outros documentos não análogos que acarretarem responsabilidade para a sociedade.
                            </p>
                        }
                    </div>


                    <div>
                        <h6 className="text-center" style={baseFont}>CLÁUSULA
                            {data && data.taxCode !== CustomerHelper().TaxCode.Normal ? " NONA" : " OITAVA"}
                        </h6>

                        {data.societyType === CustomerHelper().SocietyType.SociedadeUnipessoal ?
                            <p className="text-justify">
                                O sócio <span className="font-w700 underline">{partners && partners[0] && partners[0].name}</span> terá direito a uma retirada mensal a título de pró-labore, em valor a ser fixado a cada mês
                                de acordo com a participação proporcional de suas quotas, mas sempre dentro da legislação do Imposto de Renda
                                e será levado a uma conta de <span className="font-w700 underline">DESPESAS ADMINISTRATIVAS</span>.
                            </p>
                            :
                            <p className="text-justify">
                                {data && processProlabora()}, em valor a ser fixado a cada mês
                                de acordo com a participação proporcional de suas quotas, mas sempre dentro da legislação do Imposto de Renda
                                e será levado a uma conta de <span className="font-w700 underline">DESPESAS ADMINISTRATIVAS</span>.
                            </p>
                        }
                    </div>

                    <div>
                        <h6 className="text-center" style={baseFont}>CLÁUSULA
                            {data && data.taxCode !== CustomerHelper().TaxCode.Normal ? " DÉCIMA" : " NONA"}
                        </h6>
                        {data.societyType === CustomerHelper().SocietyType.SociedadeUnipessoal ?
                            <p className="text-justify">
                                Todo dia 31 de dezembro de cada ano será procedido um balanço do exercício, sendo que os lucros ou prejuízos verificados serão distribuídos ou suportados pelo sócio na proporção de suas quotas de capital.
                            </p>
                            :
                            <p className="text-justify">
                                Todo dia 31 de dezembro de cada ano será procedido um balanço do exercício, sendo que os lucros ou prejuízos verificados serão distribuídos ou suportados pelos sócios na proporção de suas quotas de capital.
                            </p>
                        }

                        {data.societyType === CustomerHelper().SocietyType.SociedadeUnipessoal ?
                            <p className="text-justify">
                                <span className="font-w700">Parágrafo único</span>: A sociedade poderá levantar balanços intermediários, semestrais ou em períodos menores, com o objetivo de distribuir lucros antecipados a seu sócio antes do encerramento do exercício social, observando a contribuição ou participação do sócio.
                            </p>
                            :
                            <p className="text-justify">
                                <span className="font-w700">Parágrafo único</span>: A sociedade poderá levantar balanços intermediários, semestrais ou em períodos menores, com o objetivo de distribuir lucros antecipados a seus sócios antes do encerramento do exercício social, observando a contribuição ou participação de cada sócio.
                            </p>
                        }

                    </div>

                    <div>
                        <h6 className="text-center" style={baseFont}>CLÁUSULA
                            {data && data.taxCode !== CustomerHelper().TaxCode.Normal ? " DÉCIMA PRIMEIRA" : " DÉCIMA"}
                        </h6>
                        <p className="text-justify">
                            Todas as quotas da sociedade são indivisíveis e não poderão ser cedidas ou transferidas sem o expresso consentimento dos sócios que irão adquiri-las no caso de algum quotista pretender ceder as que possuem.
                        </p>
                    </div>

                    <div>
                        <h6 className="text-center" style={baseFont}>CLÁUSULA {data && data.taxCode !== CustomerHelper().TaxCode.Normal ? " DÉCIMA SEGUNDA" : " DÉCIMA PRIMEIRA"}</h6>
                        <p className="text-justify">
                            No caso de falecimento {data.societyType === CustomerHelper().SocietyType.SociedadeUnipessoal ? "do sócio" : "de quaisquer dos sócios"} a sociedade não será extinta, levantando-se um balanço especial nesta data e se convier aos herdeiros do Pré-morto, será lavrado um novo contrato com a inclusão destes com os direitos legais ou então, os herdeiros receberão todos seus haveres apurados até o balanço especial, em 10 (dez) prestações iguais e sucessivamente vencendo-se a primeira após 60 (sessenta) dias da data do Balanço Especial ou de acordo com o que se combinar na ocasião do evento.
                        </p>
                    </div>

                    <div>
                        <h6 className="text-center" style={baseFont}>CLÁUSULA {data && data.taxCode !== CustomerHelper().TaxCode.Normal ? " DÉCIMA TERCEIRA" : " DÉCIMA SEGUNDA"}</h6>
                        <p className="text-justify">
                            {data.societyType === CustomerHelper().SocietyType.SociedadeUnipessoal || partners.filter(x => x.adm == true).length === 1 ? "O administrador declara, sob as pena da Lei, de que não está impedido de exercer" : "Os administradores declaram, sob as pena da Lei, de que não estão impedidos de exercerem"} a administração da sociedade, por lei especial, ou em virtude de condenação criminal, ou por se encontrarem sob os efeitos dela, a pena que vede, ainda que temporariamente, o acesso a cargos públicos, ou por crime falimentar, de prevaricação, peita ou suborno, concussão, peculato, ou contra a economia popular, contra o sistema financeiro nacional, contra normas de defesa da concorrência, contra as relações de consumo, fé pública, ou a propriedade.
                        </p>
                        <p className="text-justify">
                            Fica eleito o foro desta Comarca para qualquer ação fundada neste Instrumento de Contrato Social.
                        </p>
                        <p className="text-justify">
                            E assim{data.societyType === CustomerHelper().SocietyType.SociedadeUnipessoal ? ", assina" : " por estarem justos e contratados, assinam"} o presente instrumento em 1 (uma) via e para validade será registrado e arquivado na {data && ElaboratorHelper.GetOrgan(data.state)}.
                        </p>
                        <h6 className="text-right font-w700" style={baseFont}>
                            {data && data.city}, {date.getDate()} de {ReportHelper().translateMonth(date.getMonth())} de {date.getFullYear()}.
                        </h6>
                    </div>

                    {partners && partners.map(item => (
                        <div>
                            <p className="text-center">
                                ________________________________________________________
                            </p>
                            <h6 className="text-center">{item.name}</h6>
                            <br />
                            <br />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
