import React, { useState, useEffect, useRef } from "react";
import CustomModal from "@/app/core/layouts/main/components/CustomModal";
import { toast } from "react-toastify";
import { Tabs, Tab, Panel } from "@bumaga/tabs";
import ProcessCard from "@/app/core/layouts/main/elements/ProcessCard";
import TimelineComponent from "@/app/core/layouts/main-components/TimelineComponent";
import ProcessNotesComponent from "@/app/core/layouts/main-components/ProcessNotes";
import ExigenciesComponent from "@/app/core/layouts/main-components/ExigenciesComponent";
import DocsComponent from "@/app/core/layouts/main-components/DocsComponent";
import ProtocolComponent from "./ProtocolComponent";
import ProcessController from "../../base/controllers/ProcessController";
import Storage from "@/app/core/util/Storage";
import CustomerExigencies from "./exigencies/CustomerExigencies";
import { Dropdown } from "react-bootstrap";
import { ProcessStatus } from "../../util/helpers/SelectedServiceHelper";
import OrganListController from "../../base/controllers/OrganListController";
import { OrganStatus } from "../../util/helpers/OrganListHelper";
import ModalDoc from "./documents/ModalDoc";
import HelpModal from "./HelpModal";
import { ExigencyList } from "../../base/controllers/ExigenciesController";
import { ExigencyStatus } from "../../util/constants/ExigenciesConstants";
import ConfirmReview from "./organs/ConfirmReview";
import ReportIssueModal from "./feedback/ReportIssueModal";
import StatusTab from "./process/StatusTab";
import ProcessHelper from "../../util/helpers/ProcessHelpers";
import FollowingProcessButton from "./process/FollowingProcessButton";
import FolderTemplatesComponent from "@/app/modules/preparation/main/Templates";

function OperationProcessModal(props) {
  const [data, setData] = useState({});
  const [currentProcess, setProcess] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showIssueModal, setShowIssueModal] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [disable, setDisable] = useState(false);
  const [exigencyPending, setExigencyPending] = useState(false);
  const [showFractioned, setShowFractioned] = useState(false);
  const [organList, setOrganList] = useState({});
  const processCtrl = ProcessController();
  const organCtrl = OrganListController();
  const processHelper = ProcessHelper();

  const enviroment = process.env.REACT_APP_ENV;

  const userData = Storage.getUserData();
  const { show, onHide, submit } = props;

  useEffect(() => {
    if (show) {
      setData(props.data);
      if (
        props.data !== undefined &&
        props.data !== null &&
        props.data.id !== undefined
      ) {
        loadProcess();
      }
    }
  }, [props.data, refresh, show]);

  function confirmExigencies(res) {
    // ExigencyList(props.data.id, (res) => {
    let openExigencies = res.filter(
      (x) =>
        x.status == ExigencyStatus.waiting ||
        x.status == ExigencyStatus.WaitingFulfillment,
    );
    let confirmExigencies = res.filter(
      (x) => x.status === ExigencyStatus.waitingConfirmation,
    );
    if (userData.level === 5 && openExigencies.length > 0) {
      setExigencyPending(true);
    } else if (userData.level !== 5 && confirmExigencies.length > 0) {
      setExigencyPending(true);
    }
    // });
  }

  async function loadOrganList(res) {
    setIsLoading(true);
    setIsLoading(false);
    setOrganList(res);

    if (
      res.stage === 2 &&
      (res.analystId === userData.id ||
        (userData.role > 0 && userData.level === 2) ||
        userData.level === 6)
    ) {
      setDisable(false);
    } else if (
      res.stage === 3 &&
      (res.preparerId === userData.id ||
        (userData.role > 0 && userData.level === 3))
    ) {
      setDisable(false);
    } else if (
      res.stage === 4 &&
      (res.devolutionNotStartedId === userData.id ||
        res.devolutionFiledId === userData.id ||
        res.devolutionDevolutionId === userData.id ||
        res.devolutionExigencyId === userData.id ||
        res.devolutionDeferredId === userData.id)
    ) {
      setDisable(false);
    } else {
      setDisable(true);
    }

    if (res.status == ProcessStatus.Returned) {
      setDisable(true);
    }
  }

  function save() { }

  async function loadProcess() {
    if (props.data.id !== undefined) {
      setIsLoading(true);
      await processCtrl.TheProcess(null, props.data.id, (res) => {
        setProcess(res);
        setIsLoading(false);
        if (props.data && props.data.organListId !== undefined) {
          loadOrganList(
            processHelper.GetCurrentOrgan(props.data.organListId, res),
          );
        }
        if (res.exigencies) {
          confirmExigencies(res.exigencies);
        }
        if (res.status == ProcessStatus.Returned) {
          setDisable(true);
        }
      });
    }
  }

  function showDoc(item) {
    setSelectedDoc(item);
    setShowModal(true);
  }

  function onChangeProcess(evt) {
    let old = process;
    let newInfo = { complains: evt.target.value };

    setProcess({ ...old, ...newInfo });
  }

  async function saveComplains() {
    if (process.complains === undefined || currentProcess.complains === "") {
      toast.error("Preencha todas as informações para poder salvar");
    } else {
      await processCtrl.Update(process, (res) => {
        toast.info(
          "Salvo com sucesso, estamos informando as pessoas responsáveis",
        );
        loadProcess();
      });
    }
  }

  function processButton() {
    if (organList && organList.id !== undefined) {
      let show = false;
      if (userData.level > 3) {
        show = true;
      }
      if (userData.id === organList.analystId) {
        show = true;
      }
      if (userData.id === organList.preparerId) {
        show = true;
      }

      if (show) {
        return (
          <ConfirmReview organList={organList} reload={() => loadProcess()} />
        );
      }
    }
  }

  return (
    <CustomModal
      title="Processo"
      show={show}
      isLoading={isLoading}
      onHide={onHide}
      onSave={() => save()}
      onSubmit={() => save()}
    >
      <div className="row">
        <div className="col-md-12">
          <Tabs>
            <div
              className="nav nav-tabs nav-tabs-block nav-justified js-tabs-enabled"
              style={{ padding: 7 }}
            >
              <Tab>
                <button
                  type="button"
                  className="btn btn-dual mr-1 border-right"
                  data-toggle="layout"
                  alt="Documentos"
                >
                  <i className="si si-diamond"></i> Processo
                </button>
              </Tab>

              {/* {enviroment == "staging" || enviroment == "local" ? */}
              {currentProcess &&
                currentProcess.status != ProcessStatus.Returned ? (
                <Tab className="nav-item">
                  <button
                    type="button"
                    className="btn btn-dual mr-1 border-right"
                  >
                    <i className="far fa-fw fa-clock"></i> Status
                    {currentProcess &&
                      ProcessHelper().StatusTicker(currentProcess)}
                  </button>
                </Tab>
              ) : (
                ""
              )}

              {/* : ""} */}

              {(organList &&
                (organList.status === OrganStatus.Deferred ||
                  organList.status === OrganStatus.Done) &&
                currentProcess.status != ProcessStatus.Returned &&
                currentProcess.status !== ProcessStatus.Done) ||
                userData.level !== 5 ? (
                <Tab>
                  <button
                    type="button"
                    className="btn btn-dual mr-1  border-right"
                    data-toggle="layout"
                    alt="Documentos"
                  >
                    <i className="far fa-file-pdf"></i> Documentos
                  </button>
                </Tab>
              ) : (
                ""
              )}
              {organList &&
                userData.level === 5 &&
                // (organList.status === OrganStatus.Deferred ||
                //   organList.status === OrganStatus.Done) &&
                ((currentProcess.status != ProcessStatus.Returned &&
                  currentProcess.status !== ProcessStatus.Done) ||
                  (showFractioned == true &&
                    currentProcess.status == ProcessStatus.Done)) ? (
                <Tab>
                  <button
                    type="button"
                    className="btn btn-dual mr-1  border-right"
                    data-toggle="layout"
                    alt="Documentos"
                  >
                    <i className="far fa-file-pdf"></i> Documentos{" "}
                    {/* {userData.level === 5 && showFractioned != true ? "registrados" : ""} */}
                  </button>
                </Tab>
              ) : (
                ""
              )}
              {userData && userData.level !== 5 ? (
                <Tab>
                  <button
                    type="button"
                    className="btn btn-dual mr-1  border-right"
                    data-toggle="layout"
                    alt="Documentos"
                  >
                    <i className="fa fa-tasks"></i> Timeline
                  </button>
                </Tab>
              ) : (
                ""
              )}
              {currentProcess &&
                currentProcess.status != ProcessStatus.Returned &&
                currentProcess.status !== ProcessStatus.Done &&
                userData.level === 5 ? (
                <Tab>
                  <button
                    type="button"
                    className="btn btn-dual mr-1 border-right"
                    data-toggle="layout"
                    alt="Documentos"
                  >
                    <i className="fa fa-tasks"></i> Exigências{" "}
                    {exigencyPending === true ? (
                      <i className="fa fa-exclamation-circle text-danger" />
                    ) : (
                      ""
                    )}
                  </button>
                </Tab>
              ) : (
                ""
              )}
              {userData && userData.level !== 5 ? (
                <Tab>
                  <button
                    type="button"
                    className="btn btn-dual mr-1 border-right"
                    data-toggle="layout"
                    alt="Documentos"
                  >
                    <i className="fa fa-images"></i> Exigências{" "}
                    {exigencyPending === true ? (
                      <i className="fa fa-exclamation-circle text-danger" />
                    ) : (
                      ""
                    )}
                  </button>
                </Tab>
              ) : (
                ""
              )}
              {userData && userData.level !== 5 ? (
                <Tab>
                  <button
                    type="button"
                    className="btn btn-dual mr-1 border-right"
                    data-toggle="layout"
                    alt="Documentos"
                  >
                    <i className="fa fa-folder"></i> Observações
                    {currentProcess &&
                      currentProcess.customerNote &&
                      currentProcess.customerNote.length > 0 ? (
                      <i className="fa fa-exclamation-circle text-danger" />
                    ) : (
                      ""
                    )}
                  </button>
                </Tab>
              ) : (
                ""
              )}
              {userData && userData.level !== 5 ? (
                <Tab className="nav-item">
                  <button
                    type="button"
                    className="btn btn btn-dual border-right"
                  >
                    <i className="far fa-bookmark"></i> Consultas & protocolos
                  </button>
                </Tab>
              ) : (
                ""
              )}
              {userData.level !== 5 && userData.level !== 2 && userData.level !== 3 ? (
                <Tab className="nav-item">
                  <button
                    type="button"
                    className="btn btn-dual mr-1 border-right"
                    data-toggle="layout"
                    alt="Documentos"
                  >
                    <i className="far fa-fw fa-clipboard"></i> Templates
                  </button>
                </Tab>
              ) : (
                ""
              )}
              {userData === null || userData.level === false ? (
                <Dropdown>
                  <Dropdown.Toggle
                    variant={"light"}
                    className="btn btn-sm btn-dual border-right"
                  >
                    <i className="fa fa-exclamation-circle text-danger" /> Não
                    estou contente
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <div className="form-group">
                      <span>Escreva suas considerações : </span>
                      <textarea
                        className="form-control"
                        value={currentProcess && currentProcess.complains}
                        onChange={(evt) => onChangeProcess(evt)}
                      />
                    </div>
                    <div className="text-center">
                      <button
                        className="btn btn-sm btn-success"
                        onClick={() => saveComplains()}
                      >
                        Enviar
                      </button>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                ""
              )}
              {/* {userData &&
                userData.level === 5 &&
                currentProcess.status !== ProcessStatus.Done ? (
                <button
                  type="button"
                  class="btn btn-sm btn-dual"
                  style={{ float: "right" }}
                  onClick={() => setShowHelpModal(true)}
                >
                  <i class="si si-question"></i> Posso ajudar?
                </button>
              ) : (
                ""
              )} */}
              {userData &&
                currentProcess.status != ProcessStatus.Returned &&
                userData.level === 5 &&
                currentProcess.status !== ProcessStatus.Done ? (
                <button
                  type="button"
                  class="btn btn-dual"
                  style={{ float: "right" }}
                  onClick={() => setShowIssueModal(true)}
                >
                  <i class="si si-question"></i> Fale conosco
                </button>
              ) : (
                ""
              )}
              {/* {userData &&
                userData.level !== 5 &&
                currentProcess.status !== ProcessStatus.Done && (enviroment == "staging" || enviroment == "local") ? (
                <FollowingProcessButton processId={data && data.id} />
              ) : (
                ""
              )} */}
            </div>
            <div className="ml-1 mr-1">
              <div className="col-md-12 text-center">
                {userData && userData.level !== 5 && processButton()}
              </div>

              {currentProcess &&
                currentProcess.status == ProcessStatus.Returned &&
                userData &&
                userData.level == 5 ? (
                <div>
                  <div className="block block-fx-pop block-bordered border-danger mt-1 mb-0">
                    <div className="block-header">
                      <div className="block-title">
                        <i className="fa fa-exclamation-circle text-danger mr-2" />
                        Processo devolvido por não cumprimento de exigências
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              <Panel>
                <ProcessCard
                  data={currentProcess}
                  processId={currentProcess && currentProcess.id}
                  organList={data && data.organListId}
                  organListId={data && data.organListId}
                  disabled={disable}
                  preLoad={currentProcess}
                  refresh={() => setRefresh(!refresh)}
                  reportFractioned={() => setShowFractioned(true)}
                />
              </Panel>
              {/* {enviroment == "staging" || enviroment == "local" ? */}
              {currentProcess &&
                currentProcess.status != ProcessStatus.Returned ? (
                <Panel>
                  <StatusTab
                    processId={currentProcess && currentProcess.id}
                    organListId={data && data.organListId}
                    view={(data) => showDoc(data)}
                    refresh={() => setRefresh(!refresh)}
                  />
                </Panel>
              ) : (
                ""
              )}
              {/* : ""} */}
              {(organList &&
                (organList.status === OrganStatus.Deferred ||
                  organList.status === OrganStatus.Done) &&
                currentProcess.status !== ProcessStatus.Done) ||
                currentProcess.status === ProcessStatus.Done ||
                userData.level !== 5 ? (
                <Panel>
                  <DocsComponent
                    processId={currentProcess && currentProcess.id}
                    organListId={data && data.organListId}
                    budgetId={0}
                    setView={(url) => showDoc(url)}
                    // disabled={disable}
                    preLoad={currentProcess}
                    refresh={() => setRefresh(!refresh)}
                    showAll={showFractioned}
                    search={true}
                  />
                </Panel>
              ) : (
                ""
              )}
              {organList &&
                userData.level === 5 &&
                // (organList.status === OrganStatus.Deferred ||
                //   organList.status === OrganStatus.Done) &&
                currentProcess.status !== ProcessStatus.Done ? (
                <Panel>
                  <DocsComponent
                    processId={currentProcess && currentProcess.id}
                    organListId={data && data.organListId}
                    budgetId={0}
                    setView={(url) => showDoc(url)}
                    search={true}
                    preLoad={currentProcess}
                    refresh={() => setRefresh(!refresh)}
                  // disabled={disable}
                  />
                </Panel>
              ) : (
                ""
              )}
              {userData && userData.level !== 5 ? (
                <Panel>
                  <TimelineComponent
                    processId={data && data.id}
                    organListId={data && data.organListId}
                    view={(url) => showDoc(url)}
                    search={true}
                    preLoad={currentProcess}
                    refresh={() => setRefresh(!refresh)}
                  />
                </Panel>
              ) : (
                ""
              )}
              <Panel>
                {userData && userData.level === 5 ? (
                  <CustomerExigencies
                    processId={currentProcess && currentProcess.id}
                    organListId={data && data.organListId}
                  />
                ) : (
                  <ExigenciesComponent
                    processId={data && data.id}
                    organListId={data && data.organListId}
                    disabled={disable}
                    preLoad={currentProcess}
                    refresh={() => setRefresh(!refresh)}
                  />
                )}
              </Panel>
              {userData.level !== 5 ? (
                <Panel>
                  <ProcessNotesComponent
                    organListId={data && data.organListId}
                    processId={data && data.id}
                    disabled={disable}
                    open={(url) => showDoc(url)}
                    search={true}
                  />
                </Panel>
              ) : (
                ""
              )}

              {userData.level !== 5 ? (
                <Panel>
                  <div className="block block-content">
                    <ProtocolComponent
                      processId={data && data.id}
                      organListId={data && data.organListId}
                      disabled={disable}
                      search={true}
                    />
                  </div>
                </Panel>
              ) : (
                ""
              )}
              {userData.level !== 5 && userData.level !== 2 && userData.level !== 3 ? (
                <Panel>
                  <div className="">
                    <FolderTemplatesComponent setView={(data) => showDoc(data)} />
                  </div>
                </Panel>
              ) : (
                ""
              )}
            </div>
          </Tabs>
        </div>
      </div>
      <ModalDoc
        show={showModal}
        onHide={() => setShowModal(false) | setSelectedDoc("")}
        doc={selectedDoc}
      />
      <HelpModal
        show={showHelpModal}
        processId={data && data.id}
        processName={data && data.processName}
        organListId={data && data.organListId}
        onHide={() => setShowHelpModal(false)}
      // report={(message) => requestContact(message)}
      />
      <ReportIssueModal
        show={showIssueModal}
        processId={data && data.id}
        processName={data && data.processName}
        organListId={data && data.organListId}
        onHide={() => setShowIssueModal(false)}
      // report={(message) => requestContact(message)}
      />
    </CustomModal>
  );
}
export default OperationProcessModal;
