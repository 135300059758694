import React, { useEffect, useState } from "react";
import ToDoController from "@/app/core/base/controllers/ToDoController";
import TaskModal from "./TaskModal";
import { GeneralHelper } from "@/app/core/util/helpers/GeneralHelper";
import { toast } from "react-toastify";
import SelectPlanModal from "@/app/core/layouts/main-components/plan/SelectPlanModal";
import PlanButton from "@/app/core/layouts/main-components/plan/PlanButton";
import Storage from "@/app/core/util/Storage";

export default function ResumeTaskCard({ reload, customerId, available }) {
  const [showModal, setShowModal] = useState(false);
  const [showPlanModal, setShowPlanModal] = useState(false);
  const [data, setData] = useState([]);
  const userData = Storage.getUserData();

  useEffect(() => {
    if(customerId){
      loadTasks()
    }
  }, [customerId])

  function loadTasks() {
    if(userData && userData.level == 5){
      ToDoController().List(customerId, res => {
        setData(res.filter(x => x.done == false && x.userId === userData.id))
      })
    }
  }

  function openItem() {
    if (available && userData && userData.level == 5) {
      window.location = `#/todo?customerId=${customerId}`
    } else if(userData && userData.level !== 5){
      toast.error("Área restrita ao cliente")
    }else {
      
    }
  }

  return (
    <div>
      <div
        className="block block-fx-pop bg-white block-roundedMax mb-3 pb-3 ml-1 cursor"
        style={{ height: 210 }}
        onClick={() => openItem()}
      >
        {userData && userData.level !== 5 ? 
        
        <div>
        <div className="block-header">
          <div className="block-title">
            <i className="fa fa-tasks fa-2x"></i>
          </div>
          <div className="block-tools">
            <i
              className="si si-question "
              title="Adicione, edite e exclua tarefas facilmente."
              />
          </div>
        </div>
              <h3 className="ml-4">Lista de tarefas <br/> <br/> <span className="text-muted mt-3 pt-4"><i className="si si-lock"/> Área restrita ao cliente</span></h3> 
        </div>
              
              : 
        <div>
          <div className="block-header">
            <div className="block-title">
              <i className="fa fa-tasks fa-2x"></i>
            </div>
            <div className="block-tools">
              <i
                className="si si-question "
                title="Adicione, edite e exclua tarefas facilmente."
              />
            </div>
          </div>
          {available ?
            <div className="block-content pt-0">
              {data && data.length == 0 ? (
                <div>
                  <h4 className=" mt-0">
                    Você não tem nenhuma tarefa em aberto
                  </h4>
                  <button className="btn btn-info">Adicionar</button>
                </div>
              ) : (
                <div>
                  <h3 className=" mt-0">
                    {data && data.length}{" "}
                    {data && data.length == 1 ? "Tarefa" : "Tarefas"}  em aberto
                  </h3>

                  <ul>
                    {data && data.map((item, index) => {
                      if (index < 2 && item.description && item.description.length > 0) {
                        return (
                          <li className="text-dark"> {GeneralHelper.LegibleName(item.description, 35)}</li>
                        )
                      }
                    })}
                  </ul>
                </div>
              )}
            </div>
            :
            <PlanButton customerId={customerId} name={"Lista de tarefas"} reload={() => reload()}/>
          }
        </div>
              }
      </div>
      <TaskModal
        show={showModal}
        customerId={customerId}
        reload={() => reload()}
        onHide={() => setShowModal(false) | loadTasks()}
        />
    </div>
  );
}
