import React, { useState, useEffect, useRef } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Logo from "@/assets/img/logo.jpg";
import { ReminderController } from "@/app/core/base/controllers/ReminderController";
import { ReminderHelper } from "@/app/core/util/helpers/ReminderHelper";
import { ReminderFileController } from "@/app/core/base/controllers/ReminderFileController";
import { Message } from "@/app/core/util/messages/GeralMessages";
import Storage from "@/app/core/util/Storage";
import { OnDataChange } from "@/app/core/util/services/DataService";

import { ExtrairTexto, FieldTypeObj } from "@/app/core/util/helpers/FieldTypeHelper";
import CurrencyFormat from "react-currency-format";
import {
  CPFValidator,
  CivilState,
  CivilStateObj,
  CivilStateTranslate,
  Communion,
  CommunionObj,
  CommunionTranslate,
  ValidaCNPJ,
} from "@/app/core/util/helpers/UserHelper";
import InputMask from "react-input-mask";
import { CpfMask } from "@/app/core/util/helpers/ContactHelper";
import { StateList } from "@/app/core/base/controllers/StateController";
import CustomerHelper from "@/app/core/util/helpers/CustomerHelper";
import { AdjustCompanyData, GetAddress, GetCompanyByTaxId } from "@/app/core/base/controllers/GlobalController";
import { number } from "yup";
import { MoneyMask } from "@/app/core/util/helpers/MoneyHelper";
import ModeloConstituicao from "./components/ModeloConstituicao";
import ModeloAlteracao from "./components/ModeloAlteracao";
import { YearsAgo } from "@/app/core/util/DateTranslater";
import ModeloDistrato from "./components/ModeloDistrato";
import Field from "@/app/core/util/helpers/Field";
import { ConsultController } from "@/app/core/base/controllers/ConsultController";
import { ElaboratorHelper } from "@/app/core/util/helpers/ElaboratorHelper";
import { ElaboratorController } from "@/app/core/base/controllers/ElaboratorController";
import { PartnerController } from "@/app/core/base/controllers/PartnerController";
import { CustomerSelect } from "@/app/core/base/controllers/CustomerController";
import GenerateAlterationContract from "./components/ModeloAlteracao";
import { Dropdown } from "react-bootstrap";

export default function DrafterComponent(props) {
  const [step, setStep] = useState(ElaboratorHelper.AlterationSteps.Confirmation);
  const [progress, setProgress] = useState(1);
  const [division, setDivision] = useState(1);
  const [form, setForm] = useState(0);
  const [logo, setLogo] = useState("");
  const [data, setData] = useState({
    adminitrationType: CustomerHelper().AdministationType.ApenasIsoladamente,
    taxCode: CustomerHelper().TaxCode.ME,
  });
  const [partner, setPartner] = useState({});
  const [partners, setPartners] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newPartner, setNewPartner] = useState(false);
  const [userData, setUserData] = useState(Storage.getUserData());
  const [states, setStates] = useState([]);
  const inputFile = useRef(null);
  const date = new Date();
  const [change, setChange] = useState(date.getTime());

  function onUploadClick() {
    inputFile.current.click();
  }

  useEffect(() => {
    processStep()
  }, [step]);

  useEffect(() => {
    loadStates();
    loadForm()
  }, []);

  function loadForm() {
    ElaboratorController.Get(props.match.params.key, res => {
      setData(res);
      setPartners(res.partners);
      setForm(res.type);

      if (res.type === ElaboratorHelper.Type.Abertura) {
        setDivision(100 / 10);
      } else if (res.type === ElaboratorHelper.Type.Alteracao) {
        setDivision(100 / 3);
      }

      console.log("Tipo de sociedade", res)

      CustomerSelect(res.customerId, customer => {
        if (customer.avatarImages && customer.avatarImages.length > 0) {
          setLogo(customer.avatarImages[0].url);
        }
      })
    })
  }

  async function updateBase(done) {
    let base = data;
    if (base.status === ElaboratorHelper.Status.Pending) {
      base.status = ElaboratorHelper.Status.Ongoing;
    } else if (done === true) {
      base.status = ElaboratorHelper.Status.Done;
    }
    if (partners.length > 0) {
      for (let s of partners) {
        await PartnerController.Update(s, res => { })
      }
    }

    if (partners.length === 1 && data.societyType === CustomerHelper().SocietyType.SociedadeUnipessoal) {
      base.adminitrationType = CustomerHelper().AdministationType.ApenasIsoladamente;
    }
    await ElaboratorController.Update(base, res => setData(res));
    return false;
  }

  async function removeAdm(item) {
    if (window.confirm(Message.DeleteConfirm)) {
      await updateBase();
      PartnerController.Delete(item.id, res => {
        toast.success(Message.DeleteSuccess);
        PartnerController.List(data.id, result => setPartners(result));
      })
    }
  }

  function processStep() {
    setProgress(step * division);
  }

  function processAdministration(list) {
    const adms = list.filter(x => x.adm === true);
    let base = data;
    if (adms.length == 1) {
      base.adminitrationType = CustomerHelper().AdministationType.ApenasIsoladamente;
    }
    if (adms.length > 1) {
      base.adminitrationType = CustomerHelper().AdministationType.IsoladamenteEEmConjunto;
    }
    setData(base);
    notifyChange();
  }

  function loadStates() {
    StateList((res) => {
      setStates(res);
    });
  }

  function notifyChange() {
    setChange(date.getTime());
  }

  function loadReminder() { }

  function handleChange(evt, type) {
    if (evt.target.name == "postalCode" && evt.target.value.length >= 8) {
      GetAddress(evt.target.value, (res) => {
        let newData = data;
        newData.complement = "";
        newData.number = "";
        console.log("Estado", { ...newData, ...res }, res);

        setData({ ...newData, ...res });
      });
    } else if (evt.target.name == "taxId" && evt.target.value.length >= 14) {
      GetCompanyByTaxId(evt.target.value, (res) => {
        let base = AdjustCompanyData(res);
        setData({ ...data, ...res, taxId: evt.target.value, ...base });
      }, err => {
        setData({ ...data, taxId: evt.target.value });
      });
    } else {
      setData(OnDataChange(data, evt, type));
    }
  }

  function handleAdmChange(evt, type) {
    if (evt.target.name == "postalCode" && evt.target.value.length >= 8) {
      GetAddress(evt.target.value, (res) => {
        console.log("Estado", { ...data, ...res }, res);
        setPartner({ ...partner, ...res });
      });
    } else {
      setPartner(OnDataChange(partner, evt, type));
    }
  }

  function addPartner(adm) {
    let error = false;
    let msg = "Faltou preenche o ";
    if (!partner.name) {
      error = true;
      msg += "Nome; ";
    }
    if (!partner.nationality) {
      error = true;
      msg += "Nacionalidade; ";
    }
    if (partner.civilState == undefined) {
      error = true;
      msg += "Estado civil; ";
    }
    if (!partner.jobTitle) {
      error = true;
      msg += "Profissão; ";
    }
    if (!partner.doc) {
      error = true;
      msg += "RG; ";
    }
    if (!partner.street) {
      error = true;
      msg += "Logradouro; ";
    }

    if (!partner.neighborhood) {
      error = true;
      msg += "Bairro; ";
    }

    if (!partner.postalCode) {
      error = true;
      msg += "CEP; ";
    }

    if (!partner.city) {
      error = true;
      msg += "Cidade; ";
    }

    if (!partner.state) {
      error = true;
      msg += "Estado; ";
    }


    if (!partner.state) {
      error = true;
      msg += "Estado; ";
    }

    // if (!partner.shares) {
    //   error = true;
    //   msg += "Participação; ";
    // }
    if (!partner.stateId) {
      error = true;
      msg += "CPF; ";
    } else {
      if (!CPFValidator(partner.stateId)) {
        msg = "O CPF é inválido";
        error = true;
      }
    }

    if (!partner.birthDate) {
      error = true;
      msg += "Data de nascimento; ";
    } else {
      const time = YearsAgo(partner.birthDate)
      if (time > 100) {
        error = true;
        msg = "A data de nascimento não pode ser superior a 100 anos atrás";
      }
    }


    if (error) {
      toast.error(msg);
    } else {
      let base = { ...partner, id: partners.length + 1, shares: 0, value: 0 }
      base.elaboratorId = data.id;
      if (data.societyType !== CustomerHelper().SocietyType.SociedadeUnipessoal) {
        base.shares = data.capital
        base.value = data.capital;
      }
      if (adm) {
        base.adm = true;
        base.onlyAdm = true;
        setData({ ...data, newAdm: false })
      } else {
        base.adm = true;
      }
      if (base.id) {
        PartnerController.Insert(base, res => {
          PartnerController.List(data.id, result => setPartners(result));
          // setPartners([...partners, base]);
          console.log("Parceiros", [...partners, base]);
          setPartner({});
          setNewPartner(false);
        })
      }
    }
  }

  function partnerForm(index, adm) {
    return (
      <div className="m-2 mt-3 row">
        <div className="form-group col-md-6">
          <span>Nome completo:</span>
          <input
            className="form-control"
            value={partner && partner.name}
            key="namePartner"
            name="name"
            onChange={(evt) => handleAdmChange(evt)}
          />
        </div>
        <div className="form-group col-md-6">
          <span>Nacionalidade:</span>
          <input
            className="form-control"
            value={partner && partner.nationality}
            key="nationalityPartner"
            name="nationality"
            onChange={(evt) => handleAdmChange(evt)}
          />
        </div>
        <div className="form-group col-md-6">
          <span>Data de Nascimento:</span>
          <input
            className="form-control"
            type="date"
            value={partner && partner.birthDate}
            max={new Date().toJSON().split('T')[0]}
            key="birthDate"
            name="birthDate"
            onChange={(evt) => handleAdmChange(evt)}
          />
        </div>
        <div className="form-group col-md-6">
          <span>
            Estado Civil:
          </span>
          <select
            className="form-control"
            value={partner && partner.civilState}
            key="civilStatePartner"
            name="civilState"
            onChange={(evt) => handleAdmChange(evt, FieldTypeObj.Number)}
          >
            <option value={null}>Selecione</option>
            {CivilState.map((item) => (
              <option value={item.value}>{item.name}</option>
            ))}
          </select>
        </div>
        {partner && partner.civilState == CivilStateObj.Married ? (
          <div className="form-group col-md-6">
            <span>Tipo de comunhão</span>
            <select
              className="form-control"
              value={partner && partner.communion}
              key="communionPartner"
              name="communion"
              onChange={(evt) => handleAdmChange(evt, FieldTypeObj.Number)}
            >
              <option value={null}>Selecione</option>
              {Communion.map((item) => (
                <option value={item.value}>{item.name}</option>
              ))}
            </select>
          </div>
        ) : (
          ""
        )}
        <div className="form-group col-md-6">
          <span>Profissão:</span>
          <input
            className="form-control"
            value={partner && partner.jobTitle}
            key="jobTitlePartner"
            name="jobTitle"
            onChange={(evt) => handleAdmChange(evt)}
          />
        </div>
        <div className="form-group col-md-6">
          <span>RG ou RNE:</span>
          <input
            className="form-control"
            value={partner && partner.doc}
            key="docPartner"
            name="doc"
            onChange={(evt) => handleAdmChange(evt)}
          />
        </div>
        <div className="form-group col-md-6">
          <span>CPF:</span>
          <InputMask
            mask="999.999.999-99"
            maskChar=""
            type="text"
            className="form-control"
            id="advanced-filter-document"
            name="cpf"
            placeholder="999.999.999-99"
            value={partner && partner.stateId}
            onChange={(event) => {
              handleAdmChange({
                target: {
                  name: "stateId",
                  value: event.target.value.replace(/\D/g, ""),
                },
              });
            }}
          />
        </div>

        <div className="form-group col-md-6">
          <span>Logradouro:</span>
          <input
            className="form-control"
            value={partner && partner.street}
            key="streetPartner"
            name="street"
            onChange={(evt) => handleAdmChange(evt)}
          />
        </div>

        <div className="form-group col-md-6">
          <div className="row">
            <div className="col-6">
              <label for="address">Número</label>
            </div>
            <div className="col-6 text-right">
              <button className="btn btn-sm btn-dual" onClick={() => setPartner({
                ...partner,
                noNumber: partner.noNumber == false || partner.noNumber == undefined ? true : false,
                number: partner.noNumber == false || partner.noNumber == undefined ? "S/N" : ""
              })}>
                {partner && partner.noNumber ?
                  <i className="si si-close mr-1" />
                  :
                  <i className="si si-check mr-1" />
                }
                Sem número</button>
            </div>
          </div>
          <input
            placeholder="Número"
            className="form-control"
            type="text"
            disabled={partner && partner.noNumber}
            name="number"
            value={partner && partner.number}
            onChange={(evt) => handleAdmChange(evt)}
          />
        </div>

        <div className="form-group col-md-6">
          <span>Bairro:</span>
          <input
            className="form-control"
            value={partner && partner.neighborhood}
            key="neighborhoodPartner"
            name="neighborhood"
            onChange={(evt) => handleAdmChange(evt)}
          />
        </div>

        <div className="form-group col-md-6">
          <span>Complemento:</span>
          <input
            className="form-control"
            value={partner && partner.complement}
            key="complementPartner"
            name="complement"
            onChange={(evt) => handleAdmChange(evt)}
          />
        </div>

        <div className="form-group col-md-6">
          <span>CEP:</span>
          <input
            className="form-control"
            value={partner && partner.postalCode}
            key="postalCodePartner"
            name="postalCode"
            onChange={(evt) => handleAdmChange(evt)}
          />
        </div>

        <div className="form-group col-md-6">
          <span>Município:</span>
          <input
            className="form-control"
            value={partner && partner.city}
            key="cityPartner"
            name="city"
            onChange={(evt) => handleAdmChange(evt)}
          />
        </div>

        <div className="form-group col-md-6">
          <span>Estado:</span>
          <select
            className="form-control"
            value={partner && partner.state}
            key="statePartner"
            name="state"
            onChange={(evt) => handleAdmChange(evt)}
          >
            <option value={null}>Selecione</option>
            {states &&
              states.map((item) => (
                <option selected={partner && partner.state === item.longName ? true : false} value={item.longName}>{item.longName}</option>
              ))}
          </select>
        </div>

        {/* <div className="form-group col-md-6">
          <span>Porcentagem do capital</span>
          <input
            className="form-control"
            type="number"
            value={partner && partner.shares}
            key="sharesPartner"
            name="shares"
            onChange={(evt) => handleAdmChange(evt, FieldTypeObj.Number)}
          />
        </div> */}
        {/* Se as cotas não corresponderem a 100% da empresa, devemos evitar a pessoa de avançar */}

        {index != undefined ? (
          ""
        ) : (
          <div className="text-center col-md-12">
            <button
              className="btn btn-warning btn-square mt-4 mb-4"
              onClick={() => addPartner(adm)}
            >
              Salvar {adm ? "Administrador" : "sócio"}
            </button>
          </div>
        )}

        {/* Depois de salvar apresentar como conteiner e colocar o botão Adicionar Sócio */}

        {loading === true ? (
          <div className="text-center">
            <i className="fa fa-2x fa-circle-notch fa-spin text-info" />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }

  function editItem(index) {
    let old = partners;
    old[index].edit =
      old[index].edit == undefined || old[index].edit == false ? true : false;
    setPartners(old);
    setPartner(old[index]);
    console.log("Sócio editado", old[index])
    notifyChange();
  }

  function saveItem(index) {
    let error = false;
    console.log("Partiner editando", partner);
    let msg = "Faltou preenche o ";
    if (!partner.name) {
      error = true;
      msg += "Nome; ";
    }
    if (!partner.nationality) {
      error = true;
      msg += "Nacionalidade; ";
    }
    if (partner.civilState == undefined) {
      error = true;
      msg += "Estado civil; ";
    }
    if (!partner.jobTitle) {
      error = true;
      msg += "Profissão; ";
    }
    if (!partner.doc) {
      error = true;
      msg += "RG; ";
    }
    if (!partner.stateId) {
      error = true;
      msg += "CPF; ";
    } else {
      if (!CPFValidator(partner.stateId)) {
        msg = "O CPF é inválido";
        error = true;
      }
    }

    if (error) {
      toast.error(msg);
    } else {
      if (partner.id) {
        PartnerController.Update(partner, res => {
          PartnerController.List(data.id, result => setPartners(result));
          // let old = partners;
          // old[index] = partner;
          // old[index].edit = false;
          // setPartners(old);
          // console.log("Parceiros", old);
          setPartner({});
          notifyChange();
        })
      }
    }
  }

  function removeItem(item) {
    if (window.confirm(Message.DeleteConfirm)) {
      PartnerController.Delete(item.id, res => {
        toast.success(Message.DeleteSuccess);
        PartnerController.List(data.id, result => {
          setPartners(result)
          notifyChange();
        });
      })
    }
  }


  function checkAdministrator(evt, index) {
    let old = partners;
    old[index].adm = evt.target.checked;
    setPartners(old);
    processAdministration(old)
    notifyChange();
  }

  function checkProlabore(evt, index) {
    let old = partners;
    old[index].prolabore = evt.target.checked;
    setPartners(old);
    notifyChange();
  }

  function addressFields() {
    return (
      <div className="row">
        <div className="col-sm-6">
          <div className="form-group">
            <label for="address">Logradouro</label>
            <input
              placeholder="Logradouro"
              className="form-control"
              type="text"
              name="street"
              value={data && data.street}
              onChange={(evt) => handleChange(evt)}
            />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <div className="row">
              <div className="col-6">
                <label for="address">Número</label>
              </div>
              <div className="col-6 text-right">
                <button className="btn btn-sm btn-dual" onClick={() => setData({
                  ...data,
                  noNumber: data.noNumber == false || data.noNumber == undefined ? true : false,
                  number: data.noNumber == false || data.noNumber == undefined ? "S/N" : ""
                })}>
                  {data && data.noNumber ?
                    <i className="si si-close mr-1" />
                    :
                    <i className="si si-check mr-1" />
                  }
                  Sem número</button>
              </div>
            </div>
            <input
              placeholder="Número"
              className="form-control"
              type="text"
              disabled={data && data.noNumber}
              name="number"
              value={data && data.number}
              onChange={(evt) => handleChange(evt)}
            />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <label for="address">Bairro</label>
            <input
              placeholder="Bairro"
              className="form-control"
              type="text"
              name="neighborhood"
              value={data && data.neighborhood}
              onChange={(evt) => handleChange(evt)}
            />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <label for="address">Complemento</label>
            <input
              placeholder="Complemento"
              className="form-control"
              type="text"
              name="complement"
              value={data && data.complement}
              onChange={(evt) => handleChange(evt)}
            />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <label for="city">Cidade</label>
            <input
              placeholder="Cidade"
              className="form-control"
              type="text"
              name="city"
              value={data && data.city}
              onChange={(evt) => handleChange(evt)}
            />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <label for="state">Estado</label>
            <select
              name="state"
              onChange={(evt) => handleChange(evt)}
              value={data && data.state}
              className="form-control"
            >
              <option value={null}>Selecionar</option>
              {states &&
                states.map((state) => (
                  <option
                    value={state.longName}
                    selected={data && data.state === state.longName ? true : false}
                  >
                    {state.longName} ({state.shortName})
                  </option>
                ))}
            </select>
          </div>
        </div>
      </div>
    )
  }

  function goSecond() {
    if (data.societyType == undefined) {
      toast.error(Message.PendingData);
    } else {
      setStep(step + 1);
      updateBase()
    }
  }

  function goThird() {
    if (!data.name) {
      toast.error(Message.PendingData);
    } else {
      setStep(step + 1);
      updateBase();
    }
  }

  function goToFourth() {
    if (partners.length == 0) {
      toast.error(Message.PendingData);
    } else if (data.societyType === CustomerHelper().SocietyType.SociedadeLimitada && partners.filter(x => !x.onlyAdm).length === 1) {
      toast.error(Message.PendingPartner);
    } else if (data.societyType === CustomerHelper().SocietyType.SociedadeUnipessoal && partners.filter(x => !x.onlyAdm).length > 1) {
      toast.error(Message.MoreThanOnePartner);
    } else {
      setStep(step + 1);
      updateBase();
    }
  }

  function handleShares(evt, index) {
    let old = partners;
    old[index].shares = parseInt(evt.target.value);

    old[index].value = (old[index].shares / 100) * data.capital;
    console.log("valores", old[index].value, data.capital, old[index].shares)

    setPartners(old);
    notifyChange();
  }

  function goFifth() {
    if (data.street == undefined) {
      toast.error(`Faltou preencher a rua`);
    } else if (data.number == undefined) {
      toast.error(`Faltou preencher o número`);
    } else if (data.neighborhood == undefined) {
      toast.error(`Faltou preencher o bairro`);
    } else if (data.city == undefined) {
      toast.error(`Faltou preencher a cidade`);
    } else if (data.state == undefined) {
      toast.error(`Faltou preencher o estado`);
    } else {
      updateBase();
      setStep(step + 1);
    }
  }

  function goSix() {
    if (data.description == undefined) {
      toast.error(`Preencha a descrição para continuar`);
    } else {
      updateBase();
      setStep(step + 1);
    }
  }

  function goSeven() {
    if (data.capital == undefined || data.capital == 0) {
      toast.error(`Preencha o capital social para continuar`);
    } else if (data.capital < 100) {
      toast.error(`O capital social deve ser maior que R$100,00`);
    } else {
      if (data.societyType === CustomerHelper().SocietyType.SociedadeUnipessoal) {
        setStep(step + 2);
      } else {
        setStep(step + 1);
      }
      updateBase();
    }
  }

  function goEight() {
    let shares = 0;

    for (let s of partners) {
      console.log(s, shares, partners)
      if (s && s.onlyAdm === false) {
        shares += s.shares;
      }
    }
    console.log("parceiros", partners, shares)
    if (shares < 100) {
      toast.error(
        "A quantidade de quotas está abaixo de 100% do capital social",
      );
    } else if (shares > 100) {
      console.log("Shares", shares, partners)
      toast.error(
        "A quantidade de quotas está acima de 100% do capital social",
      );
    } else {
      updateBase();
      setStep(step + 1);
    }
  }

  function goNine() {
    let shares = 0;

    for (let s of partners) {
      console.log(s, shares, partners)
      if (s && s.adm) {
        shares++;
      }
    }
    console.log("parceiros", partners, shares)
    if (shares == 0) {
      toast.error(
        "Ao menos 1 dos sócios precisa ser administrador",
      );
    } else {
      updateBase();
      setStep(step + 1);
    }
  }

  function goFinish() {
    updateBase(true);
    setStep(step + 1);
  }

  function goBackToShares() {
    if (data.societyType === CustomerHelper().SocietyType.SociedadeUnipessoal) {
      setStep(step - 2);
    } else {
      setStep(step - 1);
    }
  }

  function goBack() {
    if (step > 1) {
      setStep(step - 1)
      setProgress(progress - 10);
    }
  }

  function selectDistractResponsible(item) {
    let users = partners;
    for (let s of users) {
      if (s.stateId == item.stateId) {
        s.responsible = true;
      } else {
        s.responsible = false;
      }
    }

    console.log("Mudança de conta", users)
    setPartners(users);
  }

  function thePartner() {
    return (
      <div>
        {change && partners && partners.length == 0 ? (
          partnerForm()
        ) : (
          <p></p>
        )}

        {change &&
          partners &&
          partners.map((item, index) => {
            if (item && !item.onlyAdm) {
              return (
                <div>
                  <div
                    className="block block-bordered border-2x border-warning"
                    key={`partiner-${index}`}
                  >
                    <div className="block-header">
                      <div className="block-title">Sócio: {item && item.name}</div>
                      <div className="block-tools">
                        {change && item && item.edit != true ? (
                          <button
                            className="btn btn-dual"
                            title="Editar Sócio"
                            onClick={() => editItem(index)}
                          >
                            <i className="far fa-edit" />
                          </button>
                        ) : (
                          <button
                            className="btn btn-success"
                            title="Salvar Sócio"
                            onClick={() => saveItem(index)}
                          >
                            <i className="si si-success" /> Salvar
                          </button>
                        )}
                        <button
                          className="btn btn-dual"
                          onClick={() => removeItem(item)}
                        >
                          <i className="si si-close text-danger" />
                        </button>
                      </div>
                    </div>
                    {item && item.edit != true ? (
                      <div className="block-content row">
                        <div className="col-md-6">
                          <b className="font-w700">Nome completo</b>
                          <br />
                          <p>{item.name}</p>
                        </div>
                        <div className="col-md-6">
                          <b className="font-w700">Nacionalidade</b>
                          <br />
                          <p>{item.nationality}</p>
                        </div>
                        <div className="col-md-6">
                          <b className="font-w700">Estado civil</b>
                          <br />
                          <p>{CivilStateTranslate(item.civilState)}</p>
                        </div>
                        {item.civilState == CivilStateObj.Married ? (
                          <div className="col-md-6">
                            <b className="font-w700">Tipo de comunhão</b>
                            <br />
                            <p>{CommunionTranslate(item.communion)}</p>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="col-md-6">
                          <b className="font-w700">Profissão</b>
                          <br />
                          <p>{item.jobTitle}</p>
                        </div>
                        <div className="col-md-6">
                          <b className="font-w700">RG</b>
                          <br />
                          <p>{item.doc}</p>
                        </div>
                        <div className="col-md-6">
                          <b className="font-w700">CPF</b>
                          <br />
                          <p>{CpfMask(item.stateId)}</p>
                        </div>
                        <div className="col-md-6">
                          <b className="font-w700">Logradouro</b>
                          <br />
                          <p>{item.street}</p>
                        </div>
                        <div className="col-md-6">
                          <b className="font-w700">Numero</b>
                          <br />
                          <p>{item.number ? item.number : "S/N"}</p>
                        </div>
                        <div className="col-md-6">
                          <b className="font-w700">Bairro</b>
                          <br />
                          <p>{item.neighborhood}</p>
                        </div>
                        <div className="col-md-6">
                          <b className="font-w700">Complemento</b>
                          <br />
                          <p>{item.complement}</p>
                        </div>
                        <div className="col-md-6">
                          <b className="font-w700">CEP</b>
                          <br />
                          <p>{item.postalCode}</p>
                        </div>
                        <div className="col-md-6">
                          <b className="font-w700">Cidade</b>
                          <br />
                          <p>{item.city}</p>
                        </div>
                        <div className="col-md-6">
                          <b className="font-w700">Estado</b>
                          <br />
                          <p>{item.state}</p>
                        </div>
                        {/* <div className="col-md-6">
                          <b className="font-w700">Porcentagem do capital</b>
                          <br />
                          <p>{item.shares}</p>
                        </div> */}
                      </div>
                    ) : (
                      partnerForm(index)
                    )}
                  </div>
                  <hr />
                </div>
              )
            }
          })}
        {newPartner == false && partners.length > 0 && data && data.societyType !== CustomerHelper().SocietyType.SociedadeUnipessoal ? (
          <div className="text-center">
            <button
              className="btn btn-warning btn-square mt-4 mb-4"
              key={"newPartnet"}
              onClick={() => setNewPartner(!newPartner)}
            >
              Adicionar Sócio
            </button>
          </div>
        ) : newPartner == true && partners.length > 0 ? (
          partnerForm()
        ) : (
          ""
        )}
      </div>
    )
  }

  function partnerQuotes(item, index) {
    return (
      <div className="block block-rounded block-bordered">
        <div className="block-header">
          <div className="block-title">
            <div class="ml-3 text-left">
              <p class="font-w600 mb-0">{item && item.name}</p>
              <p class="font-size-sm font-italic text-muted mb-0">
                {item && item.stateId && CpfMask(item.stateId)}
              </p>
            </div>
          </div>
          <div className="block-tools">
            <div className="form-group">
              <span>Digite a %</span>
              <input type="number" className="form-control" min={1} max={100} value={item && item.shares} onChange={evt => handleShares(evt, index)} />
            </div>
            <span>
              {item && item.value ? MoneyMask(item.value, 2) : "00,00"}
            </span>
          </div>
        </div>
      </div>
    )
  }

  function capitalFields() {
    return (
      <div>
        <div className="form-group">
          <span> Digite o valor em R$</span>
          <CurrencyFormat
            className={`form-control`}
            thousandSeparator={"."}
            decimalSeparator={","}
            name="marketValue"
            prefix={"R$"}
            value={data && data.capital}
            onValueChange={(values) => {
              const { formattedValue, value } = values;
              handleChange(
                { target: { name: "capital", value: value } },
                FieldTypeObj.Currency,
              );
            }}
          />
        </div>
        <div className="form-group">
          <span>Para confirmar escreva o valor acima por extenso</span>
          <input className="form-control" name="capitalExtended" placeholder="Exemplo: dez mil" value={data && data.capitalExtended} onChange={evt => handleChange(evt)} />
        </div>
      </div>
    )
  }

  function administratorForm(item, index) {
    return (
      <div className="block block-rounded block-bordered">
        <div className="block-header">
          <div className="block-title d-flex">
            {item && item.onlyAdm ?
              <button className="btn btn-dual text-danger" title="Remover administrador" onClick={() => removeAdm(item)}>
                <i className="si si-close" />
              </button>
              :
              ""
            }
            <div class="ml-3 text-left">
              <p class="font-w600 mb-0">{item && item.name}{item && item.onlyAdm ? <small> - Administrador não sócio</small>
                : ""
              }
              </p>
              <p class="font-size-sm font-italic text-muted mb-0">
                {item && item.stateId && CpfMask(item.stateId)}
              </p>
            </div>
          </div>
          <div className="block-tools">
            <div className="custom-control custom-checkbox custom-control-warning mb-1">
              <input
                type="checkbox"
                className="custom-control-input"
                checked={item && item.adm}
                id={`adm-${index}`}
                onChange={(evt) => checkAdministrator(evt, index)}
              />
              <label className="custom-control-label" for={`adm-${index}`}>
                Selecionar
              </label>
            </div>
          </div>
        </div>
      </div>
    )
  }

  function administrationOfCompany() {
    return (
      <div className="form-group pb-3">
        <br />
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" class="custom-control-input" id="adminitration1" name="example-rd-custom-inline"
            checked={data && data.adminitrationType == CustomerHelper().AdministationType.IsoladamenteEEmConjunto ? true : false}
            disabled={partners && partners.length === 1 ? true : false}
            onChange={evt => handleChange({
              target: {
                name: "adminitrationType",
                value: evt.target.checked == true ? CustomerHelper().AdministationType.IsoladamenteEEmConjunto : 0
              }
            })} />
          <label class="custom-control-label" for="adminitration1">Isoladamente e em conjunto</label>
        </div>
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" class="custom-control-input" id="adminitration2" name="example-rd-custom-inline"
            checked={data && data.adminitrationType == CustomerHelper().AdministationType.ApenasIsoladamente ? true : false}
            onChange={evt => handleChange({
              target: {
                name: "adminitrationType",
                value: evt.target.checked == true ? CustomerHelper().AdministationType.ApenasIsoladamente : 0
              }
            })} />
          <label class="custom-control-label" for="adminitration2">Apenas isoladamente</label>
        </div>
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" class="custom-control-input" id="adminitration3" name="example-rd-custom-inline"
            checked={data && data.adminitrationType == CustomerHelper().AdministationType.ApenasEmConjunto ? true : false}
            disabled={partners && partners.length === 1 ? true : false}
            onChange={evt => handleChange({
              target: {
                name: "adminitrationType",
                value: evt.target.checked == true ? CustomerHelper().AdministationType.ApenasEmConjunto : 0
              }
            })} />
          <label class="custom-control-label" for="adminitration3">Apenas em conjunto</label>
        </div>
      </div>
    )
  }

  function changeOpenStep() {
    switch (step) {
      case ElaboratorHelper.OpenSteps.CompanyType:
        return (
          <div className="block-content">
            <div className="ml-5 mr-5 mt-3 mb-5">
              <div className="form-group">
                <h4 className="text-center">Vamos começar do começo... Qual tipo de empresa deseja abrir?</h4><br />
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" class="custom-control-input"
                    id="companyType1"
                    name="example-rd-custom-inline"
                    checked={data && data.societyType === CustomerHelper().SocietyType.SociedadeLimitada ? true : false} onChange={evt => handleChange({
                      target: {
                        name: "societyType",
                        value: evt.target.checked == true ? CustomerHelper().SocietyType.SociedadeLimitada : 0
                      }
                    })} />
                  <label class="custom-control-label" for="companyType1">Sociedade Limitada (LTDA)</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" class="custom-control-input"
                    id="companyType2"
                    name="example-rd-custom-inline"
                    checked={data && data.societyType === CustomerHelper().SocietyType.SociedadeUnipessoal ? true : false} onChange={evt => handleChange({
                      target: {
                        name: "societyType",
                        value: evt.target.checked == true ? CustomerHelper().SocietyType.SociedadeUnipessoal : 0
                      }
                    })} />
                  <label class="custom-control-label" for="companyType2">Sociedade Unipessoal (SLU)</label>
                </div>
              </div>
            </div>
            <button className="mb-3 btn btn-block btn-warning" onClick={() => goSecond()}>Próximo</button>
          </div>
        )
      case ElaboratorHelper.OpenSteps.Name:
        return (
          <div className=" block-content">
            <div className="ml-5 mr-5 mt-3 mb-5">
              <div className="form-group">
                <h4 className="text-center">Legal... Agora digite o nome completo dessa empresa! (Não é necessário digitar LTDA)</h4>
                <input className="form-control" name="name" value={data && data.name} onChange={evt => handleChange(evt)} />
              </div>

              <div className="form-group pb-3">
                <span>Essa empresa terá nome fantasia?</span>
                <br />
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" class="custom-control-input" id="fantasyName1" checked={data && data.hasFantasyName === true ? true : false} name="example-rd-custom-inline" onChange={evt => handleChange({
                    target: {
                      name: "hasFantasyName",
                      value: evt.target.checked == true ? true : false
                    }
                  })} />
                  <label class="custom-control-label" for="fantasyName1">Sim</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" class="custom-control-input" id="fantasyName2" checked={data && data.hasFantasyName === true ? false : true} name="example-rd-custom-inline" onChange={evt => handleChange({
                    target: {
                      name: "hasFantasyName",
                      value: evt.target.checked == true ? false : true
                    }
                  })} />
                  <label class="custom-control-label" for="fantasyName2">Não</label>
                </div>
              </div>

              {data && data.hasFantasyName == true ?
                <div className="form-group">
                  <span className="text-center">Digite abaixo o nome fantasia desejado</span>
                  <input className="form-control" name="fantasyName" value={data && data.fantasyName} onChange={evt => handleChange(evt)} />
                </div>
                : ""}

              <button className="btn btn-block btn-warning" onClick={() => goThird()}>Próximo</button>
              <button className="btn btn-block btn-dual mb-5" onClick={() => goBack()}>Voltar</button>
            </div>
          </div>
        )
      case ElaboratorHelper.OpenSteps.Partners:
        return (
          <div className="block-content">
            <h4 className="text-center">Ok, agora me informe quem {data && data.societyType === CustomerHelper().SocietyType.SociedadeUnipessoal ? "será o sócio" : "serão os sócios"}:</h4>


            {thePartner()}

            <div className="text-right">
              <button
                className="btn btn-outline-warning btn-square mt-4 mb-4"
                onClick={() => goBack()}
              >
                Voltar
              </button>
              <button
                className="btn btn-warning btn-square mt-4 mb-4"
                onClick={() => goToFourth()}
              >
                Próximo
              </button>
            </div>
          </div>
        );

      case ElaboratorHelper.OpenSteps.Address:
        return (
          <div className=" block-content">
            <div className="ml-5 mr-5 mt-3 mb-5">
              <h4 className="text-center">Bacana, me confirme o endereço onde a empresa irá se instalar?</h4>
              <div className="form-group">
                <span>CEP</span>
                <input className="form-control" name="postalCode" placeholder="CEP" value={data && data.postalCode} onChange={evt => handleChange(evt)} />
              </div>
              {data && data.postalCode && data.postalCode.length >= 8 ?
                addressFields()
                : ""}

              <button className="btn btn-block btn-warning" onClick={() => goFifth()}>Próximo</button>
              <button className="btn btn-block btn-dual mb-5" onClick={() => goBack()}>Voltar</button>
            </div>
          </div>
        );

      case ElaboratorHelper.OpenSteps.Subject:
        return (
          <div className=" block-content">
            <div className="ml-5 mr-5 mt-3 mb-5">
              <h4 className="text-center">Entendi, vamos falar sobre a atividade desta empresa agora.</h4>

              <div className="form-group">
                <span>Me informe (detalhadamente) qual será o objeto social?</span>
                <textarea className="form-control" name="description" placeholder="" value={data && data.description} onChange={evt => handleChange(evt)} />
              </div>


              <button className="btn btn-block btn-warning" onClick={() => goSix()}>Próximo</button>
              <button className="btn btn-block btn-dual mb-5" onClick={() => goBack()}>Voltar</button>
            </div>
          </div>
        );

      case ElaboratorHelper.OpenSteps.Capital:
        return (
          <div className=" block-content">
            <div className="ml-5 mr-5 mt-3 mb-5">
              <h4 className="text-center">Show! Então vamos falar de capital social, quanto será investido em capital?</h4>

              {data && capitalFields()}

              <div className="form-group pb-3">
                <span>Qual será o porte da empresa?</span>
                <br />
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" class="custom-control-input" id="taxCode1" name="example-rd-custom-inline" onChange={evt => handleChange({
                    target: {
                      name: "taxCode",
                      value: evt.target.checked === true ? CustomerHelper().TaxCode.ME : 0
                    }
                  })} checked={data && data.taxCode === CustomerHelper().TaxCode.ME ? true : false} />
                  <label class="custom-control-label" for="taxCode1">ME</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" class="custom-control-input" id="taxCode2" name="example-rd-custom-inline" onChange={evt => handleChange({
                    target: {
                      name: "taxCode",
                      value: evt.target.checked === true ? CustomerHelper().TaxCode.EPP : 0
                    }
                  })} checked={data && data.taxCode === CustomerHelper().TaxCode.EPP ? true : false} />
                  <label class="custom-control-label" for="taxCode2">EPP</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" class="custom-control-input" id="taxCode3" name="example-rd-custom-inline" onChange={evt => handleChange({
                    target: {
                      name: "taxCode",
                      value: evt.target.checked === true ? CustomerHelper().TaxCode.Normal : 0
                    }
                  })} checked={data && data.taxCode === CustomerHelper().TaxCode.Normal ? true : false} />
                  <label class="custom-control-label" for="taxCode3">Normal</label>
                </div>
              </div>

              <button className="btn btn-block btn-warning" onClick={() => goSeven()}>Próximo</button>
              <button className="btn btn-block btn-dual mb-5" onClick={() => goBack()}>Voltar</button>
            </div>
          </div>
        );

      case ElaboratorHelper.OpenSteps.Shares:
        return (
          <div className=" block-content">
            <div className="ml-5 mr-5 mt-3 mb-5">
              <h4 className="text-center m-0">Ok, e como ficará a distribuição entre os sócios?</h4>
              <p className="text-center ">Capital social registrado: {data && data.capital && MoneyMask(data.capital, 2)}</p>

              {change && partners && partners.map((item, index) => {
                if (!item.onlyAdm) {
                  return partnerQuotes(item, index)
                }
              })}


              <button className="btn btn-block btn-warning" onClick={() => goEight()}>Próximo</button>
              <button className="btn btn-block btn-dual mb-5" onClick={() => goBack()}>Voltar</button>
            </div>
          </div>
        );

      case ElaboratorHelper.OpenSteps.Administrators:
        return (
          <div className=" block-content">
            <div className="ml-5 mr-5 mt-3 mb-5">
              <h4 className="text-center">Falta pouco! Me diga, quem será o administrador da empresa?</h4>

              {change && partners && partners.map((item, index) => administratorForm(item, index))}

              {data && data.newAdm ?
                <div className="border border-rounded border-2x border-warning">
                  {partnerForm(undefined, true)}
                </div>
                : ""}

              <div className="text-center py-3 mb-2">
                <button className="btn btn-success" onClick={() => setData({ ...data, newAdm: data.newAdm == false || data.newAdm == undefined ? true : false })}>{data.newAdm == false || data.newAdm == undefined ? "Adicionar administrador não sócio" : "Remover formulário"}</button>
              </div>

              <span>Como será feita o uso da administração?</span>
              {change && administrationOfCompany()}

              <button className="btn btn-block btn-warning" onClick={() => goNine()}>Próximo</button>
              <button className="btn btn-block btn-dual mb-5" onClick={() => goBackToShares()}>Voltar</button>
            </div>
          </div>
        );

      case ElaboratorHelper.OpenSteps.Prolabore:
        return (
          <div className=" block-content">
            <div className="ml-5 mr-5 mt-3 mb-5">
              <h4 className="text-center">{data.societyType !== CustomerHelper().SocietyType.SociedadeUnipessoal ? "Algum deles" : "O sócio"} terá retirada de pró-labore?</h4>

              {change && partners && partners.map((item, index) => {
                if (!item.onlyAdm) {
                  return (
                    <div className="block block-rounded block-bordered">
                      <div className="block-header">
                        <div className="block-title">
                          <div class="ml-3 text-left">
                            <p class="font-w600 mb-0">{item && item.name}</p>
                            <p class="font-size-sm font-italic text-muted mb-0">
                              {item && item.stateId && CpfMask(item.stateId)}
                            </p>
                          </div>
                        </div>
                        <div className="block-tools">
                          <div className="custom-control custom-checkbox custom-control-warning mb-1">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              checked={item.prolabore}
                              id={index}
                              onChange={(evt) => checkProlabore(evt, index)}
                            />
                            <label className="custom-control-label" for={index}>
                              Selecionar
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }
              })}


              <button className="btn btn-block btn-warning" onClick={() => goFinish()}>Próximo</button>
              <button className="btn btn-block btn-dual mb-5" onClick={() => goBack()}>Voltar</button>
            </div>
          </div>
        );


      default:
        return (
          <div className="content">
            <div className="block block-fx-pop block-bordered border-success mt-5">
              <div className="block-header">
                <div className="block-content text-center">
                  <i className="far fa-check-circle text-success mr-2" />{" "}
                  Pronto, seu contrato já está elaborado!
                  <br />
                  <button className="btn btn-square btn-info mt-3" onClick={() => window.location = "#/elaborator"}>
                    Clique aqui para baixar o arquivo em Word
                  </button>
                </div>
              </div>
            </div>
            <ModeloConstituicao data={data} partners={partners} />
            {/* {data && data.societyType == CustomerHelper().SocietyType.SociedadeLimitada ?
              : data && data.societyType == CustomerHelper().SocietyType.SociedadeUnipessoal ?
                <ModeloSLU data={{ ...data, partners }} /> : ""
            } */}

            <button className="btn btn-block btn-warning" onClick={() => goNine()}>Próximo</button>
            <button className="btn btn-block btn-dual mb-5" onClick={() => goBack()}>Voltar</button>
          </div>
        );
    }
  }


  /* Cancelamento */

  function changeCancelation() {
    switch (step) {
      case 1:
        return (
          <div className="block-content">
            <div className="ml-5 mr-5 mt-3 mb-5">
              <div className="form-group">
                <h4 className="text-center">Vamos começar do começo... Qual tipo de empresa deseja cancelar?</h4><br />
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" class="custom-control-input" id="companyType1" name="example-rd-custom-inline" onChange={evt => handleChange({
                    target: {
                      name: "societyType",
                      value: evt.target.checked == true ? CustomerHelper().SocietyType.SociedadeLimitada : 0
                    }
                  })} />
                  <label class="custom-control-label" for="companyType1">Sociedade Limitada (LTDA)</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" class="custom-control-input" id="companyType2" name="example-rd-custom-inline" onChange={evt => handleChange({
                    target: {
                      name: "societyType",
                      value: evt.target.checked == true ? CustomerHelper().SocietyType.SociedadeUnipessoal : 0
                    }
                  })} />
                  <label class="custom-control-label" for="companyType2">Sociedade Unipessoal (SLU)</label>
                </div>

              </div>
            </div>
            <button className="mb-3 btn btn-block btn-warning" onClick={() => goSecond()}>Próximo</button>
          </div>
        )
      case 2:
        return (
          <div className="block-content">
            <div className="ml-5 mr-5 mt-3 mb-5">
              <div className="form-group">
                <h4 className="text-center">Entendi, então vamos iniciar o processo de baixa/distrato. Qual o CNPJ da empresa?</h4><br />
                <InputMask
                  mask="99.999.999/9999-99"
                  maskChar=""
                  type="text"
                  className="form-control"
                  id="advanced-filter-document"
                  name="taxId"
                  placeholder="99.999.999/9999-99"
                  value={data && data.taxId}
                  onChange={(event) => {
                    handleChange({
                      target: {
                        name: "taxId",
                        value: event.target.value.replace(/\D/g, ""),
                      },
                    });
                  }}
                />
              </div>

              <div className="form-group">
                <span>Informe o Nire abaixo</span>
                <input className="form-control" name="nire" value={data && data.nire} onChange={evt => handleChange(evt)} />
              </div>

              <div className="form-group">
                <span>Data de abertura</span>
                <input className="form-control" name="openDate" type="date" value={data && data.openDate} onChange={evt => handleChange(evt)} max={new Date().toJSON().split('T')[0]} />
              </div>

              <div className="form-group">
                <span>Nome completo dessa empresa</span>
                <input className="form-control" name="companyName" value={data && data.companyName} onChange={evt => handleChange(evt)} />
              </div>

              <div className="form-group">
                <span>Qual o capital social registrado?</span>
                <CurrencyFormat
                  className={`form-control`}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  name="marketValue"
                  prefix={"R$"}
                  value={data && data.capital}
                  onValueChange={(values) => {
                    const { formattedValue, value } = values;
                    handleChange(
                      { target: { name: "capital", value: value } },
                      FieldTypeObj.Currency,
                    );
                  }}
                />
              </div>
              <div className="form-group">
                <span>Para confirmar escreva o valor acima por extenso</span>
                <input className="form-control" name="capitalExtended" placeholder="Exemplo: dez mil" value={data && data.capitalExtended} onChange={evt => handleChange(evt)} />
              </div>
            </div>

            <div className="text-right">
              <button
                className="btn btn-outline-warning btn-square mt-4 mb-4"
                onClick={() => goBack()}
              >
                Voltar
              </button>
              <button
                className="btn btn-warning btn-square mt-4 mb-4"
                onClick={() => setStep(step + 1)}
              >
                Próximo
              </button>
            </div>
          </div>
        )

      case 3:
        return (
          <div className=" block-content">
            <div className="ml-5 mr-5 mt-3 mb-5">
              <h4 className="text-center">Bacana, me confirme o endereço onde a empresa da empresa?</h4>
              <div className="form-group">
                <span>CEP</span>
                <input className="form-control" name="postalCode" placeholder="CEP" value={data && data.postalCode} onChange={evt => handleChange(evt)} />
              </div>
              {data && data.postalCode && data.postalCode.length >= 8 ?
                addressFields()
                : ""}
              <div className="text-right">
                <button
                  className="btn btn-outline-warning btn-square mt-4 mb-4"
                  onClick={() => goBack()}
                >
                  Voltar
                </button>
                <button
                  className="btn btn-warning btn-square mt-4 mb-4"
                  onClick={() => setStep(step + 1)}
                >
                  Próximo
                </button>
              </div>
            </div>
          </div>
        )

      case 4:
        return (
          <div className="block-content">
            <div className="ml-5 mr-5 mt-3 mb-5">
              <div className="form-group">
                <h4 className="text-center mb-0">Okay, possui o último contrato em word?</h4><br />
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" class="custom-control-input" id="hasFile1" name="example-rd-custom-inline" onChange={evt => handleChange({
                    target: {
                      name: "hasFile",
                      value: evt.target.checked == true ? true : false
                    }
                  })} />
                  <label class="custom-control-label" for="hasFile1">Sim</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" class="custom-control-input" id="hasFile2" name="example-rd-custom-inline" onChange={evt => handleChange({
                    target: {
                      name: "hasFile",
                      value: evt.target.checked == true ? false : true
                    }
                  })} />
                  <label class="custom-control-label" for="hasFile2">Não</label>
                </div>

                {data && data.hasFile === true ? (
                  <div
                    class="dropzone dz-clickable mt-3 cursor mb-2 text-center"
                    onClick={() => onUploadClick()}
                  >
                    <div class="dz-default dz-message">
                      <i className="si si-cloud-upload fa-2x" />
                      <br />
                      <span>Clique aqui para fazer upload</span>
                      <br />
                      <small>O arquivo precisa ser um PDF ou Word</small>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="text-right">
              <button
                className="btn btn-outline-warning btn-square mt-4 mb-4"
                onClick={() => goBack()}
              >
                Voltar
              </button>
              <button
                className="btn btn-warning btn-square mt-4 mb-4"
                onClick={() => setStep(step + 1)}
              >
                Próximo
              </button>
            </div>
          </div>
        )
      case 5:
        return (
          <div className="block-content">
            <h4 className="text-center">Ok, agora preencha a qualificação {data && data.societyType === CustomerHelper().SocietyType.SociedadeUnipessoal ? "do sócio atual" : "dos sócios atuais"}:</h4>
            {change && partners && partners.length == 0 ? (
              partnerForm()
            ) : (
              <p>Sócios</p>
            )}
            {change &&
              partners &&
              partners.map((item, index) => {
                if (item && !item.onlyAdm) {
                  return (
                    <div>
                      <div
                        className="block block-bordered border-2x border-warning"
                        key={`partiner-${index}`}
                      >
                        <div className="block-header">
                          <div className="block-title">Sócio: {item && item.name}</div>
                          <div className="block-tools">
                            {change && item && item.edit != true ? (
                              <button
                                className="btn btn-dual"
                                title="Editar Sócio"
                                onClick={() => editItem(index)}
                              >
                                <i className="far fa-edit" />
                              </button>
                            ) : (
                              <button
                                className="btn btn-success"
                                title="Salvar Sócio"
                                onClick={() => saveItem(index)}
                              >
                                <i className="si si-success" /> Salvar
                              </button>
                            )}
                            <button
                              className="btn btn-dual"
                              onClick={() => removeItem(index)}
                            >
                              <i className="si si-close text-danger" />
                            </button>
                          </div>
                        </div>
                        {item && item.edit != true ? (
                          <div className="block-content row">
                            <div className="col-md-6">
                              <b className="font-w700">Nome completo</b>
                              <br />
                              <p>{item.name}</p>
                            </div>
                            <div className="col-md-6">
                              <b className="font-w700">Nacionalidade</b>
                              <br />
                              <p>{item.nationality}</p>
                            </div>
                            <div className="col-md-6">
                              <b className="font-w700">Estado civil</b>
                              <br />
                              <p>{CivilStateTranslate(item.civilState)}</p>
                            </div>
                            {item.civilState == CivilStateObj.Married ? (
                              <div className="col-md-6">
                                <b className="font-w700">Tipo de comunhão</b>
                                <br />
                                <p>{CommunionTranslate(item.communion)}</p>
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="col-md-6">
                              <b className="font-w700">Profissão</b>
                              <br />
                              <p>{item.jobTitle}</p>
                            </div>
                            <div className="col-md-6">
                              <b className="font-w700">RG</b>
                              <br />
                              <p>{item.doc}</p>
                            </div>
                            <div className="col-md-6">
                              <b className="font-w700">CPF</b>
                              <br />
                              <p>{CpfMask(item.stateId)}</p>
                            </div>
                            <div className="col-md-6">
                              <b className="font-w700">Logradouro</b>
                              <br />
                              <p>{item.street}</p>
                            </div>
                            <div className="col-md-6">
                              <b className="font-w700">Numero</b>
                              <br />
                              <p>{item.number ? item.number : "S/N"}</p>
                            </div>
                            <div className="col-md-6">
                              <b className="font-w700">Bairro</b>
                              <br />
                              <p>{item.neighborhood}</p>
                            </div>
                            <div className="col-md-6">
                              <b className="font-w700">Complemento</b>
                              <br />
                              <p>{item.complement}</p>
                            </div>
                            <div className="col-md-6">
                              <b className="font-w700">CEP</b>
                              <br />
                              <p>{item.postalCode}</p>
                            </div>
                            <div className="col-md-6">
                              <b className="font-w700">Cidade</b>
                              <br />
                              <p>{item.city}</p>
                            </div>
                            <div className="col-md-6">
                              <b className="font-w700">Estado</b>
                              <br />
                              <p>{item.state}</p>
                            </div>
                            {/* <div className="col-md-6">
                          <b className="font-w700">Porcentagem do capital</b>
                          <br />
                          <p>{item.shares}</p>
                        </div> */}
                          </div>
                        ) : (
                          partnerForm(index)
                        )}
                      </div>
                      <hr />
                    </div>
                  )
                }
              })}

            {newPartner == false && partners.length > 0 ? (
              <div className="text-center">
                <button
                  className="btn btn-warning btn-square mt-4 mb-4"
                  key={"newPartnet"}
                  onClick={() => setNewPartner(!newPartner)}
                >
                  Adicionar Sócio
                </button>
              </div>
            ) : newPartner == true && partners.length > 0 ? (
              partnerForm()
            ) : (
              ""
            )}

            <div className="text-right">
              <button
                className="btn btn-outline-warning btn-square mt-4 mb-4"
                onClick={() => goBack()}
              >
                Voltar
              </button>
              <button
                className="btn btn-warning btn-square mt-4 mb-4"
                onClick={() => goToFourth()}
              >
                Próximo
              </button>
            </div>
          </div>
        )
      case 6:
        return (
          <div className=" block-content">
            <div className="ml-5 mr-5 mt-3 mb-5">
              <h4 className="text-center m-0">Ok, e como está a distribuição entre os sócios?</h4>
              <p className="text-center ">Capital social registrado: {data && data.capital && MoneyMask(data.capital, 2)}</p>

              {change && partners && partners.map((item, index) => {
                if (!item.onlyAdm) {
                  return (
                    <div className="block block-rounded block-bordered">
                      <div className="block-header">
                        <div className="block-title">
                          <div class="ml-3 text-left">
                            <p class="font-w600 mb-0">{item && item.name}</p>
                            <p class="font-size-sm font-italic text-muted mb-0">
                              {item && item.stateId && CpfMask(item.stateId)}
                            </p>
                          </div>
                        </div>
                        <div className="block-tools">
                          <div className="form-group">
                            <span>Digite a %</span>
                            <input type="number" className="form-control" min={1} max={100} value={item && item.shares} onChange={evt => handleShares(evt, index)} />
                          </div>
                          <span>
                            {item && item.value ? MoneyMask(item.value, 2) : "00,00"}
                          </span>
                        </div>
                      </div>
                    </div>
                  )
                }
              }
              )}

              <div className="text-right">
                <button
                  className="btn btn-outline-warning btn-square mt-4 mb-4"
                  onClick={() => goBack()}
                >
                  Voltar
                </button>
                <button
                  className="btn btn-warning btn-square mt-4 mb-4"
                  onClick={() => setStep(step + 1)}
                >
                  Próximo
                </button>
              </div>
            </div>
          </div>
        )
      case 7:
        return (
          <div className="block-content">
            <div className="ml-5 mr-5 mt-3 mb-5">
              <div className="form-group">
                <h4 className="text-center mb-0">Quem ficará responsável pela guarda dos livros?</h4><br />
                {partners && partners.map((item, index) => (
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" class="custom-control-input" id={`partner=${index}`} name="example-rd-custom-inline" onChange={evt => selectDistractResponsible(item)} />
                    <label class="custom-control-label" for={`partner=${index}`}>{item.name}</label>
                  </div>
                ))}

              </div>
            </div>

            <div className="text-right">
              <button
                className="btn btn-outline-warning btn-square mt-4 mb-4"
                onClick={() => goBack()}
              >
                Voltar
              </button>
              <button
                className="btn btn-warning btn-square mt-4 mb-4"
                onClick={() => setStep(step + 1)}
              >
                Próximo
              </button>
            </div>
          </div>
        )
      default:
        return (
          <div className="content">
            {/* <div className="block block-fx-pop block-bordered border-success mt-5">
              <div className="block-header">
                <div className="block-content text-center">
                  <i className="far fa-check-circle text-success mr-2" />{" "}
                  Pronto, agora já tenho todas as informações necessárias para iniciarmos o Cancelamento e dentro de alguns instantes receberá inicialmente o Distrato Social para apreciação.
                </div>
              </div>
            </div> */}
            <ModeloDistrato data={data} partners={partners} />
            <div className="text-right">
              <button
                className="btn btn-outline-warning btn-square mt-4 mb-4"
                onClick={() => goBack()}
              >
                Voltar
              </button>
            </div>
          </div>
        );
    }
  }

  /* <--------- Cancelamento ---------> */


  /* Alteração */
  function searchAlterationCompany() {
    toast.info("Um momento, estamos consultando a empresa...")
    if (!data.taxId || !ValidaCNPJ(data.taxId)) {
      toast.error(Message.InvalidCNPJ)
    } else {
      setStep(step + 1);
      let infos = data;
      // if(!postalCodeOld){

      ConsultController.GetReceita(data.taxId, 0, res => {
        console.log("Dados do CNPJ", res);
        if (res.data && res.data.length > 0) {
          const base = res.data[0];
          infos.neighborhoodOld = base.endereco_bairro;
          infos.postalCodeOld = base.endereco_cep;
          infos.complementOld = base.endereco_complemento;
          infos.streetOld = base.endereco_logradouro;
          infos.cityOld = base.endereco_municipio;
          infos.numberOld = base.endereco_numero;
          infos.capitalExtended = ExtrairTexto(base.capital_social)

          infos.capitalOld = base.normalizado_capital_social;
          infos.descriptionOld = base.atividade_economica;
          if (base.nome_fantasiaOld != "") {
            infos.fantasyNameOld = base.nome_fantasia;
            infos.hasFantasyName = true
          }
          infos.nameOld = base.razao_social;
          if (states.length > 0) {
            const result = states.filter(x => x.shortName.includes(base.endereco_uf));
            if (result.length > 0) {
              infos.stateOld = result[0].longName;
            }
          }

          setData(infos)
          console.log("Dados processados", infos)
        }
        // updateBaseAlteration();
      })
      // }
    }
  }

  function processChanges() {
    if (data.nameChange === false &&
      data.addressChange === false &&
      data.subjectChange === false &&
      data.capitalChange === false &&
      data.partnersChange === false) {
      toast.error(Message.PendingData);
    } else {
      if (data.nameChange === true) {
        setStep(ElaboratorHelper.AlterationSteps.Name);
      } else if (data.addressChange === true) {
        setStep(ElaboratorHelper.AlterationSteps.Address);
      } else if (data.subjectChange === true) {
        setStep(ElaboratorHelper.AlterationSteps.Subject);
      } else if (data.capitalChange === true) {
        setStep(ElaboratorHelper.AlterationSteps.Capital);
      } else if (data.partnersChange === true) {
        setStep(ElaboratorHelper.AlterationSteps.Partners);
      }
    }
    // setStep(ElaboratorHelper.AlterationSteps.Done);
  }

  function removeFromCompany(index) {
    let newData = partners;
    newData[index].remove = newData[index].remove == true ? false : true;
    setPartners(newData);
    notifyChange();
  }

  function removeTo(index) {
    removeFromCompany(index);
    toast.success("Sócio marcado para remoção");
  }

  function partnerPresentation(item, index, isAlteration) {
    return (
      <div>
        <div
          className={item.remove === true ? "block block-bordered border-2x border-danger" : "block block-bordered border-2x border-warning"}
          key={`partiner-${index}`}
        >
          <div className="block-header">
            <div className="block-title">Sócio: {item && item.name}</div>
            <div className="block-tools">
              {change && item && item.edit != true ? (
                <button
                  className="btn btn-dual"
                  title="Editar Sócio"
                  onClick={() => editItem(index)}
                >
                  <i className="far fa-edit" />
                </button>
              ) : (
                <button
                  className="btn btn-success"
                  title="Salvar Sócio"
                  onClick={() => saveItem(index)}
                >
                  <i className="si si-success" /> Salvar
                </button>
              )}
              <button
                className="btn btn-dual"
                onClick={() => removeItem(index)}
              >
                <i className="si si-close text-danger" />
              </button>
              {isAlteration ?
                <span>
                  {item && item.remove === true ?
                    <button
                      className="btn btn-secondary"
                      onClick={() => removeFromCompany(index)}
                    >
                      <i className="si si-share-alt text-danger" /> Cancelar Removeção da sociedade
                    </button>
                    :
                    <Dropdown>
                      <Dropdown.Toggle>
                        <i className="si si-share-alt" /> Remover da sociedade
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <div className="form-group">
                          <span>Transferir quotas para:</span>
                          <select className="form-control" name="transferTo" value={data.transferTo} onChange={evt => handleChange(evt)}>
                            <option>Selecione</option>
                            {partners.map((the, i) => {
                              if (i != index) {
                                return <option value={the.name}>{the.name}</option>
                              }
                            })}
                          </select>
                          <button className="btn btn-info btn-block" onClick={() => removeTo(index)}>Transferir</button>
                        </div>
                        <hr />
                        <div className="form-group">
                          <span>Transferir quotas para novo sócio:</span>
                          <select className="form-control" name="transferToNew" value={data.transferToNew} onChange={evt => handleChange(evt)}>
                            <option>Selecione</option>
                            {partners.map((the, i) => {
                              if (i != index) {
                                return <option value={the.name}>{the.name}</option>
                              }
                            })}
                          </select>
                          <button className="btn btn-info btn-block" onClick={() => removeTo(index)}>Transferir</button>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  }
                </span>
                : ""}
            </div>
          </div>
          {item && item.edit != true ? (
            <div className="block-content row">
              <div className="col-md-6">
                <b className="font-w700">Nome completo</b>
                <br />
                <p>{item.name}</p>
              </div>
              <div className="col-md-6">
                <b className="font-w700">Nacionalidade</b>
                <br />
                <p>{item.nationality}</p>
              </div>
              <div className="col-md-6">
                <b className="font-w700">Estado civil</b>
                <br />
                <p>{CivilStateTranslate(item.civilState)}</p>
              </div>
              {item.civilState == CivilStateObj.Married ? (
                <div className="col-md-6">
                  <b className="font-w700">Tipo de comunhão</b>
                  <br />
                  <p>{CommunionTranslate(item.communion)}</p>
                </div>
              ) : (
                ""
              )}
              <div className="col-md-6">
                <b className="font-w700">Profissão</b>
                <br />
                <p>{item.jobTitle}</p>
              </div>
              <div className="col-md-6">
                <b className="font-w700">RG</b>
                <br />
                <p>{item.doc}</p>
              </div>
              <div className="col-md-6">
                <b className="font-w700">CPF</b>
                <br />
                <p>{CpfMask(item.stateId)}</p>
              </div>
              <div className="col-md-6">
                <b className="font-w700">Logradouro</b>
                <br />
                <p>{item.street}</p>
              </div>
              <div className="col-md-6">
                <b className="font-w700">Numero</b>
                <br />
                <p>{item.number ? item.number : "S/N"}</p>
              </div>
              <div className="col-md-6">
                <b className="font-w700">Bairro</b>
                <br />
                <p>{item.neighborhood}</p>
              </div>
              <div className="col-md-6">
                <b className="font-w700">Complemento</b>
                <br />
                <p>{item.complement}</p>
              </div>
              <div className="col-md-6">
                <b className="font-w700">CEP</b>
                <br />
                <p>{item.postalCode}</p>
              </div>
              <div className="col-md-6">
                <b className="font-w700">Cidade</b>
                <br />
                <p>{item.city}</p>
              </div>
              <div className="col-md-6">
                <b className="font-w700">Estado</b>
                <br />
                <p>{item.state}</p>
              </div>
            </div>
          ) : (
            partnerForm(index)
          )}
        </div>
        <hr />
      </div>
    )
  }

  async function updateBaseAlteration(done) {
    let base = data;
    if (base.status === ElaboratorHelper.Status.Pending) {
      base.status = ElaboratorHelper.Status.Ongoing;
    } else if (done === true) {
      base.status = ElaboratorHelper.Status.Done;
    }
    if (partners.length > 0) {
      for (let s of partners) {
        await PartnerController.Update(s, res => { })
      }
    }

    if (partners.length === 1 && data.societyType === CustomerHelper().SocietyType.SociedadeUnipessoal) {
      base.adminitrationType = CustomerHelper().AdministationType.ApenasIsoladamente;
    }
    await ElaboratorController.Update(base, res => setData({ ...data, ...res }));
    return false;
  }

  function processPrevious(current) {
    setStep(ElaboratorHelper.AlterationSteps.Alterations);
    setProgress(progress - 30)
  }

  function nameClause() {
    return (
      <div className="block block-fx-pop block-bordered border-2x border-warning">
        <div className="block-header">
          <div className="block-title">Nome da empresa</div>
          <div className="block-tools">

            {data.nameEdit === true ?
              <button className="btn btn-success" onClick={() => setData({ ...data, nameEdit: false }) | updateBaseAlteration()}>Salvar informação</button>
              :
              <button className="btn btn-info" onClick={() => setData({ ...data, nameEdit: true })}>Editar informação</button>
            }
          </div>
        </div>
        {data && data && data.nameEdit !== true ?
          <div>
            <div className="block-content mt-0 pt-0">
              Hoje na receita está assim: <br />
            </div>
            <div className="block block-fx-pop block-bordered border-2x border-info m-2">
              <div className="block-content mb-2">{data && data && data.nameOld}</div>
            </div>
          </div>
          : ""
        }
        {data.nameEdit === true ?
          <div className="block block-fx-pop block-bordered border-2x border-success m-2">
            <div className="block-content">

              <div className="form-group">
                <span>Descreva o novo objeto social:</span>
                <input className="form-control"
                  name="name"
                  value={data && data.name}
                  onChange={evt => handleChange(evt)}
                />
              </div>
            </div>
          </div>
          : data.name !== "" && data.name !== undefined ?

            <div className="block block-fx-pop block-bordered border-2x border-success m-2">
              <div className="block-content">

                <div className="form-group">
                  <span>Novo nome:</span> <br />
                  {data && data.name}
                </div>
              </div>
            </div>
            : ""
        }
      </div>
    )
  }

  function socialObjectClause() {
    return (
      <div className="block block-fx-pop block-bordered border-2x border-warning">
        <div className="block-header">
          <div className="block-title">Objeto social</div>
          <div className="block-tools">

            {data.descriptionEdit === true ?
              <button className="btn btn-success" onClick={() => setData({ ...data, descriptionEdit: false }) | updateBaseAlteration()}>Salvar informação</button>
              :
              <button className="btn btn-info" onClick={() => setData({ ...data, descriptionEdit: true })}>Editar informação</button>
            }
          </div>
        </div>
        {data && data && data.descriptionEdit !== true ?
          <div>
            <div className="block-content mt-0 pt-0">
              Hoje na receita está assim: <br />
            </div>
            <div className="block block-fx-pop block-bordered border-2x border-info m-2">
              <div className="block-content mb-2">{data && data && data.descriptionOld}</div>
            </div>
          </div>
          : ""
        }
        {data.descriptionEdit === true ?
          <div className="block block-fx-pop block-bordered border-2x border-success m-2">
            <div className="block-content">

              <div className="form-group">
                <span>Descreva o novo objeto social:</span>
                <textarea className="form-control"
                  name="description"
                  value={data && data.description}
                  onChange={evt => handleChange(evt)}
                />
              </div>
            </div>
          </div>
          : data.description !== "" && data.description !== undefined ?

            <div className="block block-fx-pop block-bordered border-2x border-success m-2">
              <div className="block-content">

                <div className="form-group">
                  <span>Novo objeto social:</span> <br />
                  {data && data.description}
                </div>
              </div>
            </div>
            : ""
        }
      </div>
    )
  }

  function addressClause() {
    return (
      <div className="block block-fx-pop block-bordered border-2x border-warning">
        <div className="block-header">
          <div className="block-title">Endereço da empresa</div>
          <div className="block-tools">

            {data.addressEdit === true ?
              <button className="btn btn-success" onClick={() => setData({ ...data, addressEdit: false }) | updateBaseAlteration()}>Salvar informação</button>
              :
              <button className="btn btn-info" onClick={() => setData({ ...data, addressEdit: true })}>Editar informação</button>
            }
          </div>
        </div>
        {data && data && data.addressEdit !== true ?
          <div>
            <div className="block-content mt-0 pt-0">
              Hoje na receita está assim: <br />
            </div>
            <div className="block block-fx-pop block-bordered border-2x border-info m-2">
              <div className="block-content mb-2">{data && `${data && data.streetOld} - ${data && data.neighborhoodOld} ${data && data.complementOld} | ${data && data.cityOld} / ${data && data.stateOld}`}</div>
            </div>
          </div>
          : ""
        }
        {data.addressEdit === true ?
          <div className="block block-fx-pop block-bordered border-2x border-success m-2">
            <div className="block-content">

              <div className="form-group">
                <div className="form-group">
                  <span>CEP</span>
                  <input className="form-control" name="postalCode" placeholder="CEP" value={data && data.postalCode} onChange={evt => handleChange(evt)} />
                </div>
                {data && data.postalCode && data.postalCode.length >= 8 ?
                  addressFields()
                  : ""}
              </div>
            </div>
          </div>
          : data.street !== "" && data.street !== undefined ?

            <div className="block block-fx-pop block-bordered border-2x border-success m-2">
              <div className="block-content">

                <div className="form-group">
                  <span>Novo endereço:</span> <br />
                  {data && `${data.street} ${data.number} - ${data.neighborhood} ${data.complement} | ${data.city} / ${data.state}`}
                </div>
              </div>
            </div>
            : ""
        }
      </div>
    )
  }

  function capitalClause() {
    return (
      <div className="block block-fx-pop block-bordered border-2x border-warning">
        <div className="block-header">
          <div className="block-title">Capital social da empresa</div>
          <div className="block-tools">

            {data.capitalEdit === true ?
              <button className="btn btn-success" onClick={() => setData({ ...data, capitalEdit: false }) | updateBaseAlteration()}>Salvar informação</button>
              :
              <button className="btn btn-info" onClick={() => setData({ ...data, capitalEdit: true })}>Editar informação</button>
            }
          </div>
        </div>
        {data && data && data.capitalEdit !== true ?
          <div>
            <div className="block-content mt-0 pt-0">
              O capital social está assim atualmente na Receita federal <br />
            </div>
            <div className="block block-fx-pop block-bordered border-2x border-info m-2">
              <div className="block-content mb-2">{data && data && `${MoneyMask(data.capitalOld)}`}</div>
            </div>
          </div>
          : ""
        }
        {data.capitalEdit === true ?
          <div className="block block-fx-pop block-bordered border-2x border-success m-2">
            <div className="block-content">
              {capitalFields()}
            </div>
          </div>
          : data.capital !== "" && data.capital !== undefined ?

            <div className="block block-fx-pop block-bordered border-2x border-success m-2">
              <div className="block-content">

                <div className="form-group">
                  <span>Novo capital:</span> <br />
                  {data && `${MoneyMask(data.capital)} ${data.capitalExtended}`}
                </div>
              </div>
            </div>
            : ""
        }
      </div>
    )
  }

  function partnersClause() {
    return (
      <div className="block block-fx-pop block-bordered border-2x border-warning">
        <div className="block-header">
          <div className="block-title">Sócios</div>
          <div className="block-tools">

            {data.partnerEdit === true ?
              <button className="btn btn-success" onClick={() => setData({ ...data, partnerEdit: false }) | updateBaseAlteration()}>Salvar informação</button>
              :
              <button className="btn btn-info" onClick={() => setData({ ...data, partnerEdit: true })}>Adicionar sócio</button>
            }
          </div>
        </div>

        {data.partnerEdit === true ?
          <div className="block block-fx-pop block-bordered border-2x border-success m-2">
            {partnerForm()}
          </div>
          : ""
        }
        {change && partners && partners.length > 0 ?

          <div className="block block-fx-pop block-bordered border-2x border-success m-2">
            <div className="block-content">

              <div className="form-group">
                <span>Sócios registrados:</span> <br />
                {change && partners && partners.map((item, index) => partnerPresentation(item, index, true))}
              </div>
            </div>
          </div>
          : <div className="block block-fx-pop block-bordered border-2x border-danger m-2">
            <div className="block-content text-center mb-3">
              <i className="fa fa-exclamation-circle text-danger" /> informação obrigatória
            </div>
          </div>}
      </div>
    )
  }

  function processNext(current) {
    if (current === ElaboratorHelper.AlterationSteps.Name) {
      if (data.addressChange === true) {
        setStep(ElaboratorHelper.AlterationSteps.Address);
        updateBaseAlteration()
      } else if (data.subjectChange === true) {
        setStep(ElaboratorHelper.AlterationSteps.Subject);
        updateBaseAlteration()
      } else if (data.capitalChange === true) {
        setStep(ElaboratorHelper.AlterationSteps.Capital);
        updateBaseAlteration()
      } else if (data.partnersChange === true) {
        setStep(ElaboratorHelper.AlterationSteps.Partners);
        updateBaseAlteration()
      } else {
        setStep(ElaboratorHelper.AlterationSteps.Confirmation);
        updateBaseAlteration(true);
      }
    } else if (current === ElaboratorHelper.AlterationSteps.Address) {
      if (data.subjectChange === true) {
        setStep(ElaboratorHelper.AlterationSteps.Subject);
        updateBaseAlteration()
      } else if (data.capitalChange === true) {
        setStep(ElaboratorHelper.AlterationSteps.Capital);
        updateBaseAlteration()
      } else if (data.partnersChange === true) {
        setStep(ElaboratorHelper.AlterationSteps.Partners);
        updateBaseAlteration()
      } else {
        setStep(ElaboratorHelper.AlterationSteps.Confirmation);
        updateBaseAlteration(true);
      }
    } else if (current === ElaboratorHelper.AlterationSteps.Subject) {
      if (data.capitalChange === true) {
        setStep(ElaboratorHelper.AlterationSteps.Capital);
        updateBaseAlteration()
      } else if (data.partnersChange === true) {
        setStep(ElaboratorHelper.AlterationSteps.Partners);
        updateBaseAlteration()
      } else {
        setStep(ElaboratorHelper.AlterationSteps.Confirmation);
        updateBaseAlteration(true);
      }
    } else if (current === ElaboratorHelper.AlterationSteps.Capital) {
      if (data.partnersChange === true) {
        setStep(ElaboratorHelper.AlterationSteps.Partners);
        updateBaseAlteration()
      } else {
        setStep(ElaboratorHelper.AlterationSteps.Confirmation);
        updateBaseAlteration(true);
      }
    } else if (current === ElaboratorHelper.AlterationSteps.OldPartners) {
      setStep(ElaboratorHelper.AlterationSteps.OldPartners);
      updateBaseAlteration(true);

    } else if (current === ElaboratorHelper.AlterationSteps.Contract) {
      setStep(ElaboratorHelper.AlterationSteps.Contract);
      updateBaseAlteration(true);

    }
  }


  function notice(title, description) {
    return <div className="block block-rounded block-bordered border-info">
      <div className="block-header mb-0 pb-0">
        <div className="block-title mb-0 pb-0 font-w700">{title}:</div>
      </div>
      <div className="block-content mt-0 pt-0">
        {description}
      </div>
    </div>
  }


  function changeAlteration() {
    switch (step) {
      case ElaboratorHelper.AlterationSteps.Cnpj:
        return (
          <div className="block-content">
            <div className="ml-5 mr-5 mt-3 mb-5">
              <div className="form-group">
                <h4 className="text-center">Vamos começar do começo... Qual o CNPJ da empresa que deseja alterar?</h4><br />
                <Field
                  title={"CNPJ"}
                  name={"taxId"}
                  value={data && data.taxId}
                  onChange={evt => handleChange({ target: { name: evt.target.name, value: evt.target.value.replace(/[^\d]+/g, "") } })}
                  mask={"99.999.999/9999-99"}
                  type={FieldTypeObj.Mask} />

              </div>
            </div>
            <button className="mb-3 btn btn-block btn-warning" onClick={() => searchAlterationCompany()}>Próximo</button>
          </div>
        )
      case ElaboratorHelper.AlterationSteps.Alterations:
        return (
          <div className="">
            <div className="ml-5 mr-5 mt-3 mb-5">
              <h4 className="text-center">Quais alterações deseja fazer na empresa?</h4><br />
              {/* <h4 className="text-left">Esses são os dados que já conseguimos puxar automaticamente - Clique em editar para adicionar cláusulas de alteração</h4> */}
              <Field
                title={"Nome empresarial"}
                name={"nameChange"}
                value={data && data.nameChange}
                type={FieldTypeObj.Checkbox}
                onChange={evt => handleChange(evt)}
              />
              <Field
                title={"Endereço comercial"}
                name={"addressChange"}
                value={data && data.addressChange}
                type={FieldTypeObj.Checkbox}
                onChange={evt => handleChange(evt)}
              />
              <Field
                title={"Objeto social"}
                name={"subjectChange"}
                value={data && data.subjectChange}
                type={FieldTypeObj.Checkbox}
                onChange={evt => handleChange(evt)}
              />
              <Field
                title={"Valor do capital"}
                name={"capitalChange"}
                value={data && data.capitalChange}
                type={FieldTypeObj.Checkbox}
                onChange={evt => handleChange(evt)}
              />
              <Field
                title={"Alterar quadro societário"}
                name={"partnersChange"}
                value={data && data.partnersChange}
                type={FieldTypeObj.Checkbox}
                onChange={evt => handleChange(evt)}
              />
              {/* <div className="form-group">
                <h4 className="text-center">Quais alterações deseja fazer na empresa?</h4><br />
                <h4 className="text-left">Esses são os dados que já conseguimos puxar automaticamente - Clique em editar para adicionar cláusulas de alteração</h4>

                {change && data && nameClause()}
                {change && data && socialObjectClause()}
                {change && data && partnersClause()}
                {change && data && capitalClause()}
                {change && data && addressClause()}

              </div> */}
            </div>
            <button className="mb-3 btn btn-block btn-warning" onClick={() => processChanges()}>Próximo</button>
            <button className="mb-3 btn btn-block btn-secondary" onClick={() => setStep(ElaboratorHelper.AlterationSteps.Cnpj)}>Voltar</button>
          </div>
        )
      case ElaboratorHelper.AlterationSteps.Name:
        //Name Step
        return (
          <div className="block-content">
            <div className="ml-5 mr-5 mt-3 mb-5">
              {data && data.nameOld ? notice("O nome empresarial está assim atualmente na Receita federal", data.nameOld) : ""}
              <div className="form-group">
                <h4 className="text-center">Qual será o novo nome da empresa? <br /><small>(Não é necessário digitar LTDA)</small></h4><br />
                <Field
                  title={"Nome empresarial"}
                  name={"fantasyName"}
                  value={data && data.fantasyName}
                  type={FieldTypeObj.Text}
                  onChange={evt => handleChange(evt)}
                />
              </div>
            </div>
            <button className="mb-3 btn btn-block btn-warning" onClick={() => processNext(ElaboratorHelper.AlterationSteps.Name)}>Próximo</button>
            <button className="mb-3 btn btn-block btn-secondary" onClick={() => processPrevious(ElaboratorHelper.AlterationSteps.Name)}>Voltar</button>

          </div>
        )
      case ElaboratorHelper.AlterationSteps.Address:
        //Name Step
        return (
          <div className="block-content">
            <div className="ml-5 mr-5 mt-3 mb-5">
              {data && data.postalCodeOld ? notice("O endereço está assim atualmente na Receita federal", `${data.streetOld} - ${data.neighborhoodOld} ${data.complementOld} | ${data.cityOld} / ${data.stateOld}`) : ""}
              <div className="form-group">
                <h4 className="text-center">Qual o novo endereço comercial?</h4><br />
                <div className="form-group">
                  <span>CEP</span>
                  <input className="form-control" name="postalCode" placeholder="CEP" value={data && data.postalCode} onChange={evt => handleChange(evt)} />
                </div>
                {data && data.postalCode && data.postalCode.length >= 8 ?
                  addressFields()
                  : ""}
              </div>
            </div>
            <button className="mb-3 btn btn-block btn-warning" onClick={() => processNext(ElaboratorHelper.AlterationSteps.Subject)}>Próximo</button>
            <button className="mb-3 btn btn-block btn-secondary" onClick={() => processPrevious(ElaboratorHelper.AlterationSteps.Address)}>Voltar</button>

          </div>
        )
      case ElaboratorHelper.AlterationSteps.Subject:
        //Subject Step
        return (
          <div className="block-content">
            <div className="ml-5 mr-5 mt-3 mb-5">
              {data && data.postalCodeOld ? notice("O objeto social está assim atualmente na Receita federal", data.descriptionOld) : ""}
              <div className="form-group">
                <h4 className="text-center mb-0 pb-0 mt-5">Me informe (detalhadamente) qual será o objeto social?</h4><br />
                <textarea className="form-control" title={"Objeto contratual"} type={FieldTypeObj.TextArea} name={"description"} value={data && data.description} onChange={evt => handleChange(evt)} />
              </div>
            </div>
            <button className="mb-3 btn btn-block btn-warning" onClick={() => processNext(ElaboratorHelper.AlterationSteps.Capital)}>Próximo</button>
            <button className="mb-3 btn btn-block btn-secondary" onClick={() => processPrevious(ElaboratorHelper.AlterationSteps.Address)}>Voltar</button>
          </div>
        )
      case ElaboratorHelper.AlterationSteps.Capital:
        //Subject Step
        return (
          <div className="block-content">
            <div className="ml-5 mr-5 mt-3 mb-5">
              {data && data.capitalOld ? notice("O capital social está assim atualmente na Receita federal", data.capitalOld) : ""}
              <div className="form-group">
                <h4 className="text-center">Show! Então vamos falar de capital social, quanto será investido em capital?</h4>
                <div className="form-group">
                  <span> Digite o valor em R$</span>
                  <CurrencyFormat
                    className={`form-control`}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    name="marketValue"
                    prefix={"R$"}
                    min={100}
                    value={data && data.capital}
                    onValueChange={(values) => {
                      const { formattedValue, value } = values;
                      handleChange(
                        { target: { name: "capital", value: value } },
                        FieldTypeObj.Currency,
                      );
                    }}
                  />
                </div>
                <div className="form-group">
                  <span>Para confirmar escreva o valor acima por extenso</span>
                  <input className="form-control" name="capitalExtended" placeholder="Exemplo: dez mil" value={data && data.capitalExtended} onChange={evt => handleChange(evt)} />
                </div>
              </div>
            </div>
            <button className="mb-3 btn btn-block btn-warning" onClick={() => processNext(ElaboratorHelper.AlterationSteps.Partners)}>Próximo</button>
            <button className="mb-3 btn btn-block btn-secondary" onClick={() => processPrevious(ElaboratorHelper.AlterationSteps.Capital)}>Voltar</button>
          </div>
        )
      case ElaboratorHelper.AlterationSteps.Partners:
        //Subject Step
        return (
          <div className="block-content">
            <div className="ml-5 mr-5 mt-3 mb-5">
              <div className="form-group">
                <h4 className="text-center">Quem são os sócios?</h4>

                {change && partners && partners.length == 0 ? (
                  partnerForm()
                ) : (
                  <p></p>
                )}
                {change &&
                  partners &&
                  partners.map((item, index) => {
                    if (item && !item.onlyAdm) {
                      return partnerPresentation(item, index)
                    }
                  })}
                {newPartner == false && partners.length > 0 && data && data.societyType !== CustomerHelper().SocietyType.SociedadeUnipessoal ? (
                  <div className="text-center">
                    <button
                      className="btn btn-warning btn-square mt-4 mb-4"
                      key={"newPartnet"}
                      onClick={() => setNewPartner(!newPartner)}
                    >
                      Adicionar Sócio
                    </button>
                  </div>
                ) : newPartner == true && partners.length > 0 ? (
                  partnerForm()
                ) : (
                  ""
                )}

                <h4 className="text-center m-0">Ok, e como ficará a distribuição entre os sócios?</h4>
                <p className="text-center ">Capital social registrado: {data && data.capital && MoneyMask(data.capital, 2)}</p>

                {change && partners && partners.map((item, index) => {
                  if (!item.onlyAdm) {
                    return partnerQuotes(item, index)
                  }
                })}
              </div>

              {/* Adicionar um botão de transferência de quotas */}
            </div>
            <button className="mb-3 btn btn-block btn-warning" onClick={() => processNext(ElaboratorHelper.AlterationSteps.Confirmation)}>Próximo</button>
            <button className="mb-3 btn btn-block btn-secondary" onClick={() => processPrevious(ElaboratorHelper.AlterationSteps.Capital)}>Voltar</button>
          </div>
        )

      case ElaboratorHelper.AlterationSteps.OldPartners:
        return (
          <div className=" block-content">
            <div className="ml-5 mr-5 mt-3 mb-5">
              <h4 className="text-center">Perfeito, já temos todas as informações novas da empresa, porém nem todos os dados podemos puxar automaticamente.</h4>

              {data && thePartner()}

              <button className="mb-3 btn btn-block btn-warning" onClick={() => processNext(ElaboratorHelper.AlterationSteps.Done)}>Próximo</button>
              <button className="mb-3 btn btn-block btn-secondary" onClick={() => processPrevious(ElaboratorHelper.AlterationSteps.Capital)}>Voltar</button>
            </div>
          </div>
        );

      case ElaboratorHelper.AlterationSteps.Confirmation:
        //Subject Step
        return (
          <div className="block-content">
            <div className="ml-5 mr-5 mt-3 mb-5">
              <div className="form-group">
                <h4 className="text-center">Perfeito, já temos todas as informações novas da empresa que você quer alterar. Agora precisamos que preencha as informações faltantes para elaborarmos o contrato de alteração com sucesso.
                  <br />
                  <br />
                  <span className="text-danger">Atenção!</span> As informações que você preencher a partir de agora irão alimentar o contrato de alteração, ou seja, preencha os dados de forma idêntica ao último contrato registrado para evitar exigências.</h4>
              </div>

              {data && data.subjectChange === false ?
                <div className="block block-rounded block-bordered block-fx-pop">
                  <div className="block-header">
                    <div className="block-title">Digite aqui o objeto social informado<br /><span className="text-danger">De acordo com o último contrato registrado</span></div>
                  </div>
                  <div className="block-content mb-4">
                    <textarea className="form-control" title={"Objeto contratual"} type={FieldTypeObj.TextArea} name={"description"} value={data && data.description} onChange={evt => handleChange(evt)} />
                  </div>
                </div>
                : ""}

              <hr />

              {data && data.partnersChange === false ?
                <div className="block block-rounded block-bordered block-fx-pop">
                  <div className="block-header">
                    <div className="block-title">Preencha a qualificação dos sócios <br /><span className="text-danger">De acordo com o último contrato registrado</span></div>
                  </div>
                  <div className="block-content">
                    {thePartner()}
                  </div>
                </div>
                : ""}

              <hr />
              <div className="block block-rounded block-bordered block-fx-pop">
                <div className="block-header">
                  <div className="block-title">Confirme abaixo a divisão das quotas<br /><span className="text-danger">De acordo com o último contrato registrado</span></div>
                </div>
                <div className="block-content">
                  {change && partners && partners.map((item, index) => {
                    if (!item.onlyAdm) {
                      return partnerQuotes(item, index)
                    }
                  })}
                </div>
              </div>

              <hr />
              <div className="block block-rounded block-bordered block-fx-pop">
                <div className="block-header">
                  <div className="block-title">Confirme quem são os administradores da empresa<br /><span className="text-danger">De acordo com o último contrato registrado</span></div>
                </div>
                <div className="block-content">

                  {change && partners && partners.map((item, index) => administratorForm(item, index))}

                  {data && data.newAdm ?
                    <div className="border border-rounded border-2x border-warning">
                      {partnerForm(undefined, true)}
                    </div>
                    : ""}

                  <div className="text-center py-3 mb-2">
                    <button className="btn btn-success" onClick={() => setData({ ...data, newAdm: data.newAdm == false || data.newAdm == undefined ? true : false })}>{data.newAdm == false || data.newAdm == undefined ? "Adicionar administrador não sócio" : "Remover formulário"}</button>
                  </div>

                  <span>Como é feito o uso da administração<br /><span className="text-danger">De acordo com o último contrato registrado</span></span>
                  {change && administrationOfCompany()}
                </div>
              </div>

              <hr />
              <div className="block block-rounded block-bordered block-fx-pop">
                <div className="block-header">
                  <div className="block-title">Como é a retirada de prólabore<br /><span className="text-danger">De acordo com o último contrato registrado</span></div>
                </div>
                <div className="block-content">
                  {change && partners && partners.map((item, index) => {
                    if (!item.onlyAdm) {
                      return (
                        <div className="block block-rounded block-bordered">
                          <div className="block-header">
                            <div className="block-title">
                              <div class="ml-3 text-left">
                                <p class="font-w600 mb-0">{item && item.name}</p>
                                <p class="font-size-sm font-italic text-muted mb-0">
                                  {item && item.stateId && CpfMask(item.stateId)}
                                </p>
                              </div>
                            </div>
                            <div className="block-tools">
                              <div className="custom-control custom-checkbox custom-control-warning mb-1">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  checked={item.prolabore}
                                  id={index}
                                  onChange={(evt) => checkProlabore(evt, index)}
                                />
                                <label className="custom-control-label" for={index}>
                                  Selecionar
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    }
                  })}

                </div>
              </div>


              <button className="mb-3 btn btn-block btn-warning" onClick={() => processNext(ElaboratorHelper.AlterationSteps.Contract)}>Próximo</button>
              <button className="mb-3 btn btn-block btn-secondary" onClick={() => processPrevious(ElaboratorHelper.AlterationSteps.Alterations)}>Voltar</button>
            </div>
          </div>
        )

      case ElaboratorHelper.AlterationSteps.Contract:
        //Subject Step
        return (
          <div className="block-content">
            <div className="ml-5 mr-5 mt-3 mb-5">
              <div className="form-group">
                <h4 className="text-center">Contrato</h4>
              </div>

              <ModeloAlteracao data={data} partners={partners} />

              <button className="mb-3 btn btn-block btn-secondary" onClick={() => processPrevious(ElaboratorHelper.AlterationSteps.Alterations)}>Voltar</button>
            </div>
          </div>
        )
    }

  }
  /* <-------------- Alteração --------------> */

  async function uploadFile(fl) {
    setLoading(true);
    var formData = new FormData();
    for (let s in fl) {
      formData.append("file", fl[s]);
    }

    await ReminderFileController.Upload(formData, data.id, (res) => {
      toast.info("Upload concluído");
      setLoading(false);
      loadReminder();
    });
  }

  return (
    <div className="">
      <div className="row no-gutters justify-content-center bg-body-dark">
        <div className="hero-static col-sm-10 col-md-10 col-xl-8 d-flex align-items-center p-2 px-sm-0">
          <div className="block block-rounded block-fx-pop w-100 mb-0 overflow-hidden">
            <div className="block-content">
              {data &&
                data.status === ReminderHelper.Status.Done &&
                userData == null ? (
                <div className="block block-fx-pop block-bordered border-success mt-5">
                  <div className="block-header">
                    <div className="block-title">
                      <i className="far fa-check-circle text-success mr-2" />{" "}
                      Você já respondeu esse lembrete
                    </div>
                    <div className="block-tools"></div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="text-center">
                <img src={logo} width="150px" />
                <br />
                {step && step <= 3 ? (
                  <h3 className="text-muted mt-3 mb-1">{form == 0 ? "Contrato de abertura" : form == 1 ? "Contrato de alteração" : "Contrato de cancelamento"}</h3>
                ) : (
                  ""
                )}
                {step && step <= 3 ? (
                  <p className="text-muted mt-0 mb-5">
                    Preencha os dados para gerar o documento
                  </p>
                ) : (
                  ""
                )}
              </div>
              <div class="progress ml-4 mt-3 mr-4 push" style={{ height: 6 }}>
                <div
                  class="progress-bar progress-bar-striped progress-bar-animated bg-warning"
                  role="progressbar"
                  style={{ width: `${progress}%` }}
                ></div>
              </div>

              {step && form == 0 ? changeOpenStep() : form == 1 ? changeAlteration() : changeCancelation()}

            </div>
          </div>
        </div>
        <ToastContainer className="toastr" />
        <input
          type="file"
          ref={inputFile}
          onChange={(event) => {
            uploadFile(event.currentTarget.files);
          }}
          style={{ visibility: "hidden", width: 10 }}
        />
      </div>
    </div>
  );
}
