import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";
import { HandleErrors } from "../../util/helpers/ErrorHelper";

export default function DebtorController() {
  async function Insert(data, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `debtor/insert`,
        data,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err)
    );
  }
 
  async function Update(data, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `debtor/update`,
        data,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err)
    );
  }

  async function Get(startDate, endDate, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `debtor/?startDate=${startDate}&endDate=${endDate}`,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err)
    );
  }
  
  async function GetDeleteds(startDate, endDate, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `debtor/deleteds/?startDate=${startDate}&endDate=${endDate}`,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err)
    );
  }
  async function Delete(id, response, error) {
    await BaseService(
      {
        method: "DELETE",
        url: `debtor/delete/${id}`,
      },
      (res) => response(res),
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err)
    );
  }

  return {
    Insert,
    Update,
    Delete,
    Get,
    GetDeleteds,
  };
}
