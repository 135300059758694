import {
  XAxis,
  AreaChart,
  Area,
  Tooltip,
  ResponsiveContainer,
  YAxis,
  CartesianGrid,
} from "recharts";
import ReportHelper from "@/app/core/util/helpers/ReportHelper";
import React, { useEffect, useState } from "react";
import ReportController from "@/app/core/base/controllers/ReportController";

export default function SalesVolumeReport({ }) {
  const date = new Date();
  const [data, setData] = useState({ total: 0, ticket: 0, count: 0 });
  const [startDate, setStartDate] = useState(
    getFirstDayOfMonth(date.getFullYear(), 0).toISOString().substring(0, 10),
  );
  const [endDate, setEndDate] = useState(
    getLastDayOfMonth(date.getFullYear(), 11).toISOString().substring(0, 10),
  );
  const reportHelper = ReportHelper();
  const report = ReportController();

  useEffect(() => {
    loadReport();
  }, [startDate, endDate]);

  function getFirstDayOfMonth(year, month) {
    return new Date(year, month, 1);
  }

  function getLastDayOfMonth(year, month) {
    return new Date(year, month, month === 1 ? 28 : 30);
  }

  async function loadReport() {

    await report.SaleVolume(startDate, endDate, async (current) => {
      await report.SaleVolume(
        getFirstDayOfMonth(date.getFullYear() - 1, 0).toISOString().substring(0, 10)
        , getLastDayOfMonth(date.getFullYear() - 1, 11).toISOString().substring(0, 10),
        async (lastYear) => {
          await report.SaleVolume(
            getFirstDayOfMonth(date.getFullYear() - 2, 0).toISOString().substring(0, 10)
            , getLastDayOfMonth(date.getFullYear() - 2, 11).toISOString().substring(0, 10)

            , async (theYearBefore) => {

              process(current, lastYear, theYearBefore);

            });
        });
    });
  }

  function process(res, lastYear, theYearBefore) {
    let responses = reportHelper.base;

    for (let s in responses) {
      for (let i in res) {
        if (res[i].month == responses[s].value) {
          responses[s].atual = parseInt(res[i].result);
        }
      }
      for (let i in lastYear) {
        if (lastYear[i].month == responses[s].value) {
          responses[s].ano_passado = parseInt(lastYear[i].result);
        }
      }
      for (let i in theYearBefore) {
        if (theYearBefore[i].month == responses[s].value) {
          responses[s].ano_retrasado = parseInt(theYearBefore[i].result);
        }
      }
    }

    setData(responses);
  }


  function processDate(year) {
    setStartDate(getFirstDayOfMonth(year, 0).toISOString().substring(0, 10));

    setEndDate(getLastDayOfMonth(year, 11).toISOString().substring(0, 10));
  }

  return (
    <div className="col-md-12" key={"ticketsReport"}>
      <div className="block block-rounded block-bordered">
        <ul className="nav nav-tabs nav-tabs-block align-items-center border-bottom">
          <li className="nav-item">
            <a
              className="nav-link text-body-color active"
              href="javascript:void(0)"
            >
              Volume de vendas
            </a>
          </li>
        </ul>
        {data && data ? (
          <div className="block-content block-content-full">
            <ResponsiveContainer height={300} key={"ativos"}>
              <AreaChart
                width={400}
                height={400}
                data={data}
                margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />

                <Area
                  type="monotone"
                  dataKey={"ano_retrasado"}
                  stroke={reportHelper.colors[3]}
                  fill={reportHelper.colors[3]}
                />
                <Area
                  type="monotone"
                  dataKey={"ano_passado"}
                  stroke={reportHelper.colors[0]}
                  fill={reportHelper.colors[0]}
                />
                <Area
                  type="monotone"
                  dataKey={"atual"}
                  stroke={reportHelper.colors[6]}
                  fill={reportHelper.colors[6]}
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
