export const ElaboratorHelper = {
    TypeList: [
        { name: "Abertura", value: 0, color: "info" },
        { name: "Alteração", value: 1, color: "warning" },
        { name: "Cancelamento", value: 2, color: "danger" },
    ],

    StatusList: [
        { name: "Pendente", value: 0, color: "info" },
        { name: "Parcialmente preenchido", value: 1, color: "warning" },
        { name: "Concluído", value: 2, color: "danger" },
    ],

    Type: {
        Abertura: 0,
        Alteracao: 1,
        Cancelamento: 2,
    },

    AlterationSteps: {
        Cnpj: 1,
        Alterations: 2,
        Name: 3,
        Address: 4,
        Subject: 5,
        Capital: 6,
        Partners: 7,
        Confirmation: 8,
        OldPartners: 9,
        Done: 10,
        Contract: 11,
    },

    OpenSteps: {
        CompanyType: 1,
        Name: 2,
        Partners: 3,
        Address: 4,
        Subject: 5,
        Capital: 6,
        Shares: 7,
        Administrators: 8,
        Prolabore: 9,
        Done: 10,
    },

    GetType: function (i) {
        const res = this.TypeList.filter(x => x.value === i);
        if (res.length > 0) {
            return <span className={`badge badge-${res[0].color}`}>{res[0].name}</span>
        } else {
            return <span className={`badge badge-secondary`}>S.I.</span>

        }
    },
    GetStatus: function (i) {
        const res = this.StatusList.filter(x => x.value === i);
        if (res.length > 0) {
            return <span className={`badge badge-${res[0].color}`}>{res[0].name}</span>
        } else {
            return <span className={`badge badge-secondary`}>S.I.</span>

        }
    },

    GetOrgan: function (state) {
        if (state.includes("Acre")) {
            return "Junta Comercial do Estado do Acre (JUCEAC)";
        } else if (state.includes("Alagoas")) {
            return "Junta Comercial do Estado de Alagoas (JUCEAL)";
        } else if (state.includes("Amazonas")) {
            return "Junta Comercial do Estado do Amazonas (JUCEA)";
        } else if (state.includes("Amapá")) {
            return "Junta Comercial do Estado do Amapá (JUCAP)";
        } else if (state.includes("Bahia")) {
            return "Junta Comercial do Estado da Bahia (JUCEB)";
        } else if (state.includes("Ceará")) {
            return "Junta Comercial do Estado do Ceará (JUCEC)";
        } else if (state.includes("Distrito Federal")) {
            return "Junta Comercial, Industrial e Serviços do Distrito Federal (JUCIS-DF)";
        } else if (state.includes("Espírito Santo")) {
            return "Junta Comercial do Estado do Espírito Santo (JUCEES)";
        } else if (state.includes("Goiás")) {
            return "Junta Comercial do Estado de Goiás (JUCEG)";
        } else if (state.includes("Maranhão")) {
            return "Junta Comercial do Estado do Maranhão (JUCEMA)";
        } else if (state.includes("Minas Gerais")) {
            return "Junta Comercial do Estado de Minas Gerais (JUCEMG)";
        } else if (state.includes("Mato Grosso do Sul")) {
            return "Junta Comercial do Estado de Mato Grosso do Sul (JUCEMS)";
        } else if (state.includes("Mato Grosso")) {
            return "Junta Comercial do Estado de Mato Grosso (JUCEMAT)";
        } else if (state.includes("Pará")) {
            return "Junta Comercial do Estado do Pará (JUCEPA)";
        } else if (state.includes("Paraíba")) {
            return "Junta Comercial do Estado da Paraíba (JUCEP)";
        } else if (state.includes("Pernambuco")) {
            return "Junta Comercial do Estado de Pernambuco (JUCEPE)";
        } else if (state.includes("Piauí")) {
            return "Junta Comercial do Estado do Piauí (JUCEPI)";
        } else if (state.includes("Paraná")) {
            return "Junta Comercial do Estado do Paraná (JUCEPAR)";
        } else if (state.includes("Rio de Janeiro")) {
            return "Junta Comercial do Estado do Rio de Janeiro (JUCERJA)";
        } else if (state.includes("Rio Grande do Norte")) {
            return "Junta Comercial do Estado do Rio Grande do Norte (JUCERN)";
        } else if (state.includes("Rondônia")) {
            return "Junta Comercial do Estado de Rondônia (JUCER)";
        } else if (state.includes("Roraima")) {
            return "Junta Comercial do Estado de Roraima (JUCERR)";
        } else if (state.includes("Rio Grande do Sul")) {
            return "Junta Comercial do Estado do Rio Grande do Sul (JUCISRS)";
        } else if (state.includes("Santa Catarina")) {
            return "Junta Comercial do Estado de Santa Catarina (JUCESC)";
        } else if (state.includes("Sergipe")) {
            return "Junta Comercial do Estado de Sergipe (JUCESE)";
        } else if (state.includes("São Paulo")) {
            return "Junta Comercial do Estado de São Paulo (JUCESP)";
        } else if (state.includes("Tocantins")) {
            return "Junta Comercial do Estado do Tocantins (JUCETINS)";
        }
    },

    Status: {
        Pending: 0,
        Ongoing: 1,
        Done: 2,
    }
}