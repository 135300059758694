import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";
import { HandleAuthErrors, HandleErrors } from "../../util/helpers/ErrorHelper";

export function UserInsert(values, response, error) {
  BaseService(
    {
      method: "POST",
      url: "account/register",
      data: values,
    },
    (res) => {
      response(res);
    },
    (err) => {
      error !== undefined ? error(err) : toast.error(HandleAuthErrors(err));
    },
  );
}

export function UserUpdate(data, response) {
  BaseService(
    {
      method: "POST",
      url: "account/update",
      data: data,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error("Erro ao atualizar usuário");
    },
  );
}

export function UserConsult(email, response) {
  BaseService(
    {
      method: "GET",
      url: `account/consult?email=${email}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      if (err && err.status === 200) {
        response(err.data);
      } else {
        toast.error("Erro ao atualizar usuário");
      }
    },
  );
}

export function LoginAccount(data, response) {
  BaseService(
    {
      method: "POST",
      url: "account/login",
      data: data,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleAuthErrors(err));
    },
  );
}

export function ResetPassword(data, response) {
  BaseService(
    {
      method: "POST",
      url: "account/password/reset",
      data: data,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function ForgotPassword(data, contact, response) {
  BaseService(
    {
      method: "POST",
      url: `account/password/forgot/${data}?contact=${contact}`,
      data: data,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleAuthErrors(err));
    },
  );
}

export function CompanyUsers(response) {
  BaseService(
    {
      method: "GET",
      url: "account/company-users",
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function CustomerUsers(customerId, response) {
  BaseService(
    {
      method: "GET",
      url: `account/customer-users/${customerId}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function UserDelete(userId, response) {
  BaseService(
    {
      method: "DELETE",
      url: `account/delete/${userId}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function UserSelect(userId, response) {
  BaseService(
    {
      method: "GET",
      url: `account/users/${userId}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error("Erro ao selecionar usuário");
    },
  );
}

export function DepartmentUsers(level, response) {
  BaseService(
    {
      method: "GET",
      url: `account/department-users/${level}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error("Erro ao selecionar usuário");
    },
  );
}

export function UserPictureUpload(formData, userId, response) {
  BaseService(
    {
      method: "POST",
      url: `account/users/${userId}/upload-avatar`,
      data: formData,
      contentType: "multipart/form-data",
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error("Erro ao atualizar a foto do usuário");
    },
  );
}

export default function AccountController() {
  async function Register(values, response, error) {
    await BaseService(
      {
        method: "POST",
        url: "account/register",
        data: values,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleAuthErrors(err)) : error(err),
    );
  }
  async function SendToken(data, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `account/2fa/validate`,
        data,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function ReportAccess(data, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `account/access-history`,
        data,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  }

  async function Update(data, response, error) {
    await BaseService(
      {
        method: "POST",
        url: "account/update",
        data: data,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleAuthErrors(err)) : error(err),
    );
  }

  async function CompanyUsers(response, error) {
    await BaseService(
      {
        method: "GET",
        url: "account/company",
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleAuthErrors(err)) : error(err),
    );
  }

  async function Get(userId, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `account/users/${userId}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleAuthErrors(err)) : error(err),
    );
  }

  async function RestartPassword(userId, data, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `account/users/${userId}/password/update`,
        data,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleAuthErrors(err)) : error(err),
    );
  }

  async function AdministratorRestartPassword(userId, data, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `account/administrator/users/${userId}/password/update`,
        data,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleAuthErrors(err)) : error(err),
    );
  }

  async function ConsultEmail(email, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `account/consult?email=${email}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleAuthErrors(err)) : error(err),
    );
  }

  async function Login(data, response, error) {
    await BaseService(
      {
        method: "POST",
        url: "account/login",
        data: data,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleAuthErrors(err)) : error(err),
    );
  }

  async function AvatarUpload(formData, userId, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `account/users/${userId}/upload-avatar`,
        data: formData,
        contentType: "multipart/form-data",
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleAuthErrors(err)) : error(err),
    );
  }

  function AdministratorForgotPassword(email, response) {
    BaseService(
      {
        method: "POST",
        url: `account/administrator/password/forgot/${email}`,
      },
      (res) => {
        response(res);
      },
      (err) => {
        toast.error(HandleAuthErrors(err));
      },
    );
  }
  
  function InvalidOfCustomer(customerId, response) {
    BaseService(
      {
        method: "GET",
        url: `account/invalid-users/${customerId}`,
      },
      (res) => {
        response(res);
      },
      (err) => {
        toast.error(HandleAuthErrors(err));
      },
    );
  }

  return {
    Register,
    Update,
    ConsultEmail,
    Login,
    AvatarUpload,
    CompanyUsers,
    RestartPassword,
    AdministratorRestartPassword,
    AdministratorForgotPassword,
    SendToken,
    ReportAccess,
    Get,
    InvalidOfCustomer,
  };
}
