import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProcessController from "@/app/core/base/controllers/ProcessController";
import { MoneyMask } from "@/app/core/util/helpers/MoneyHelper";
import Barcode from 'react-barcode';
import Storage from "@/app/core/util/Storage";
import Logo from "@/assets/img/logo.jpg";
import ServiceChargeController from "@/app/core/base/controllers/ServiceChargeController";
import FinancialHelper from "@/app/core/util/helpers/FinancialHelper";
import CopyToClipboard from "react-copy-to-clipboard";
import { CompleteDateToPast, OnlyDateTranslate } from "@/app/core/util/DateTranslater";

function PaymentPage(props) {
  const [data, setData] = useState({});
  const [userData, setUserData] = useState(Storage.getUserData());
  const [disabled, setDisabled] = useState(false);
  const [info, setInfo] = useState(null);
  const [logo, setLogo] = useState(Logo);
  const [view, setView] = useState("");
  const enviroment = process.env.REACT_APP_ENV;

  const processController = ProcessController();
  const chargeController = ServiceChargeController();
  const financialHelper = FinancialHelper();

  useEffect(() => {
    loadCharge();
  }, []);

  async function loadCharge() {
    const query = props.match.params.key;
    if(query.length > 10){
      await chargeController.GetByKey(query, async (res) => continueProcess(res));
    }else{
      await chargeController.Get(query, async (res) => continueProcess(res));
    }

  }

  function continueProcess(res){
    if(CompleteDateToPast(res.dueDate) > 0 && res.discount > 0){
      res.entries.push({
        dueDate:res.createdDate,
        description:`Desconto de ${MoneyMask(res.discount)} para pagamento até ${OnlyDateTranslate(res.dueDate)}`,
        type:FinancialHelper().OperationType.Discount,
        value: res.discount,
      })

      res.price += res.discount
    }
    setData(res);
    loadStatus(res);
  }

  function openCustomerPanel() {
    window.open("https://app.hasa.com.br/", "_blank");
  }

  function loadStatus(svc) {
    if (svc.status === financialHelper.PaymentStatus.Paid) {
      setInfo(
        <div className="block block-fx-pop block-bordered border-info mt-5">
          <div className="block-header">
            <div className="block-title">
              <i className="far fa-check-circle text-info mr-2" /> Esse boleto já foi pago!
              <br />
              <small>Acompanhe seus pedidos na área do cliente</small>
            </div>
            <div className="block-tools">
              <button
                disabled={disabled}
                onClick={() => openCustomerPanel()}
                className="btn btn-hero-info btn-sm"
              >
                Clique aqui
              </button>
            </div>
          </div>
        </div>
      );
    }
  }

  async function openPayment() {
    const options = data.boletos;
    const hasPdf = options.filter(x => x.url.includes(".pdf"));
    const hasHtml = options.filter(x => x.url.includes(".html"));
    if(hasPdf.length > 0){
      window.location = hasPdf[0].url;
    }else{
      window.location = hasHtml[0].url;
    }
  }

  async function notifyCopy() {
    toast.info("Código do boleto copiado");
  }

  function checkCustomerCode(){
    
    
    // if (enviroment == "local") {
    //   openPayment(true);
    //   return;
    // }
    let customerCode = window.prompt(
      "Para acessar essa tela, confirme seu código de cliente",
    );
    if (customerCode === data.customer.customerCode) {
      openPayment(true);
    } else {
      let customerCode2 = window.prompt(
        "Código de cliente inválido, tente novamente : ",
      );
      if (customerCode2 === data.customer.customerCode) {
        openPayment(true);
      } else {
        if (customerCode === data.customer.customerCode) {
          openPayment(true);
        } else {
          let customerCode2 = window.prompt(
            "Código de cliente inválido, tente novamente : ",
          );
          if (customerCode2 === data.customer.customerCode) {
            openPayment(true);
          } else {
            if (customerCode === data.customer.customerCode) {
              openPayment(true);
            } else {
              let customerCode2 = window.prompt(
                "Código de cliente inválido, tente novamente : ",
              );
              if (customerCode2 === data.customer.customerCode) {
                openPayment(true);
              } else {
                let customerCode2 = window.prompt(
                  "Código de cliente inválido, tente novamente : ",
                );
                if (customerCode2 === data.customer.customerCode) {
                  openPayment(true);
                } else {
                  toast.info(
                    "Você errou o código muitas vezes e será redirecionado ao site",
                  );
                  setTimeout(() => {
                    window.location = "https://hasa.com.br";
                  }, 3900);
                }
              }
            }
          }
        }
      }
    }
  }

  return (
    <div
      style={{
        width: "100%",
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        height: "100vh",
      }}
    >
      <div
        className="container mb-3 "
        style={{ maxWidth: "1000px", margin: "0 auto", marginTop: "3em" }}
      >
        <div className="row">
          <div
            className="col-lg-12"
            align="center"
            style={{ marginBottom: "1em" }}
          >
            <img src={logo} width="150px" />
          </div>
        </div>
        <div className="mb-5 pb-5">
          <div className="block block-fx-shadow">
            <div className="block-header block-header-default">
              <h3 className="block-title">Boleto #{data && data.nossoNumero}</h3>
              <div className="block-options">
                <button type="button" className="btn-block-option" onClick={() => window.print()}>
                  <i className="si si-printer mr-1"></i> Imprimir
                </button>
              </div>
            </div>
            <div className="block-content">
              <div className="">
                <div className="table-responsive push">
                  <table className="table table-bordered">
                    <thead className="bg-body">
                      <tr>
                        <th>Data</th>
                        <th>Item</th>
                        <th className="text-right" style={{ width: 120 }}>Valor</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data && data.previousBalance !== 0 && data.previousBalance !== null && data.previousBalance !== undefined ? 
                      <tr>
                        <td></td>
                        <td>
                          <p className="font-w600 mb-1">Saldo anterior</p>
                        </td>
                        <td className="text-right">{data.previousBalance < 0 ? <div className="text-danger">{MoneyMask(data.previousBalance, 2)}</div> : <div className="text-success">{MoneyMask(data.previousBalance, 2)}</div>}</td>
                      </tr>
                      : ""}
                      {data && data.entries && data.entries.map(item => {
                        if (item.type != FinancialHelper().OperationType.Notification) {
                          return <tr>
                            <td>{OnlyDateTranslate(item.dueDate)}
                            </td>
                            <td>
                              <p className="font-w600 mb-1">{item.description} {item.process && `- ${item.process.processName}`}</p>
                              {/* <div className="text-muted">{item.description}</div> */}
                            </td>
                            <td className="text-right">{item && item.type == FinancialHelper().OperationType.Debit ? <div className="text-danger">-{MoneyMask(item.value, 2)}</div> : <div className="text-success">{MoneyMask(item.value, 2)}</div>}</td>
                          </tr>
                        }
                      })}
                      <tr>
                        <td colspan="2" className="font-w700 text-uppercase text-right bg-body-light">Total</td>
                        <td className="font-w700 text-right bg-body-light text-danger">{data && data.price && new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(data.price)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <p className="text-muted text-center my-5">
                  {/* <CopyToClipboard
                    text={data && data.numeroLinhaDigitavel}
                  >
                    <button
                      type="button"
                      className="btn btn-sm btn-warning mr-1 mb-3"
                      title="copiar"
                      onClick={() => notifyCopy()}
                    >
                      <i className="far fa-copy mr-1" /> Copiar código do boleto
                    </button>
                  </CopyToClipboard> */}
                    <button
                      type="button"
                      className="btn btn-block btn-warning mr-1 mb-3"
                      title="copiar"
                      onClick={() => checkCustomerCode()}
                    >
                      <i className="si si-lock-open mr-1" /> Acessar Boleto
                    </button>
                  {/* <Barcode value={data && data.codigoBarras} format="ITF" /> */}
                </p>
              </div>
            </div>
          </div>
          {info}
        </div>
        <ToastContainer className="toastr" />
      </div>
    </div>
  );
}

export default PaymentPage;
