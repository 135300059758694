import React, { useEffect, useState } from "react";
import { MoneyMask } from "@/app/core/util/helpers/MoneyHelper";
import CashFlowController from "@/app/core/base/controllers/CashFlowController";
import DebtorController from "@/app/core/base/controllers/DebtorController";
import DebtorLine from "./DebtorLine";

export default function DebtorCard({startDate, endDate, reload}) {
  const [data, setData] = useState([]);
  const cashFlow = CashFlowController();

  const date = new Date();
  const [change, setChange] = useState(date.getTime());

  useEffect(() => {
    loadDebtor();
  }, [reload]);

  function notifyChange() {
    setChange(date.getTime());
  }

  async function loadDebtor() {
    DebtorController().Get(startDate, endDate, (res) => {
        setData(res);
    }, error => console.log("Erro", error));
  }

  return (
    <div>
        <table className="table table-hover table-bordered">
            <thead>
                <tr>
                    <th>Data</th>
                    <th>Código de cliente</th>
                    <th>Código sistema</th>
                    <th>Nome</th>
                    <th>Valor</th>
                </tr>
            </thead>
            <tbody>
                {data && data.map(item => (
                    <DebtorLine infos={item} reload={()=> loadDebtor()}/>
                ))}
                <DebtorLine reload={()=> loadDebtor()}/>
            </tbody>
        </table>
    </div>
  );
}
