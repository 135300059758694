import { ReminderController } from "@/app/core/base/controllers/ReminderController";
import PlanButton from "@/app/core/layouts/main-components/plan/PlanButton";
import Storage from "@/app/core/util/Storage";
import { OrganHelper } from "@/app/core/util/helpers/OrganHelper";
import { ReminderHelper } from "@/app/core/util/helpers/ReminderHelper";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

export default function ReminderCard({ customerId, reload, available }) {
    const [data, setData] = useState([]);
    const userData = Storage.getUserData();

    useEffect(() => {
        if(customerId){
            loadReminders()
        }
    }, [])

    function loadReminders() {
        if(userData && userData.level == 5){
            ReminderController.List(customerId, res => {
                setData(res.filter(x => x.status == ReminderHelper.Status.Ongoing));
            })
        }
    }

    function openItem() {
        if(userData && userData.level !== 5){
              toast.error("Área restrita ao cliente")
            }else if (available) {
            window.location = `#/reminders?customerId=${customerId}`;
        } else {

        }
    }

    return (
        <div
            className="block block-fx-pop bg-white block-roundedMax mb-3 pb-3 ml-1 cursor"
            style={{ height: 210 }}
            onClick={() => openItem()}
        >
            {userData && userData.level !== 5 ? 
        
        <div>
        <div className="block-header">
          <div className="block-title">
            <i className="fa fa-tasks fa-2x"></i>
          </div>
          <div className="block-tools">
            <i
              className="si si-question "
              title="Adicione, edite e exclua tarefas facilmente."
              />
          </div>
        </div>
              <h3 className="ml-4">Agenda de lembretes <br/> <br/> <span className="text-muted mt-3 pt-4"><i className="si si-lock"/> Área restrita ao cliente</span></h3> 
        </div>
              
              : 
              <div>
            <div className="block-header">
                <div className="block-title">
                    <i className="si si-calendar fa-2x"></i>
                </div>
                <div className="block-tools">
                    <i
                        className="si si-question"
                        title="Tenha acesso a nossa ferramenta incrível para facilitar a cobrança de documentos e valores dos seus clientes"
                    />
                </div>
            </div>

            {available ?
                <div className="block-content">
                    <h3 className="mb-1">Agenda de lembretes</h3>{data && data.length} lembretes ativos
                </div>
                :
                <PlanButton customerId={customerId} name={"Agenda de lembretes"} reload={() => reload()} />
            }
        </div>
}
        </div>
    );
}
