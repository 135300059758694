import {
  CustomerPicture,
  CustomerSelect,
} from "@/app/core/base/controllers/CustomerController";
import Storage from "@/app/core/util/Storage";
import React, { useEffect, useState, useRef } from "react";
import CarouselCard from "./components/Carousel";
import ManagerCard from "./components/ManagerCard";
import TaskCard from "./components/TaskCard";
import OrganListController from "@/app/core/base/controllers/OrganListController";
import CompaniesCard from "./components/CompaniesCard";
import BudgetCard from "./components/BudgetCard";
import { toast } from "react-toastify";
import ResumeTaskCard from "./components/ResumeTaskCard";
import ElaboratorCard from "./components/ElaboratorCard";
import Calendar from "react-calendar";
import ReminderCard from "./components/ReminderCard";
import CustomerHelper from "@/app/core/util/helpers/CustomerHelper";

export default function HomeComponent({ props }) {
  const userData = Storage.getUserData();
  const [picture, setPicture] = useState(null);
  const [data, setData] = useState({});
  const [exigencies, setExigencies] = useState([]);
  const inputFile = useRef(null);
  const [value, setValue] = useState(new Date());
const date = new Date();

    const [change, setChange] = useState(date.getTime());
    function notifyChange() {
        setChange(date.getTime());
    }

  function onUploadClick() {
    inputFile.current.click();
  }

  useEffect(() => {
    getCustomerData();
    loadCustomerExigencies();
  }, []);

  function loadCustomerExigencies() {
    OrganListController().SelectByCustomer(
      props.match.params.key,
      (res) => setExigencies(res),
      (err) => {
      },
    );
  }

  function getCustomerData() {
    CustomerSelect(props.match.params.key, (res) => {
      setData(res);

      if (res.avatarImages.length > 0) {
        setPicture(res.avatarImages[0].url);
      }

      notifyChange();
    });
  }

  function uploadFile(files) {
    var formData = new FormData();
    formData.append("file", files[0]);

    CustomerPicture(data.id, formData, (res) => {
      toast.success("Salvo com sucesso");
      getCustomerData();
    });
  }

  function onChange(){
    window.location = "#/reminders"
  }
  
  function openDate(){
    window.location = "#/reminders"
  }

  function checkAvailability(type){
    let response = true;
    console.log("Planos", type, data);
    if(!data.plan){
      response = false;
    }else{
      if(type === undefined || data.plan.items === undefined){
        return false;
      }
      let exist = data.plan.items.filter(x => x.component && x.component.toString() == type.toString());
      
      console.log("Exigte", exist);
      if(exist.length > 0){
        response = true
      }else{
        response = false
      }
    }

    return response;
  }

  function myPlan(){
    if(data && data.plan && data.plan.name.includes("Basic")){
      return <i className="fa fa-user-tie fa-2x"/>
    } else if(data && data.plan && data.plan.name.includes("Exclusive")){
      return <i className="si si-diamond fa-2x"/>
    }else if(data && data.plan && data.plan.name.includes("Elite")){
      return <i className="si si-rocket fa-2x"/>;
    }
  }

  return (
    <div className="mr-1 ml-3 pl-0 pr-0">
      <div className="row mr-0 pr-0">
        <div className="col-md-12 row mb-3">
          <img
            className="img-avatar img-avatar96 img-avatar-thumb text-center ml-3"
            src={
              data && picture != null
                ? picture
                : "assets/media/avatars/avatar10.jpg"
            }
            style={{ textAlign: "center" }}
            onClick={(evt) => onUploadClick()}
            // alt="Add logo"
            title="Personalize com seu logo aqui"
          />
          <button
            className="btn btn-white btn-rounded btn-sm"
            style={{ marginLeft: -25, marginTop: 75 }}
            onClick={() => onUploadClick()}
          >
            <i className="fa fa-camera" />
          </button>
          <h3 className="mb-0 ml-3 mt-4">
            <strong className="font-w700">
              Seja bem vindo! {userData && userData.firstName}
            </strong>
            <br />
            <small className="text-muted">
              Código {data && data.customerCode} - {data && data.customerCode && CustomerHelper().PlanName(data)}
            </small>
          </h3>
        </div>
        <div className="col-md-6 ml-0 mr-0">
          {/* Slider */}
          <div style={{ width: "100%", height: 430 }}>
            <CarouselCard />
          </div>
        </div>
        <div className="col-md-6 row ml-0 mr-0 pl-0">
          <div className="col-md-6 ml-0 mr-0 pr-0 pl-0">
            {/* <BudgetCard
              budgets={budgets}
              reload={() => loadBudgets() | getCustomerData()}
              customerId={data && data.id}
            /> */}
            <ResumeTaskCard customerId={props.match.params.key} available={change && data && checkAvailability(CustomerHelper().ProComponents.Tasks)} reload={() => getCustomerData()}/>
          </div>
          <div
            className="col-md-6 ml-0 mr-0 pr-1 pl-1 cursor">
            <ElaboratorCard customerId={props.match.params.key} available={change && data && checkAvailability(CustomerHelper().ProComponents.Elaborator)} reload={() => getCustomerData()}/>
          </div>
          <div className="col-md-6 ml-0 mr-0 pr-1 pl-1">
            <BudgetCard
              customerId={props.match.params.key}
            />
          </div>
          <div className="col-md-6 ml-0 mr-0 pr-1 pl-1 cursor">
            <CompaniesCard customerId={	props.match.params.key} available={change && data && checkAvailability(CustomerHelper().ProComponents.Regularity)} reload={() => getCustomerData()}/>
          </div>
        </div>
        <div className="col-md-9 pr-2">
          {/* Tabelas */}
          {data && (
            <TaskCard
              customerId={props.match.params.key}
              data={data && data}
              exigencies={exigencies}
              available={change && data && checkAvailability(CustomerHelper().ProComponents.Tasks)} 
              reload={() => getCustomerData()}
            />
          )}
        </div>
        <div className="col-md-3 ml-0 pl-0">
            <ReminderCard customerId={props.match.params.key} available={change && data && checkAvailability(CustomerHelper().ProComponents.Reminder)}
              reload={() => getCustomerData()}/>
          <div className="mt-4">
          <ManagerCard customerId={props.match.params.key} 
              available={change && data && checkAvailability(CustomerHelper().ProComponents.Manager)} 
              reload={() => getCustomerData()}/>
          </div>
        </div>
      </div>
      <input
        type="file"
        ref={inputFile}
        accept="image/*"
        onChange={(event) => {
          uploadFile(event.currentTarget.files);
        }}
        style={{ visibility: "hidden", width: 10 }}
      />
    </div>
  );
}
