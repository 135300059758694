import { toast } from "react-toastify";
import BaseService from "@/app/core/base/BaseService";
import { HandleErrors } from "../../util/helpers/ErrorHelper";

export const ReavaliationController = {
    Team : async function (userId, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `Reavaliation/team/${userId}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  },
    Responsible : async function (startDate, endDate, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `Reavaliation/responsible?startDate=${startDate}&endDate=${endDate}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  },
    Requester : async function (startDate, endDate, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `Reavaliation/requester?startDate=${startDate}&endDate=${endDate}`,
      },
      (res) => {
        response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  },
}
