import React, { useEffect, useState } from "react";
import Loading from "@/app/core/layouts/main/components/Loading";
import DebtorCard from "./components/DebtorCard";

export default function MainCustomer(props) {
    const [loading] = useState(false);
    const [refreshData, setRefreshData] = useState(false);
    const date = new Date();

    const [startDate, setStartDate] = useState(
        getFirstDayOfMonth(date.getFullYear(), date.getMonth())
            .toISOString()
            .substring(0, 10)
    );
    const [endDate, setEndDate] = useState(getLastDayOfMonth(date.getFullYear(), date.getMonth())
        .toISOString()
        .substring(0, 10));

    function getLastDayOfMonth(year, month) {
        let nextMonth = new Date(year, month + 1, 1);
        nextMonth.setDate(nextMonth.getDate() - 1);
        return nextMonth;
    }

    function getFirstDayOfMonth(year, month) {
        return new Date(year, month, 1);
    }
    return (
        <>
            <Loading loading={loading} />
            <div className="row">

                <div className="col-md-8">
                    <div className="content-heading">Debitador</div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="form-group">
                        <span>Data de início</span>
                        <input
                            type={"date"}
                            className="form-control"
                            value={startDate}
                            onChange={(evt) => setStartDate(evt.target.value)}
                        />
                    </div>
                    <div className="form-group">
                        <span>Data fim</span>
                        <input
                            type={"date"}
                            className="form-control"
                            value={endDate}
                            onChange={(evt) => setEndDate(evt.target.value)}
                        />
                    </div>
                    <span>

                    <button className="btn btn-hero-info btn-block mt-4" onClick={() => setRefreshData(!refreshData)}>
                        <i className="fa fa-search mr-1" />
                        Buscar
                    </button>
                    </span>
                </div>
            </div>
            <div className="bg-white">
                <div className="content">
                    <DebtorCard
                        startDate={startDate}
                        endDate={endDate}
                        reload={refreshData}
                    />
                </div>
            </div>

        </>
    );
}
